import { DragEvent, useCallback, useMemo } from 'react';
import { Pointer, Position, Projection, Scale } from '../../../types';
import {
  ContextMenu,
  CopyItem,
  creatorConfig,
  creatorCopy,
  creatorDrag,
  creatorHistory,
  creatorInsert,
  creatorProject,
  creatorSelect,
  creatorTemplate,
  DropCollisions,
  InsertItem,
  InvalidDrag,
  SelectData,
  SelectItem,
  templates,
  TemplateTypes,
} from '../../../modules/creator';
import {
  IndentPosition,
  Project,
  ProjectData,
  ProjectDimensions,
} from '../../../services/api/models/project';
import {
  ProductModel,
  ProductSearch,
} from '../../../services/api/models/product';
import { Glass } from '../../../services/api/models/glass';
import Big from 'big.js';
import { fixValue } from '../../../modules/creator/utils/fix';
import {
  selectData,
  selectToggleValidateProject,
  selectUpdateCorners,
  selectValidatedHistoryPointer,
  selectValidateProject,
} from '../../../modules/creator/store/project';
import {
  DEFAULT_RATIO,
  selectSetMainLayerPosition,
} from '../../../modules/creator/store/config';
import { Shape } from '../../../modules/space';

type TemplateData = Pick<ProjectData, 'dimensions' | 'breakingPoint'>;

export interface UseCreator {
  showAngles: boolean;
  openLeftPanel: boolean;
  openRightPanel: boolean;
  saved: boolean;
  pointer: Pointer;
  selectedScale: Scale;
  disableUndo: boolean;
  disableRedo: boolean;
  defaultId: number;
  isHistoryEnd: boolean;
  templateId?: string;
  contextMenu?: ContextMenu;
  selected: SelectItem[];
  templatePosition: Position;
  project: Project;
  copied?: CopyItem;
  toggleLeftPanel(): void;
  toggleRightPanel(): void;
  toggleShowAngles(): void;
  setSaved(): void;
  onChangePointer(pointer: Pointer): void;
  onSelectScale(percentage: Scale): void;
  setProjectName(name: string): void;
  onUndo(): void;
  onRedo(): void;
  closeContextMenu(): void;
  setBaseTemplate(template: TemplateData): void;
  selectTemplate(type: TemplateTypes): ProjectData;
  selectShape(data: SelectData): void;
  updateDimensions(
    dimensions: ProjectDimensions,
    position?: Partial<Position>,
    history?: boolean,
  ): void;
  addGlass(
    thickness: number,
    onWarning?: () => void,
    onGlassCreationInProgress?: () => void,
    onFallbackGlassCreation?: () => void,
    onComplete?: () => void,
  ): void;
  setTemplatePosition(position: Position): void;
  updateProjectPosition(position: Position): void;
  deleteGlass(glassId: number, callback?: () => void): void;
  onCopy(item: CopyItem): void;
  onCut(item: CopyItem): void;
  onPaste(): CopyItem | undefined;
  deselect(): void;
  dragging?: boolean;
  dragId?: string | number;
  onDragStart(
    e: DragEvent,
    data: unknown,
    dragId?: string | number,
    dragImage?: HTMLElement,
  ): void;
  onDragEnd(e: DragEvent): void;
  onDrag(e: DragEvent): void;
  dragCollision: DropCollisions;
  dragInvalid: InvalidDrag;
  addProduct(
    product: ProductModel,
    originProduct: ProductSearch,
    callback?: () => void,
  ): void;
  deleteProduct(iid: string, callback?: () => void): void;
  projectProducts: ProductModel[];
  projectGlasses: Glass[];
  usedProductsData: Record<number, ProductSearch>;
  insertItem: (item: InsertItem) => void;
  layerPosition: Position;
  multiselect?: boolean;
  indent?: IndentPosition;
  setIndent(indent: IndentPosition): void;
  clearIndent(): void;
  projection: Projection;
  onSelectProjection(projection: Projection): void;
  toggleAdjustManually(): void;
  updateCorners(dimensions: ProjectDimensions, history?: boolean): void;
  scale: number;
  validatedHistoryPointer: number;
  historyPointer: number;
  validateProject: boolean;
  toggleValidateProject(): void;
}

const {
  useConfigStore,
  selectSetPointer,
  selectPointer,
  selectScale,
  selectSetScale,
  selectMainLayerPosition,
  selectProjection,
  selectSetProjection,
} = creatorConfig;

const {
  DEFAULT_PROJECT_ID,
  useProjectStore,
  selectUndo,
  selectRedo,
  selectShowAngles,
  selectHistoryPointer,
  selectOpenLeftPanel,
  selectOpenRightPanel,
  selectToggleLeftPanel,
  selectToggleRightPanel,
  selectToggleShowAngles,
  selectProject,
  selectSaved,
  selectSave,
  selectSetProjectName,
  selectUpdateDimensions,
  selectUpdateHistory,
  selectAddGlass,
  selectDeleteGlass,
  selectAddProduct,
  selectDeleteProduct,
  selectUsedProducts,
  selectProjectProducts,
  selectProjectGlasses,
  selectUpdatePosition,
  selectClearIndent,
  selectSetIndent,
  selectIndent,
  selectToggleAdjustManually,
} = creatorProject;

const {
  useSelectStore,
  selectContextMenu,
  selectSelected,
  selectCloseContextMenu,
  selectSelect,
  selectDeselect,
  selectMulti,
} = creatorSelect;

const {
  useTemplateStore,
  selectTemplateId,
  selectSetBaseBreakingPoint,
  selectSetup,
  selectSetConfigured,
  selectSetPosition,
  selectPosition,
} = creatorTemplate;

const {
  useCopyStore,
  selectCopy,
  selectPaste,
  selectCut,
  selectCopied,
} = creatorCopy;

const {
  useDragStore,
  selectOnDragStart,
  selectDragging,
  selectDragId,
  selectOnDrag,
  selectCollision,
  selectInvalid,
  selectOnDragEnd,
} = creatorDrag;

const { useInsertStore, selectInsertItem } = creatorInsert;

const { useHistoryStore, selectHistory, selectClear } = creatorHistory;

const selectTemplate = (template: TemplateTypes) => templates[template];

const useCreator = (): UseCreator => {
  const templateId = useTemplateStore(selectTemplateId);
  const project = useProjectStore(selectProject);
  const showAngles = useProjectStore(selectShowAngles);
  const openLeftPanel = useProjectStore(selectOpenLeftPanel);
  const openRightPanel = useProjectStore(selectOpenRightPanel);
  const toggleLeftPanel = useProjectStore(selectToggleLeftPanel);
  const toggleRightPanel = useProjectStore(selectToggleRightPanel);
  const toggleShowAngles = useProjectStore(selectToggleShowAngles);
  const saved = useProjectStore(selectSaved);
  const setSaved = useProjectStore(selectSave);
  const setPointer = useConfigStore(selectSetPointer);
  const pointer = useConfigStore(selectPointer);
  const setScale = useConfigStore(selectSetScale);
  const scale = useConfigStore(selectScale);
  const projectData = useProjectStore(selectData);
  const onUndo = useProjectStore(selectUndo);
  const onRedo = useProjectStore(selectRedo);
  const historyPointer = useProjectStore(selectHistoryPointer);
  const history = useHistoryStore(selectHistory);
  const setProjectName = useProjectStore(selectSetProjectName);
  const contextMenu = useSelectStore(selectContextMenu);
  const closeContextMenu = useSelectStore(selectCloseContextMenu);
  const selected = useSelectStore(selectSelected);
  const setTemplateBaseBreakpoint = useTemplateStore(
    selectSetBaseBreakingPoint,
  );
  const setTemplatePosition = useTemplateStore(selectSetPosition);
  const templatePosition = useTemplateStore(selectPosition);
  const setupTemplate = useTemplateStore(selectSetup);
  const markAsConfigured = useTemplateStore(selectSetConfigured);
  const updateProjectDimensions = useProjectStore(selectUpdateDimensions);
  const updateProjectHistory = useProjectStore(selectUpdateHistory);
  const updateProjectPosition = useProjectStore(selectUpdatePosition);
  const clearHistory = useHistoryStore(selectClear);
  const selectShape = useSelectStore(selectSelect);
  const deselect = useSelectStore(selectDeselect);
  const multiselect = useSelectStore(selectMulti);
  const addGlass = useProjectStore(selectAddGlass);
  const deleteGlass = useProjectStore(selectDeleteGlass);
  const onCut = useCopyStore(selectCut);
  const onCopy = useCopyStore(selectCopy);
  const onPaste = useCopyStore(selectPaste);
  const copied = useCopyStore(selectCopied);
  const onDragStart = useDragStore(selectOnDragStart);
  const onDragEnd = useDragStore(selectOnDragEnd);
  const onDrag = useDragStore(selectOnDrag);
  const dragging = useDragStore(selectDragging);
  const dragId = useDragStore(selectDragId);
  const dragCollision = useDragStore(selectCollision);
  const dragInvalid = useDragStore(selectInvalid);
  const addProduct = useProjectStore(selectAddProduct);
  const deleteProduct = useProjectStore(selectDeleteProduct);
  const usedProductsData = useProjectStore(selectUsedProducts);
  const projectProducts = useProjectStore(selectProjectProducts);
  const projectGlasses = useProjectStore(selectProjectGlasses);
  const insertItem = useInsertStore(selectInsertItem);
  const layerPosition = useConfigStore(selectMainLayerPosition);
  const setIndent = useProjectStore(selectSetIndent);
  const clearIndent = useProjectStore(selectClearIndent);
  const indent = useProjectStore(selectIndent);
  const projection = useConfigStore(selectProjection);
  const setProjection = useConfigStore(selectSetProjection);
  const toggleAdjustManually = useProjectStore(selectToggleAdjustManually);
  const updateCorners = useProjectStore(selectUpdateCorners);
  const validatedHistoryPointer = useProjectStore(
    selectValidatedHistoryPointer,
  );
  const validateProject = useProjectStore(selectValidateProject);
  const toggleValidateProject = useProjectStore(selectToggleValidateProject);
  const setMainLayerPosition = useConfigStore(selectSetMainLayerPosition);

  const onSelectProjection = useCallback(
    (projection: Projection) => {
      setProjection(projection);
    },
    [setProjection],
  );

  const onChangePointer = useCallback(
    (pointer: Pointer) => {
      setPointer(pointer);
    },
    [setPointer],
  );

  const getMidScreen = useCallback(
    (scale: number) => {
      const niche = new Shape(projectData.dimensions, projectData.position);
      const nicheCenter = niche.polygonCentroid;

      const nicheCenterScaled = {
        x: nicheCenter.x * DEFAULT_RATIO * scale,
        y: nicheCenter.y * DEFAULT_RATIO * scale,
      };

      // 360px  - right panel
      // 300px  - left panel
      // 110px  - top bar
      const windowCenter = {
        x: (window.innerWidth - 60) / 2 - nicheCenterScaled.x,
        y: (window.innerHeight - 110) / 2 - nicheCenterScaled.y,
      };

      return windowCenter;
    },
    [projectData],
  );

  const onSelectScale = useCallback(
    (percentage: Scale) => {
      const newScale = fixValue(new Big(percentage).div(100));
      const midPoint = getMidScreen(newScale);
      setMainLayerPosition(midPoint);
      setScale(newScale);
    },
    [setScale, setMainLayerPosition, projectData],
  );

  const selectedScale = useMemo<Scale>(
    () => fixValue(new Big(scale).times(100)),
    [scale],
  );

  const disableRedo = useMemo(
    () => history && history.length - 1 <= historyPointer,
    [history, historyPointer],
  );

  const setBaseTemplate = useCallback(
    ({ dimensions, breakingPoint }: TemplateData) => {
      clearHistory();
      const templateId = setupTemplate();
      updateProjectDimensions(dimensions);
      updateProjectHistory();
      markAsConfigured();
      if (breakingPoint) {
        setTemplateBaseBreakpoint(breakingPoint);
      }
      selectShape({ type: 'template', shapeId: templateId });
    },
    [
      clearHistory,
      markAsConfigured,
      selectShape,
      setTemplateBaseBreakpoint,
      setupTemplate,
      updateProjectDimensions,
      updateProjectHistory,
    ],
  );

  const updateDimensions = useCallback(
    (
      dimensions: ProjectDimensions,
      position?: Partial<Position>,
      history = false,
    ) => {
      const newPosition = project.data.position
        ? {
            ...project.data.position,
            ...position,
          }
        : { x: 0, y: 0 };
      updateProjectDimensions(dimensions, newPosition, history);
    },
    [updateProjectDimensions],
  );

  const disableUndo = historyPointer <= 0;

  const isHistoryEnd = historyPointer === history.length - 1;

  return {
    defaultId: DEFAULT_PROJECT_ID,
    templateId,
    showAngles,
    project,
    openLeftPanel,
    openRightPanel,
    toggleLeftPanel,
    toggleRightPanel,
    toggleShowAngles,
    saved,
    setSaved,
    onChangePointer,
    pointer,
    onSelectScale,
    selectedScale,
    onUndo,
    onRedo,
    disableUndo,
    disableRedo,
    isHistoryEnd,
    setProjectName,
    contextMenu,
    selected,
    closeContextMenu,
    setBaseTemplate,
    selectTemplate,
    selectShape,
    updateDimensions,
    addGlass,
    setTemplatePosition,
    updateProjectPosition,
    templatePosition,
    deselect,
    deleteGlass,
    onCopy,
    onCut,
    onPaste,
    copied,
    onDragStart,
    onDragEnd,
    onDrag,
    dragging,
    dragId,
    dragCollision,
    dragInvalid,
    addProduct,
    deleteProduct,
    usedProductsData,
    projectProducts,
    projectGlasses,
    multiselect,
    insertItem,
    layerPosition,
    indent,
    setIndent,
    clearIndent,
    projection,
    onSelectProjection,
    toggleAdjustManually,
    updateCorners,
    scale,
    validatedHistoryPointer,
    historyPointer,
    validateProject,
    toggleValidateProject,
  };
};

export default useCreator;
