import { Direction } from '../utils/neighbors';
import { Shape } from '../../space';
import { useMemo } from 'react';
import forEach from 'lodash/forEach';
import { scaleGlass } from '../utils/shapes';
import reduce from 'lodash/reduce';
import findIndex from 'lodash/findIndex';
import {
  mapCoordinatesToEdges,
  scalePosition,
  toPoint,
  toVector,
} from '../../../utils/shape';
import { useGaps } from './index';
import {
  selectData,
  selectProjectGlasses,
  useProjectStore,
} from '../store/project';
import { Glass as ApiGlass } from '../../../services/api/models/glass';
import { GlassGapData } from '../../../services/api/models/project';
import {
  findLine,
  findPerpendicularLine,
  findPointOnLineInDistance,
  isLinePerpendicularToXAxis,
} from '../utils/geometry/lines';
import Big from 'big.js';
import { AllGapsType } from '../types/Gap';
import { MAX_GAP_DIMENSIONS } from './useGaps';

interface GapsCollection {
  neighborId: number | undefined;
  glass: ApiGlass;
  bound: [number, number];
  position: string;
}

export const useProjectGlassesGaps = () => {
  const glasses = useProjectStore(selectProjectGlasses);
  const { dimensions, position } = useProjectStore(selectData);

  const { findAllGaps } = useGaps({
    glasses,
    templatePosition: position,
    templateDimensions: dimensions,
  });

  // Helper function to map gaps to GapsCollection
  const mapGapsToCollection = (allGaps: AllGapsType[]): GapsCollection[] => {
    return allGaps
      .map((gap) => {
        const collection: GapsCollection[] = [];

        forEach(gap.boundaries, (bound, position) => {
          if (
            bound.value &&
            (bound.value[0] > 0 || bound.value[1] > 0) &&
            (bound.value[0] < MAX_GAP_DIMENSIONS ||
              bound.value[1] < MAX_GAP_DIMENSIONS)
          ) {
            collection.push({
              neighborId: bound.neighborId as number,
              glass: scaleGlass(gap.glass, 1),
              bound: bound.value,
              position,
            });
          }
        });

        return collection;
      })
      .flat();
  };

  // Helper function to reduce and filter gaps by area
  const filterGapsByArea = (mappedGaps: GapsCollection[]): GapsCollection[] => {
    return reduce(
      mappedGaps,
      (result: GapsCollection[], item) => {
        const existingIndex = findIndex(result, (itm) => {
          return (
            itm.neighborId !== undefined &&
            item.glass.id !== undefined &&
            item.neighborId === itm.glass.id &&
            item.bound[0] === itm.bound[0] &&
            item.bound[1] === itm.bound[1]
          );
        });

        if (existingIndex === -1) {
          result.push(item);
          return result;
        }

        const existingGapArea = new Shape({
          corners: result[existingIndex].glass.corners,
        }).area;
        const currentGapArea = new Shape({ corners: item.glass.corners }).area;

        if (currentGapArea < existingGapArea) {
          result.splice(existingIndex, 1, item);
        }

        return result;
      },
      [],
    );
  };

  // Helper function to calculate gap positions
  const calculateGapPositions = (acc: GapsCollection[]): GlassGapData[] => {
    return reduce(
      acc,
      (result: GlassGapData[], item) => {
        const existingItemIndex = findIndex(
          result,
          (r) => r.glassId === item.glass.id,
        );

        const glassShape = new Shape(
          { corners: item.glass.corners },
          scalePosition(item.glass.position, true),
        );
        const glassEdges = mapCoordinatesToEdges({
          corners: glassShape.corners,
        });

        const gapStart = glassShape.getEdgeMiddlePointPosition(
          item.position as Direction,
        );

        const averageBound = (item.bound[0] + item.bound[1]) / 2;

        const edgePoints = glassEdges[item.position as Direction];

        let gapEnd = { ...gapStart };

        if (
          isLinePerpendicularToXAxis(...edgePoints) ||
          edgePoints[0][1] === edgePoints[1][1]
        ) {
          switch (item.position) {
            case 'top':
              gapEnd.y -= averageBound;
              break;
            case 'bottom':
              gapEnd.y += averageBound;
              break;
            case 'left':
              gapEnd.x -= averageBound;
              break;
            case 'right':
              gapEnd.x += averageBound;
              break;
          }
        } else {
          const edgeLine = findLine(...edgePoints);
          const perpendicularEdge = findPerpendicularLine(
            toPoint(gapStart),
            findLine(...edgePoints),
          );

          let avgBoundBig = new Big(averageBound);

          if (
            (item.position === 'top' && edgeLine.a < 0) ||
            (item.position === 'bottom' && edgeLine.a > 0) ||
            item.position === 'left'
          ) {
            avgBoundBig = avgBoundBig.mul(-1);
          }

          gapEnd = toVector(
            findPointOnLineInDistance(
              perpendicularEdge,
              toPoint(gapStart),
              avgBoundBig,
            ),
          );
        }

        if (existingItemIndex >= 0) {
          result[existingItemIndex].gapItems.push({
            start: gapStart,
            end: gapEnd,
          });
        } else {
          result.push({
            glassId: item.glass.id,
            gapItems: [{ start: gapStart, end: gapEnd }],
          });
        }

        return result;
      },
      [],
    );
  };

  const glassesGaps = useMemo(() => {
    const allGaps = findAllGaps();

    // Step 1: Map gaps to GapsCollection
    const mappedGaps = mapGapsToCollection(allGaps);

    // Step 2: Filter gaps by comparing glass areas
    const filteredGaps = filterGapsByArea(mappedGaps);

    // Step 3: Calculate and accumulate gap positions
    return calculateGapPositions(filteredGaps);
  }, [findAllGaps]);

  return {
    glassesGaps,
  };
};
