import Flatten from '@flatten-js/core';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import uniq from 'lodash/uniq';
import polygonClipping, { MultiPolygon, Ring } from 'polygon-clipping';

import { Shape } from './Shape';
import {
  IndentPosition,
  ProjectDimensions,
} from '../../services/api/models/project';
import { IndentCorners, Position, RectCorners } from '../../types';
import forEach from 'lodash/forEach';
import { minBy, sortBy } from 'lodash';

interface ISpace {
  addShapes(shapes: Shape[]): void;
  addIndentToSpaceShapes(): void;
  findEmptyLeftBound(width: number, height: number): Shape;
}

export class Space extends Shape implements ISpace {
  public constructor(dimensions: ProjectDimensions, position?: Position) {
    super(dimensions, position);
  }

  public spaceShapes: Shape[] = [];

  get area() {
    return this.toPolygon().area();
  }

  public addShapes(shapes: Shape[]) {
    this.spaceShapes = [...this.spaceShapes, ...shapes];
  }

  public distance(a: Shape, source: Shape) {
    return Math.sqrt(
      Math.pow(a.leftEdge.value - source.leftEdge.value, 2) +
        Math.pow(a.topEdge.value - source.topEdge.value, 2),
    );
  }

  public addIndentToSpaceShapes() {
    const dimensions = this.dimensions;

    switch (dimensions.indent) {
      case IndentPosition.TOP_LEFT:
        if (dimensions.corners['center-left']) {
          const indentShape = new Shape({
            corners: {
              'top-left': [
                dimensions.corners['center-left'][0],
                dimensions.corners['top-left'][1],
              ],
              'top-right': dimensions.corners['top-left'],
              'bottom-left': dimensions.corners['center-left'],
              'bottom-right': dimensions.corners['center'],
            },
          });

          this.corners['top-left'] = indentShape.corners['top-left'];
          this.addShapes([indentShape]);
        }
        break;
      case IndentPosition.TOP_RIGHT:
        if (dimensions.corners['center-right']) {
          const indentShape = new Shape({
            corners: {
              'top-left': dimensions.corners['top-right'],
              'top-right': [
                dimensions.corners['center-right'][0],
                dimensions.corners['top-right'][1],
              ],
              'bottom-left': dimensions.corners['center'],
              'bottom-right': dimensions.corners['center-right'],
            },
          });
          this.corners['top-right'] = indentShape.corners['top-right'];
          this.addShapes([indentShape]);
        }
        break;
      case IndentPosition.BOTTOM_LEFT:
        if (dimensions.corners['center-left']) {
          const indentShape = new Shape({
            corners: {
              'top-left': dimensions.corners['center-left'],
              'top-right': dimensions.corners['center'],
              'bottom-left': [
                dimensions.corners['center-left'][0],
                dimensions.corners['bottom-left'][1],
              ],
              'bottom-right': dimensions.corners['bottom-left'],
            },
          });
          this.corners['bottom-left'] = indentShape.corners['bottom-left'];
          this.addShapes([indentShape]);
        }
        break;
      case IndentPosition.BOTTOM_RIGHT:
        if (dimensions.corners['center-right']) {
          const indentShape = new Shape({
            corners: {
              'top-left': dimensions.corners['center'],
              'top-right': dimensions.corners['center-right'],
              'bottom-left': dimensions.corners['bottom-right'],
              'bottom-right': [
                dimensions.corners['center-right'][0],
                dimensions.corners['bottom-right'][1],
              ],
            },
          });
          this.corners['bottom-right'] = indentShape.corners['bottom-right'];
          this.addShapes([indentShape]);
        }
        break;
    }
  }

  public inside = (newShape: Shape) => {
    const space = this.toPolygon();
    const union = this.getShapesPolygonUnion([...this.spaceShapes]);
    const shape = this.getShapesPolygonUnion([newShape]);

    if (!shape || !shape.isValid()) {
      return false;
    }

    if (union && union.isValid()) {
      // todo this is working so so, I think it has problem with floating point, think about another solution, maybe write the same functions, but using bigInt lib
      // return (
      //   Flatten.Relations.covered(shape, space) &&
      //   (Flatten.Relations.touch(union, shape) ||
      //     !Flatten.Relations.intersect(union, shape))
      // );
    }

    return Flatten.Relations.covered(shape, space);
  };

  /**
   * Finds empty space in the left bound of the space.
   * @param width
   * @param height
   */
  public findEmptyLeftBound(width: number, height: number) {
    let isShapesRect = true;
    let shape;

    const bestShape: Shape = new Shape({
      corners: {
        'top-left': this.corners['bottom-right'],
        'top-right': this.corners['bottom-right'],
        'bottom-left': this.corners['bottom-right'],
        'bottom-right': this.corners['bottom-right'],
        center: this.corners['bottom-right'],
        'center-left': undefined,
        'center-right': undefined,
      },
    });

    if (!this.isRectangular) {
      isShapesRect = false;
    }

    forEach(this.spaceShapes, (shape) => {
      if (!shape.isRectangular) {
        isShapesRect = false;
      }
    });
    // TODO: Dirty hack, new algorithm is not working perfectly with non rectangular shapes
    if (isShapesRect) {
      shape = this.findNextEmptyShape_old(width, height);
    } else {
      shape = this.findNextEmptyShape(width, height);
    }

    if (shape) {
      return shape;
    }

    return bestShape;
  }

  // TODO: Method should be separated from class
  private findNextEmptyShape = (width: number, height: number) => {
    const isIndentCorner = (
      corners: RectCorners | IndentCorners,
    ): corners is IndentCorners => {
      return corners.hasOwnProperty('center');
    };

    if (!this.spaceShapes.length) {
      if (isIndentCorner(this.corners)) {
        if (this.indent === IndentPosition.TOP_RIGHT) {
          return new Shape({
            corners: {
              'bottom-left': this.corners['bottom-left'],
              'top-left': this.corners['top-left'],
              'top-right': this.corners['top-right'],
              'bottom-right': this.corners['center']!,
              center: undefined,
              'center-left': undefined,
              'center-right': undefined,
            },
          });
        }
        if (this.indent === IndentPosition.TOP_LEFT) {
          return new Shape({
            corners: {
              'bottom-left': this.corners['center']!,
              'top-left': this.corners['top-left'],
              'top-right': this.corners['top-right'],
              'bottom-right': this.corners['bottom-right'],
              center: undefined,
              'center-left': undefined,
              'center-right': undefined,
            },
          });
        }
        if (this.indent === IndentPosition.BOTTOM_RIGHT) {
          return new Shape({
            corners: {
              'bottom-left': this.corners['bottom-left'],
              'top-left': this.corners['top-left'],
              'top-right': this.corners['center']!,
              'bottom-right': this.corners['bottom-right'],
              center: undefined,
              'center-left': undefined,
              'center-right': undefined,
            },
          });
        }
        if (this.indent === IndentPosition.BOTTOM_LEFT) {
          return new Shape({
            corners: {
              'bottom-left': this.corners['center-left']!,
              'top-left': this.corners['top-left'],
              'top-right': this.corners['top-right']!,
              'bottom-right': this.corners['center']!,
              center: undefined,
              'center-left': undefined,
              'center-right': undefined,
            },
          });
        }
      }

      return new Shape({
        corners: {
          'bottom-left': this.corners['bottom-left'],
          'top-left': this.corners['top-left'],
          'top-right': this.corners['top-right'],
          'bottom-right': this.corners['bottom-right'],
          center: undefined,
          'center-left': undefined,
          'center-right': undefined,
        },
      });
    }

    const polygons = this.filterPolygonsByShapeType(
      this.findEmptyPolygons(width, height),
      4,
      4,
    );

    if (!polygons.length) {
      return null;
    }

    const polygon = polygons.shift();

    if (!polygon || !polygon.isValid() || polygon.isEmpty()) {
      return null;
    }

    try {
      if (!Flatten.Relations.cover(this.toPolygon(), polygon)) {
        return null;
      }
    } catch (e) {}

    const corners = polygon.vertices.sort((point1, point2) => {
      if (point1.x === point2.x) {
        return point1.y - point2.y;
      }

      return point1.x - point2.x;
    });

    /**
     * Calculates and returns the four corner points of a quadrilateral in a specific order:
     * bottom-left, top-left, top-right, and bottom-right. The points are determined based on their
     * position relative to each other and are sorted clockwise starting from the bottom-left corner.
     *
     * @param {Flatten.Point[]} corners
     *
     * @returns {{ bottomLeft: Flatten.Point; topLeft: Flatten.Point; topRight: Flatten.Point; bottomRight: Flatten.Point } | null}
     */
    const calcCorners = (
      corners: Flatten.Point[],
    ): {
      bottomLeft: Flatten.Point;
      topLeft: Flatten.Point;
      topRight: Flatten.Point;
      bottomRight: Flatten.Point;
    } | null => {
      const uniquePoints = uniqWith(
        corners,
        (a, b) => a.x === b.x && a.y === b.y,
      );

      const bottomPoints = sortBy(uniquePoints, (p) => p.y).splice(2, 2);

      const bottomLeft = minBy(bottomPoints, (p) => p.x)!;

      const result = sortBy(uniquePoints, [
        (p) => {
          if (p.x === bottomLeft.x && p.y === bottomLeft.y) return -Infinity; // Ensure bottom-left comes first
          return Math.atan2(p.y - bottomLeft.y, p.x - bottomLeft.x); // Clockwise sorting
        },
      ]);

      return {
        bottomLeft: result[0],
        topLeft: result[1],
        topRight: result[2],
        bottomRight: result[3],
      };
    };

    const isWrongCorner = (corners: Flatten.Point[]) => {
      return !!corners.find(
        (corner) => Number.isNaN(corner.x) || Number.isNaN(corner.y),
      );
    };

    if (isWrongCorner(corners)) {
      return null;
    }

    const result = calcCorners(corners);

    if (!result) {
      return null;
    }

    return new Shape({
      corners: {
        'bottom-left': [result.bottomLeft.x, result.bottomLeft.y],
        'top-left': [result.topLeft.x, result.topLeft.y],
        'top-right': [result.topRight.x, result.topRight.y],
        'bottom-right': [result.bottomRight.x, result.bottomRight.y],
        center: undefined,
        'center-left': undefined,
        'center-right': undefined,
      },
    });
  };

  private filterPolygonsByShapeType = (
    polygons: Flatten.Polygon[] | null,
    minNumberOfCorners: number,
    maxNumberOfCorners?: number,
  ) => {
    if (
      !polygons ||
      (maxNumberOfCorners !== undefined &&
        minNumberOfCorners < maxNumberOfCorners)
    ) {
      return [];
    }

    return [...polygons]
      .filter((item) => {
        if (maxNumberOfCorners === undefined) {
          return polygons.filter(
            (item) => item.vertices.length >= minNumberOfCorners,
          );
        }

        return (
          item.vertices.length >= minNumberOfCorners &&
          item.vertices.length <= maxNumberOfCorners
        );
      })
      .sort((polygon1, polygon2) => {
        if (polygon1.vertices.length === polygon2.vertices.length) {
          return polygon2.area() - polygon1.area();
        }

        return polygon1.vertices.length - polygon2.vertices.length;
      });
  };

  private sortPolygonPoints = (points: Flatten.Point[]) =>
    uniqWith(points, isEqual).sort((item1, item2) => {
      if (item1.x === item2.x) {
        return item1.y - item2.y;
      }

      return item1.x - item2.x;
    });

  private createPointsMatrix = (vertices: Flatten.Point[]) => {
    const arrayOfX = [...new Set(vertices.map((item) => item.x))];
    const arrayOfY = [...new Set(vertices.map((item) => item.y))];
    const points: Flatten.Point[] = [...vertices];

    arrayOfX
      .sort((i1, i2) => i1 - i2)
      .forEach((x) => {
        arrayOfY
          .sort((i1, i2) => i1 - i2)
          .forEach((y) => {
            points.push(new Flatten.Point(x, y));
          });
      });

    arrayOfY
      .sort((i1, i2) => i1 - i2)
      .forEach((y) => {
        arrayOfX
          .sort((i1, i2) => i1 - i2)
          .forEach((x) => {
            points.push(new Flatten.Point(x, y));
          });
      });

    return this.sortPolygonPoints(points);
  };

  private getShapesPolygonUnion = (shapes: Shape[]) => {
    const space = this.toPolygon();
    let union = new Flatten.Polygon();

    if (!shapes.length) {
      return null;
    }

    shapes.forEach((shape) => {
      const shapePoly = [
        [
          [shape.corners['bottom-left'][0], shape.corners['bottom-left'][1]],
          [shape.corners['top-left'][0], shape.corners['top-left'][1]],
          [shape.corners['top-right'][0], shape.corners['top-right'][1]],
          [shape.corners['bottom-right'][0], shape.corners['bottom-right'][1]],
        ] as Ring,
      ];
      const polygon = new Flatten.Polygon(shapePoly);

      try {
        if (polygon.isValid() && Flatten.Relations.intersect(space, polygon)) {
          union = Flatten.BooleanOperations.unify(union, polygon);
        }
      } catch (e) {}
    });

    return union;
  };

  private getShapesUnion = (shapes: Shape[]): MultiPolygon => {
    let union: MultiPolygon = [];

    if (!shapes.length) {
      return union;
    }

    shapes.forEach((shape) => {
      const shapePoly = [
        [
          [shape.corners['bottom-left'][0], shape.corners['bottom-left'][1]],
          [shape.corners['top-left'][0], shape.corners['top-left'][1]],
          [shape.corners['top-right'][0], shape.corners['top-right'][1]],
          [shape.corners['bottom-right'][0], shape.corners['bottom-right'][1]],
        ] as Ring,
      ];

      union = polygonClipping.union(union, shapePoly);
    });

    return union;
  };

  private getSpaceShapesXor = () => {
    const space = this.spaceToCorners(this.corners);
    const union: MultiPolygon = this.getShapesUnion(this.spaceShapes);

    if (!this.spaceShapes.length) {
      return null;
    }

    const polygon = new Flatten.Polygon();

    polygonClipping.xor(union, space).forEach((points) => {
      polygon.addFace(points.flat().map((point) => new Flatten.Point(point)));
    });

    if (polygon.isValid() && !polygon.isEmpty()) {
      return polygon;
    }

    return null;
  };

  private isRectangle = (
    polygon: Flatten.Polygon,
    minWidth?: number,
    minHeight?: number,
    simple = true,
  ) => {
    if (
      polygon.vertices.length !== 4 ||
      !polygon.isValid() ||
      polygon.isEmpty()
    ) {
      return false;
    }

    if (minWidth !== undefined && polygon.box.width < minWidth) {
      return false;
    }

    if (minHeight !== undefined && polygon.box.height < minHeight) {
      return false;
    }

    if (simple) {
      return true;
    }

    if (
      uniqWith(polygon.vertices, isEqual).length !== polygon.vertices.length
    ) {
      return false;
    }

    if (uniq(polygon.vertices.map((point) => point.x)).length < 3) {
      return false;
    }

    if (uniq(polygon.vertices.map((point) => point.y)).length < 3) {
      return false;
    }

    return true;
  };

  private findEmptyPolygons = (minWidth: number, minHeight: number) => {
    const polygons: Flatten.Polygon[] = [];
    const space = this.toPolygon();

    if (!space.isValid() || space.isEmpty()) {
      return null;
    }

    const xor = this.getSpaceShapesXor();
    if (xor && this.isRectangle(xor, minWidth, minHeight, false)) {
      return [xor];
    }

    const polygon =
      this.getShapesPolygonUnion(this.spaceShapes) || this.toPolygon();

    try {
      if (
        !polygon.isValid() ||
        polygon.isEmpty() ||
        Flatten.Relations.equal(space, polygon)
      ) {
        return null;
      }
    } catch (e) {}

    const vertices = xor
      ? [...xor.vertices]
      : [...polygon.vertices, ...space.vertices];
    const points = this.createPointsMatrix(vertices);

    [...points].forEach((point1) => {
      [...points].reverse().forEach((point2) => {
        if (!isEqual(point1, point2)) {
          try {
            const segment = new Flatten.Segment(point1, point2);

            const edge1 = space.findEdgeByPoint(point1);
            const edge2 = space.findEdgeByPoint(point2);

            if (space.contains(segment) && !!edge1 && !!edge2) {
              const faces = space.clone().cutFace(point1, point2) || [];
              faces.forEach((face) => {
                if (
                  this.isValidPolygon({
                    polygon: face,
                    polygons,
                    union: polygon,
                    space,
                    minWidth,
                    minHeight,
                  })
                ) {
                  polygons.push(face);
                }
              });
            }
          } catch (e) {}
        }
      });
    });

    if (polygons.length) {
      return polygons.sort((item1, item2) => item2.area() - item1.area());
    }

    let i = 0;

    [...points].forEach((point1) => {
      [...points].reverse().forEach((point2) => {
        [...points].forEach((point3) => {
          [...points].reverse().forEach((point4) => {
            if (
              this.limitNumberOfLoops(points, i) &&
              !isEqual(point1, point2) &&
              !isEqual(point1, point3) &&
              !isEqual(point1, point4) &&
              !isEqual(point2, point3) &&
              !isEqual(point2, point4) &&
              !isEqual(point3, point4)
            ) {
              try {
                const tmp = new Flatten.Polygon([
                  point1,
                  point2,
                  point3,
                  point4,
                ]);

                if (
                  this.isValidPolygon({
                    polygon: tmp,
                    polygons,
                    union: polygon,
                    space,
                    minWidth,
                    minHeight,
                  })
                ) {
                  polygons.push(tmp);
                }
              } catch (e) {}

              i++;
            }
          });
        });
      });
    });

    return polygons.sort((item1, item2) => item2.area() - item1.area());
  };

  private limitNumberOfLoops = (points: Flatten.Point[], counter: number) => {
    const pointsLimit = 14; // after this number algorithm will be very slow
    const countLimit = 1000; // limit number of checks

    if (points.length <= pointsLimit) {
      return true;
    }

    return counter <= countLimit;
  };

  private isValidPolygon = ({
    polygon,
    space,
    polygons,
    minHeight,
    minWidth,
    union,
  }: {
    polygon: Flatten.Polygon;
    space: Flatten.Polygon;
    union: Flatten.Polygon;
    polygons: Flatten.Polygon[];
    minWidth: number;
    minHeight: number;
  }) => {
    return (
      this.isRectangle(polygon, minWidth, minHeight) &&
      Flatten.Relations.cover(space, polygon) &&
      (Flatten.Relations.touch(union, polygon) ||
        !Flatten.Relations.intersect(union, polygon)) &&
      !polygons.find((item) => Flatten.Relations.equal(item, polygon))
    );
  };

  //TODO: Remove this method
  private findNextEmptyShape_old = (width: number, height: number) => {
    if (!this.spaceShapes.length) {
      return new Shape({
        corners: {
          'bottom-left': this.corners['bottom-left'],
          'top-left': this.corners['top-left'],
          'top-right': this.corners['top-right'],
          'bottom-right': this.corners['bottom-right'],
          center: undefined,
          'center-left': undefined,
          'center-right': undefined,
        },
      });
    }

    const polygons = this.filterPolygonsByShapeType(
      this.findEmptyPolygons_old(width, height),
      4,
      4,
    );

    if (!polygons.length) {
      return null;
    }

    const polygon = polygons.shift();

    if (!polygon || !polygon.isValid() || polygon.isEmpty()) {
      return null;
    }

    const corners = polygon.vertices.sort((point1, point2) => {
      if (point1.x === point2.x) {
        return point1.y - point2.y;
      }

      return point1.x - point2.x;
    });

    return new Shape({
      corners: {
        'bottom-left': [corners[1].x, corners[1].y],
        'top-left': [corners[0].x, corners[0].y],
        'top-right': [corners[2].x, corners[2].y],
        'bottom-right': [corners[3].x, corners[3].y],
        center: undefined,
        'center-left': undefined,
        'center-right': undefined,
      },
    });
  };

  public get shapesArea() {
    return this.spaceShapes.reduce((acc, shape) => {
      const polygon = new Flatten.Polygon();
      const shapePoly = [
        [
          [shape.corners['bottom-left'][0], shape.corners['bottom-left'][1]],
          [shape.corners['top-left'][0], shape.corners['top-left'][1]],
          [shape.corners['top-right'][0], shape.corners['top-right'][1]],
          [shape.corners['bottom-right'][0], shape.corners['bottom-right'][1]],
        ] as Ring,
      ];
      return (
        acc +
        polygon
          .addFace(shapePoly.flat().map((point) => new Flatten.Point(point)))
          .area()
      );
    }, 0);
  }

  private getSpaceShapesXor_old = () => {
    const corners = [
      [this.corners['bottom-left'][0], this.corners['bottom-left'][1]],
      [this.corners['top-left'][0], this.corners['top-left'][1]],
      [this.corners['top-right'][0], this.corners['top-right'][1]],
      [this.corners['bottom-right'][0], this.corners['bottom-right'][1]],
    ] as Ring;

    const space = [corners];
    let union: MultiPolygon = [];

    this.spaceShapes.forEach((shape) => {
      const shapePoly = [
        [
          [shape.corners['bottom-left'][0], shape.corners['bottom-left'][1]],
          [shape.corners['top-left'][0], shape.corners['top-left'][1]],
          [shape.corners['top-right'][0], shape.corners['top-right'][1]],
          [shape.corners['bottom-right'][0], shape.corners['bottom-right'][1]],
        ] as Ring,
      ];

      union = polygonClipping.union(union, shapePoly);
    });

    const polygon = new Flatten.Polygon();

    polygonClipping.xor(union, space).forEach((points) => {
      polygon.addFace(points.flat().map((point) => new Flatten.Point(point)));
    });

    return polygon;
  };

  private findEmptyPolygons_old = (minWidth: number, minHeight: number) => {
    const polygons: Flatten.Polygon[] = [];
    const space = this.toPolygon();

    if (!space.isValid() || space.isEmpty()) {
      return null;
    }

    const polygon = this.getSpaceShapesXor_old();

    if (
      !polygon.isValid() ||
      polygon.isEmpty() ||
      Flatten.Relations.equal(space, polygon)
    ) {
      return null;
    }

    const bbox = new Flatten.Polygon(polygon.box.toPoints());

    if (
      this.isRectangle(bbox, minWidth, minHeight) &&
      !Flatten.Relations.equal(space, polygon) &&
      Flatten.Relations.equal(polygon, bbox)
    ) {
      return [polygon];
    }

    const points = this.createPointsMatrix(polygon.vertices);

    [...points].forEach((point1) => {
      [...points].reverse().forEach((point2) => {
        if (point1.x !== point2.x || point1.y !== point2.y) {
          const segment = new Flatten.Segment(point1, point2);

          const edge1 = polygon.findEdgeByPoint(point1);
          const edge2 = polygon.findEdgeByPoint(point2);

          if (polygon.contains(segment) && !!edge1 && !!edge2) {
            const faces = polygon.clone().cutFace(point1, point2) || [];

            faces.forEach((face) => {
              if (
                this.isRectangle(face, minWidth, minHeight) &&
                Flatten.Relations.intersect(space, face) &&
                !polygons.find((item) => Flatten.Relations.equal(item, face))
              ) {
                polygons.push(face);
              }
            });
          }
        }
      });
    });

    return polygons.sort((item1, item2) => item2.area() - item1.area());
  };
}
