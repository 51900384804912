import { useCallback } from 'react';
import { Glass } from '../../../services/api/models/glass';
import { Anchor } from '../types/Anchor';
import { AllGapsType, Gaps, GapType } from '../types/Gap';
import {
  findBottomBoundary,
  findLeftBoundary,
  findRightBoundary,
  findTopBoundary,
  Obstacles,
} from '../utils/boundaries';
import { NeighborType } from '../utils/neighbors';
import { Shape } from '../../space';
import { ProjectDimensions } from '../../../services/api/models/project';
import { findPointDistanceFromVector } from '../utils/geometry/vectors';
import Big from 'big.js';

export const MAX_GAP_DIMENSIONS = 50;

export interface UseGaps {
  findTopGap(glass: Glass, updatedGlassesList?: Glass[]): GapType;
  findBottomGap(glass: Glass, updatedGlassesList?: Glass[]): GapType;
  findLeftGap(glass: Glass, updatedGlassesList?: Glass[]): GapType;
  findRightGap(glass: Glass, updatedGlassesList?: Glass[]): GapType;
  findGaps(glass: Glass): Gaps;
  findAllGaps(): AllGapsType[];
  findGapOnAnchor(anchor: Anchor, glass: Glass): GapType | undefined;
}

const mapObstacleToNeighbor = (obstacle: Obstacles) =>
  obstacle === Obstacles.OBJECT ? NeighborType.GLASS : NeighborType.WALL;

interface UseGapsProps {
  updatedGlassesList?: Glass[];
  glasses: Glass[];
  templatePosition: { x: number; y: number };
  templateDimensions: ProjectDimensions;
}

const useGaps = ({
  glasses,
  templatePosition,
  templateDimensions,
  updatedGlassesList,
}: UseGapsProps): UseGaps => {
  const roundGaps = useCallback((gaps: number[]): [number, number] => {
    const [first, second] = gaps;
    return [
      Big(first).abs().round(2).toNumber(),
      Big(second).abs().round(2).toNumber(),
    ];
  }, []);

  const findTopGap = useCallback(
    (glass: Glass, updatedGlassesList?: Glass[]): GapType => {
      const glassObj = new Shape({ corners: glass.corners }, glass.position);

      const parents = [
        {
          position: templatePosition,
          corners: templateDimensions.corners,
          indent: templateDimensions.indent,
        },
      ];
      const { value, obstacle, obstacleId } = findTopBoundary({
        shape: glass,
        shapes: updatedGlassesList ?? glasses,
        parents,
        scale: 1,
      });

      const left = findPointDistanceFromVector(
        glassObj.topEdge.points.left,
        value,
      );
      const right = findPointDistanceFromVector(
        glassObj.topEdge.points.right,
        value,
      );

      return {
        value: roundGaps([left.y, right.y]),
        neighbor: mapObstacleToNeighbor(obstacle),
        neighborId: obstacleId,
      };
    },
    [glasses, updatedGlassesList, templateDimensions.corners, templatePosition],
  );

  const findBottomGap = useCallback(
    (glass: Glass, updatedGlassesList?: Glass[]): GapType => {
      const glassObj = new Shape({ corners: glass.corners }, glass.position);
      const parents = [
        {
          position: templatePosition,
          corners: templateDimensions.corners,
          indent: templateDimensions.indent,
        },
      ];
      const { value, obstacle, obstacleId } = findBottomBoundary({
        shape: glass,
        shapes: updatedGlassesList ?? glasses,
        parents,
        scale: 1,
      });

      const left = findPointDistanceFromVector(
        glassObj.bottomEdge.points.left,
        value,
      );
      const right = findPointDistanceFromVector(
        glassObj.bottomEdge.points.right,
        value,
      );

      return {
        value: roundGaps([left.y, right.y]),
        neighbor: mapObstacleToNeighbor(obstacle),
        neighborId: obstacleId,
      };
    },
    [glasses, updatedGlassesList, templateDimensions.corners, templatePosition],
  );

  const findLeftGap = useCallback(
    (glass: Glass, updatedGlassesList?: Glass[]): GapType => {
      const glassObj = new Shape({ corners: glass.corners }, glass.position);
      const parents = [
        {
          position: templatePosition,
          corners: templateDimensions.corners,
          indent: templateDimensions.indent,
          id: 0,
        },
      ];
      const { value, obstacle, obstacleId } = findLeftBoundary({
        shape: glass,
        shapes: updatedGlassesList ?? glasses,
        parents,
        scale: 1,
      });

      const top = findPointDistanceFromVector(
        glassObj.leftEdge.points.top,
        value,
      );
      const bottom = findPointDistanceFromVector(
        glassObj.leftEdge.points.bottom,
        value,
      );

      return {
        value: roundGaps([top.x, bottom.x]),
        neighbor: mapObstacleToNeighbor(obstacle),
        neighborId: obstacleId,
      };
    },
    [glasses, updatedGlassesList, templateDimensions.corners, templatePosition],
  );

  const findRightGap = useCallback(
    (glass: Glass, updatedGlassesList?: Glass[]): GapType => {
      const glassObj = new Shape({ corners: glass.corners }, glass.position);
      const parents = [
        {
          position: templatePosition,
          corners: templateDimensions.corners,
          indent: templateDimensions.indent,
        },
      ];
      const { value, obstacle, obstacleId } = findRightBoundary({
        shape: glass,
        shapes: updatedGlassesList ?? glasses,
        parents,
        scale: 1,
      });

      const top = findPointDistanceFromVector(
        glassObj.rightEdge.points.top,
        value,
      );
      const bottom = findPointDistanceFromVector(
        glassObj.rightEdge.points.bottom,
        value,
      );

      return {
        value: roundGaps([top.x, bottom.x]),
        neighbor: mapObstacleToNeighbor(obstacle),
        neighborId: obstacleId,
      };
    },
    [glasses, updatedGlassesList, templateDimensions.corners, templatePosition],
  );

  const findGaps = useCallback(
    (glass: Glass) => ({
      top: findTopGap(glass),
      bottom: findBottomGap(glass),
      left: findLeftGap(glass),
      right: findRightGap(glass),
    }),
    [findTopGap, findBottomGap, findLeftGap, findRightGap],
  );

  const findGapOnAnchor = useCallback(
    (anchor: Anchor, glass: Glass) => {
      switch (anchor) {
        case 'top': {
          return findTopGap(glass);
        }
        case 'left': {
          return findLeftGap(glass);
        }
        case 'right': {
          return findRightGap(glass);
        }
        case 'bottom': {
          return findBottomGap(glass);
        }
      }
    },
    [findBottomGap, findLeftGap, findRightGap, findTopGap],
  );
  const findAllGaps = useCallback(() => {
    return glasses.map((glass) => ({
      glass,
      boundaries: {
        top: findTopGap(glass),
        bottom: findBottomGap(glass),
        left: findLeftGap(glass),
        right: findRightGap(glass),
      },
    }));
  }, [findBottomGap, findLeftGap, findRightGap, findTopGap, glasses]);

  return {
    findTopGap,
    findBottomGap,
    findLeftGap,
    findRightGap,
    findGaps,
    findAllGaps,
    findGapOnAnchor,
  };
};

export default useGaps;
