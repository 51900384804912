import create, { GetState, SetState } from 'zustand';
import createVanilla from 'zustand/vanilla';
import { devtools, persist } from 'zustand/middleware';
import {
  IndentPosition,
  Project,
  ProjectData,
  ProjectDimensions,
  ProjectStatus,
} from '../../../../services/api/models/project';
import { Glass } from '../../../../services/api/models/glass';
import { Dimensions, Position } from '../../types';
import { templateStore } from '../template';
import { historyStore } from '../history';
import { selectStore } from '../select';
import { HistoryItem } from '../history/history';
import { projectStore } from './index';
import { createGlass, generateNewGlassId } from '../../utils/create-glass';
import { fixPosition } from '../../utils/fix';
import {
  ProductModel,
  ProductSearch,
} from '../../../../services/api/models/product';
import { productsStore } from '../products';
import { GlassNeighbors } from '../../utils/neighbors';
import { getCoordinatesForIndent } from '../../../../utils/shape';
import { v4 as uuid } from 'uuid';
import { Shape, Shape as ShapeClass, Space } from '../../../space';
import { calculateGlassOriginCorners } from '../../../../utils/glass';

export const STORE_NAME = `@store/creator/project`;

export const DEFAULT_PROJECT_ID = -1;

const defaultProject: Project = {
  id: DEFAULT_PROJECT_ID,
  name: '',
  projectStatus: ProjectStatus.CLEAR,
  productsUsed: [],
  data: {
    adjustManually: false,
    width: 0,
    height: 0,
    products: [],
    glassSheets: [],
    unsafe: false,
    scale: 1,
    position: { x: 0, y: 0 },
    dimensions: {
      corners: {
        'top-left': [0, 0],
        'top-right': [1000, 0],
        'bottom-left': [0, 2000],
        'bottom-right': [1000, 2000],
      },
    },
  },
};

export type State = {
  historyPointer: number;
  collidingGlasses: Set<number>;
  saved: boolean;
  showAngles: boolean;
  setup(data: ProjectData): void;
  setProject(project: Project): void;
  setProjectName(name: string): void;
  update(project: Project, saved?: boolean): void;
  updateDimensions(
    dimensions: ProjectDimensions,
    position?: Position,
    history?: boolean,
  ): void;
  updateCorners(corners: ProjectDimensions, history?: boolean): void;
  updatePosition(position: Position): void;
  clear(): void;
  undo(): void;
  redo(): void;
  save(): void;
  openLeftPanel: boolean;
  openRightPanel: boolean;
  toggleLeftPanel(): void;
  toggleRightPanel(): void;
  toggleShowAngles(): void;
  toggleAdjustManually(): void;
  updateHistory(scaledDimensions?: Dimensions): void;
  addGlass(
    thickness: number,
    onWarning?: () => void,
    onGlassCreationInProgress?: () => void,
  ): Promise<void>;
  pasteGlass(glass: Glass, onError?: () => void): void;
  updateGlass(glass: Glass, skipOrigin?: boolean): void;
  updateGlassList(glass: Glass[], skipOrigin?: boolean): void;
  deleteGlass(glassId: number, callback?: () => void): void;
  addProduct(
    product: ProductModel,
    originProduct: ProductSearch,
    callback?: (id: string) => void,
  ): void;
  pasteProduct(product: ProductModel, callback?: () => void): void;
  updateProduct(product: ProductModel, callback?: () => void): void;
  updateManyProducts(products: ProductModel[], callback?: () => void): void;
  deleteProduct(internalId: string, callback?: () => void): void;
  deleteManyProducts(iids: string[], callback?: () => void): void;
  unsafeProject(): void;
  clearIndent(): void;
  setIndent(indent: IndentPosition): void;
  glassNeighbors: Record<number, GlassNeighbors>;
  updateGlassNeighbors(glassId: number, neighbors: GlassNeighbors): void;
  removeGlassNeighbors(glassId: number): void;
  project: Project;
  validatedHistoryPointer: number;
  validateProject: boolean;
  toggleValidateProject(): void;
  setValidatedHistoryPointer(pointer: number): void;
};

const insertHistory = (project: Project, index?: number): number => {
  const { insert } = historyStore.getState();
  return insert(project, index);
};

const setupAction = (set: SetState<State>) => (data: ProjectData) => {
  const project: Project = {
    data,
    id: DEFAULT_PROJECT_ID,
    projectStatus: ProjectStatus.DRAFT,
    name: '',
    productsUsed: {},
  };
  const { setBaseBreakingPoint, setConfigured } = templateStore.getState();
  const { clear: clearHistory } = historyStore.getState();
  clearHistory();
  if (data.breakingPoint) {
    setBaseBreakingPoint(data.breakingPoint);
  }
  set({
    openLeftPanel: true,
    openRightPanel: true,
    project,
    saved: true,
  });
  setConfigured();
};

const updateAction = (set: SetState<State>) => (
  project: Project,
  afterSave = false,
) => {
  const { historyPointer: currentPointer } = projectStore.getState();
  const historyPointer = insertHistory(project, currentPointer + 1);
  return set({
    historyPointer,
    project: project,
    saved: afterSave,
  });
};

const updateDimensionsAction = (set: SetState<State>, get: GetState<State>) => (
  dimensions: ProjectDimensions,
  position?: Position,
  history = false,
) => {
  const { project: oldProject } = get();
  if (oldProject) {
    const projectObj = new ShapeClass(dimensions);

    const project = {
      ...oldProject,
      data: {
        ...oldProject.data,
        position: position ?? oldProject.data.position,
        dimensions,
        width: projectObj.width,
        height: projectObj.height,
      },
    };
    if (history) {
      const historyPointer = insertHistory(project);
      return set({
        historyPointer,
        project,
      });
    }
    return set({
      project,
    });
  }
};

const updateCornersAction = (set: SetState<State>, get: GetState<State>) => (
  dimensions: ProjectDimensions,
  history = false,
) => {
  const { project: oldProject } = get();
  if (oldProject) {
    const project = {
      ...oldProject,
      data: { ...oldProject.data, dimensions },
    };

    if (history) {
      const historyPointer = insertHistory(project);
      return set({
        historyPointer,
        project,
      });
    }
    return set({
      project,
    });
  }
};

const updatePositionAction = (set: SetState<State>, get: GetState<State>) => (
  position: Position,
) => {
  const { project: oldProject } = get();
  if (oldProject) {
    const project = {
      ...oldProject,
      data: { ...oldProject.data, position },
    };
    return set({
      project,
    });
  }
};

export enum HistoryAction {
  UNDO,
  REDO,
}

const movePointer = (
  type: HistoryAction,
  currentPointer: number,
  history: HistoryItem[],
): number => {
  switch (type) {
    case HistoryAction.REDO: {
      const limit = history.length - 1;
      return currentPointer < limit ? currentPointer + 1 : limit;
    }
    case HistoryAction.UNDO: {
      return currentPointer > 0 ? currentPointer - 1 : 0;
    }
    default: {
      return currentPointer;
    }
  }
};

const historyAction = (
  type: HistoryAction,
  set: SetState<State>,
  get: GetState<State>,
) => () => {
  const { historyPointer } = get();
  const { get: getHistory, history } = historyStore.getState();
  const { deselect } = selectStore.getState();
  deselect();
  const pointer = movePointer(type, historyPointer, history);
  const entry = getHistory(pointer);
  if (entry && pointer !== historyPointer) {
    const projectData = entry.project.data;
    set({
      project: { ...entry.project, data: projectData },
      historyPointer: pointer,
    });
  }
};

const updateHistoryAction = (
  set: SetState<State>,
  get: GetState<State>,
) => () => {
  const { project, historyPointer } = get();
  if (project) {
    const newPointer = insertHistory(project, historyPointer + 1);
    return set({ historyPointer: newPointer, saved: false });
  }
};

const setProjectAction = (set: SetState<State>) => (project: Project) => {
  const { clear: clearHistory } = historyStore.getState();

  const { setConfigured, setBaseBreakingPoint } = templateStore.getState();
  clearHistory();
  if (project.data.breakingPoint) {
    setBaseBreakingPoint(project.data.breakingPoint);
  }
  const historyPointer = insertHistory(project);

  set({
    historyPointer,
    project,
    saved: true,
    openLeftPanel: true,
    openRightPanel: true,
  });
  setConfigured();
};

const clearAction = (set: SetState<State>) => () => {
  const { clear: clearHistory } = historyStore.getState();
  const { clear: clearProducts } = productsStore.getState();
  clearHistory();
  clearProducts();
  set({
    historyPointer: 0,
    validatedHistoryPointer: 0,
    saved: true,
    openLeftPanel: false,
    openRightPanel: false,
    project: defaultProject,
    glassNeighbors: {},
    collidingGlasses: new Set(),
  });
};

const setProjectNameAction = (set: SetState<State>, get: GetState<State>) => (
  name: string,
) => {
  const { project: old, historyPointer: currentPointer } = get();
  if (old) {
    const project = { ...old, name };
    const historyPointer = insertHistory(project, currentPointer + 1);
    set({
      project,
      historyPointer,
      saved: false,
    });
  }
};

const addGlassAction = (get: GetState<State>) => async (
  thickness: number,
  onWarning?: () => void,
  onGlassCreationInProgress?: () => void,
  onFallbackGlassCreation?: () => void,
  onComplete?: () => void,
) => {
  const { project, update } = get();
  const { select } = selectStore.getState();
  if (project) {
    const { glass } = await createGlass(
      project.data.dimensions,
      project.data.position ?? { x: 0, y: 0 },
      project.data.glassSheets,
      thickness,
      onGlassCreationInProgress,
      onFallbackGlassCreation,
      onComplete,
    );

    if (glass) {
      const { glassSheets } = project.data;
      const newGlassSet = [...glassSheets, glass];
      const updatedProj: Project = {
        ...project,
        data: { ...project.data, glassSheets: newGlassSet },
      };

      const updatedSpace = new Space(updatedProj.data.dimensions);

      updatedSpace.addShapes(newGlassSet.map((item) => new ShapeClass(item)));

      update(updatedProj);
      select({ shapeId: glass.id, type: 'glass' });
    } else {
      onWarning?.();
    }
  } else {
    throw new Error('Missing project');
  }
};

const pasteGlassAction = (get: GetState<State>) => (glass: Glass) => {
  const { project, update } = get();
  if (project) {
    const { glassSheets } = project.data;
    const newGlass = { ...glass, id: generateNewGlassId(glassSheets) };
    const newGlassSet = [...glassSheets, newGlass];
    const updatedProj = {
      ...project,
      data: { ...project.data, glassSheets: newGlassSet },
    };
    update(updatedProj);
  }
};

const updateGlassAction = (get: GetState<State>) => (
  updatedGlass: Glass,
  skipOriginRecalc = false,
) => {
  const { project, update } = get();

  const { glassSheets } = project.data;
  const oldGlass = glassSheets.filter((item) => item.id === updatedGlass.id)[0];

  const glass = new Shape(
    { corners: updatedGlass.corners },
    updatedGlass.position,
  );

  const modifiedGlass = {
    ...updatedGlass,
    position: fixPosition(updatedGlass.position, 8),
    width: glass.width,
    height: glass.height,
  };

  if (!skipOriginRecalc) {
    modifiedGlass.originCorners = calculateGlassOriginCorners(
      oldGlass.corners,
      updatedGlass.corners,
      oldGlass.originCorners,
    );
  }

  const newGlassSet = [
    ...glassSheets.filter((item) => item.id !== updatedGlass.id),
    modifiedGlass,
  ];
  const updatedProj = {
    ...project,
    data: { ...project.data, glassSheets: newGlassSet },
  };
  update(updatedProj);
};

const updateGlassListAction = (get: GetState<State>) => (
  updatedGlassList: Glass[],
  skipOriginRecalc = false,
) => {
  if (!updatedGlassList.length) {
    return;
  }

  const { project, update } = get();

  const { glassSheets } = project.data;

  let newGlassSet = [...glassSheets];

  updatedGlassList.forEach((updatedGlass) => {
    const oldGlass = glassSheets.filter(
      (item) => item.id === updatedGlass.id,
    )[0];

    const glass = new Shape(
      { corners: updatedGlass.corners },
      updatedGlass.position,
    );

    const modifiedGlass = {
      ...updatedGlass,
      position: fixPosition(updatedGlass.position, 8),
      width: glass.width,
      height: glass.height,
    };

    if (!skipOriginRecalc) {
      modifiedGlass.originCorners = calculateGlassOriginCorners(
        oldGlass.corners,
        updatedGlass.corners,
        oldGlass.originCorners,
      );
    }

    newGlassSet = [
      ...newGlassSet.filter((item) => item.id !== updatedGlass.id),
      modifiedGlass,
    ];
  });

  const updatedProj = {
    ...project,
    data: { ...project.data, glassSheets: newGlassSet },
  };
  update(updatedProj);
};

const deleteGlassAction = (get: GetState<State>) => (
  glassId: number,
  callback?: () => void,
) => {
  const { project, update, removeGlassNeighbors } = get();
  if (project) {
    const { glassSheets } = project.data;
    const newGlassSet = [...glassSheets.filter((item) => item.id !== glassId)];
    const updatedProj = {
      ...project,
      data: { ...project.data, glassSheets: newGlassSet },
    };
    update(updatedProj);
    removeGlassNeighbors(glassId);
    callback?.();
  } else {
    throw new Error('Missing project');
  }
};

const addProductAction = (get: GetState<State>) => (
  product: ProductModel,
  originProduct: ProductSearch,
  callback?: (id: string) => void,
) => {
  const { project, update } = get();
  if (project) {
    const { products, glassSheets } = project.data;
    const newProductId = uuid();

    const updatedProj = {
      ...project,
      productsUsed: {
        ...project.productsUsed,
        [originProduct.id]: originProduct,
      },
      data: {
        ...project.data,
        products: [...products, { ...product, id: newProductId }],
      },
    };
    update(updatedProj);
    callback?.(newProductId);
  } else {
    throw new Error('Missing project');
  }
};

const pasteProductAction = (get: GetState<State>) => (
  product: ProductModel,
  callback?: () => void,
) => {
  const { project, addProduct } = get();
  if (project) {
    const originProduct = project.productsUsed[product.productId];
    if (originProduct) {
      addProduct(product, originProduct, callback);
    } else {
      throw new Error('Cannot find product');
    }
  } else {
    throw new Error('Missing project');
  }
};

const deleteProductAction = (get: GetState<State>) => (
  iid: string,
  callback?: () => void,
) => {
  const { project, update } = get();
  if (project) {
    const { products } = project.data;
    const newProductSet = products.filter((item) => item.id !== iid);
    const updatedProj = {
      ...project,
      data: { ...project.data, products: newProductSet },
    };
    update(updatedProj);
    callback?.();
  } else {
    throw new Error('Missing project');
  }
};

const deleteManyProductsAction = (get: GetState<State>) => (
  iids: string[],
  callback?: () => void,
) => {
  const { project, update } = get();
  if (project) {
    const { products } = project.data;
    const newProductSet = products.filter((item) => !iids.includes(item.id));
    const updatedProj = {
      ...project,
      data: { ...project.data, products: newProductSet },
    };
    update(updatedProj);
    callback?.();
  } else {
    throw new Error('Missing project');
  }
};

const updateProductAction = (get: GetState<State>) => (
  product: ProductModel,
  callback?: () => void,
) => {
  const { project, update } = get();
  if (project) {
    const { products } = project.data;
    const newProductSet = products.filter((item) => item.id !== product.id);
    const updatedProj = {
      ...project,
      data: { ...project.data, products: [...newProductSet, product] },
    };
    update(updatedProj);
    callback?.();
  } else {
    throw new Error('Missing project');
  }
};

const updateManyProductsAction = (get: GetState<State>) => (
  products: ProductModel[],
  callback?: () => void,
) => {
  const { project, update } = get();
  if (project) {
    const { products: origin } = project.data;
    const newIds = products.map((product) => product.id);
    const newProductSet = origin.filter((item) => !newIds.includes(item.id));
    const updatedProj = {
      ...project,
      data: { ...project.data, products: [...newProductSet, ...products] },
    };
    update(updatedProj);
    callback?.();
  } else {
    throw new Error('Missing project');
  }
};

const unsafeProjectAction = (
  set: SetState<State>,
  get: GetState<State>,
) => () => {
  const { project } = get();
  if (project) {
    set({ project: { ...project, data: { ...project.data, unsafe: true } } });
  }
};

const clearIndentAction = (
  set: SetState<State>,
  get: GetState<State>,
) => () => {
  const { project } = get();
  if (project) {
    const { data } = project;
    const updatedData: ProjectData = {
      ...data,
      dimensions: {
        ...data.dimensions,
        indent: undefined,
      },
    };
    set({ project: { ...project, data: updatedData } });
  }
};

const setIndentAction = (set: SetState<State>, get: GetState<State>) => (
  indent: IndentPosition,
) => {
  const { project } = get();
  if (project) {
    const { data } = project;
    const updatedData: ProjectData = {
      ...data,
      dimensions: {
        indent,
        corners: getCoordinatesForIndent(
          data.width,
          data.height,
          data.position ?? { x: 0, y: 0 },
          indent,
        ),
      },
    };
    set({ project: { ...project, data: updatedData } });
  }
};

const updateGlassNeighborsAction = (
  set: SetState<State>,
  get: GetState<State>,
) => (id: number, neighbors: GlassNeighbors) => {
  const { glassNeighbors } = get();
  const newState = { ...glassNeighbors, [id]: neighbors };
  set({ glassNeighbors: newState });
};

const removeGlassNeighborsAction = (
  set: SetState<State>,
  get: GetState<State>,
) => (id: number) => {
  const { glassNeighbors } = get();
  const newState = { ...glassNeighbors };
  delete newState[id];
  set({ glassNeighbors: newState });
};

const setValidatedHistoryPointerAction = (set: SetState<State>) => (
  pointer: number,
) => {
  set({ validatedHistoryPointer: pointer });
};

const store = (set: SetState<State>, get: GetState<State>): State => ({
  project: defaultProject,
  collidingGlasses: new Set(),
  historyPointer: 0,
  validatedHistoryPointer: 0,
  saved: true,
  openLeftPanel: false,
  openRightPanel: false,
  showAngles: false,
  validateProject: false,
  update: updateAction(set),
  updateDimensions: updateDimensionsAction(set, get),
  updatePosition: updatePositionAction(set, get),
  setup: setupAction(set),
  setProject: setProjectAction(set),
  setProjectName: setProjectNameAction(set, get),
  undo: historyAction(HistoryAction.UNDO, set, get),
  redo: historyAction(HistoryAction.REDO, set, get),
  setValidatedHistoryPointer: setValidatedHistoryPointerAction(set),
  save: () => set({ saved: true }),
  toggleLeftPanel: () => set({ openLeftPanel: !get().openLeftPanel }),
  toggleRightPanel: () => set({ openRightPanel: !get().openRightPanel }),
  toggleShowAngles: () => set({ showAngles: !get().showAngles }),
  toggleAdjustManually: () =>
    set({
      project: {
        ...get().project,
        data: {
          ...get().project.data,
          adjustManually: !get().project.data.adjustManually,
        },
      },
    }),
  toggleValidateProject: () => set({ validateProject: !get().validateProject }),
  updateHistory: updateHistoryAction(set, get),
  clear: clearAction(set),
  addGlass: addGlassAction(get),
  pasteGlass: pasteGlassAction(get),
  updateGlass: updateGlassAction(get),
  updateGlassList: updateGlassListAction(get),
  updateCorners: updateCornersAction(set, get),
  deleteGlass: deleteGlassAction(get),
  addProduct: addProductAction(get),
  pasteProduct: pasteProductAction(get),
  updateProduct: updateProductAction(get),
  updateManyProducts: updateManyProductsAction(get),
  deleteProduct: deleteProductAction(get),
  deleteManyProducts: deleteManyProductsAction(get),
  unsafeProject: unsafeProjectAction(set, get),
  clearIndent: clearIndentAction(set, get),
  setIndent: setIndentAction(set, get),
  glassNeighbors: {},
  updateGlassNeighbors: updateGlassNeighborsAction(set, get),
  removeGlassNeighbors: removeGlassNeighborsAction(set, get),
});

const persistedStore = persist(store, {
  name: STORE_NAME,
  serialize: (data) => {
    return JSON.stringify({
      ...data,
      state: {
        ...data.state,
        collidingGlasses: Array.from(data.state.collidingGlasses),
      },
    });
  },
  deserialize: (data) => {
    const parsed = JSON.parse(data);
    return {
      ...parsed,
      state: {
        ...parsed.state,
        collidingGlasses: new Set(parsed.state.collidingGlasses),
      },
    };
  },
});

export const vanillaStore = createVanilla<State>(
  devtools(persistedStore, STORE_NAME),
);

export const useStore = create<State>(vanillaStore);
