import Point from '../../../space/Point';

const POINTS_EPSILON = 0.01;

export const calculateMidPoint = (p1: number[], p2: number[]) => ({
  x: (p1[0] + p2[0]) / 2,
  y: (p1[1] + p2[1]) / 2,
});

export const checkPointsEqual = (p1: number[], p2: number[]) =>
  Math.abs(p1[0] - p2[0]) < POINTS_EPSILON &&
  Math.abs(p1[1] - p2[1]) < POINTS_EPSILON;

export const calculatePoint = (p1: number[]) => ({
  x: p1[0],
  y: p1[1],
});

export const convertToPoint = (pointArray: [number, number]) => {
  return { x: pointArray[0], y: pointArray[1] } as Point;
};
