import Konva from 'konva';
import {
  ANCHOR_POINTS,
  DisabledEdge,
} from '../../../services/api/models/transformer';
import { DEFAULT_RATIO } from '../store/config';
import { BoundariesCopy } from '../types';
import {
  IndentPosition,
  ProjectDimensions,
} from '../../../services/api/models/project';
import map from 'lodash/map';
import {
  IndentCorners,
  IndentCornersType,
  IndentDimensions,
} from '../../../types';
import forEach from 'lodash/forEach';
import { findXOnSegment, findYOnSegment } from '../../../utils/shape';
import { Shape } from '../../space';
import {
  convertToGlass,
  DataCopy,
  getParentPolygon,
  GlassWithEdges,
} from './boundaries';
import { RectCornersType } from '../../../types';
import { ExtendedDataCopy } from '../components/Transformer/Transformer';
import find from 'lodash/find';
import { getCrossedEdge, getOppositeCorner } from './resizeValidators';
import {
  findCrossPoint,
  findLine,
  findParallelLine,
  findYPointOnLine,
} from './geometry/lines';
import { calculateMidPoint, calculatePoint } from './geometry/points';
import {
  findPointDistanceFromVector,
  getVectorLength,
} from './geometry/vectors';

export interface CollisionData {
  shapeId: number;
  colliderId: number;
  cornerName: string;
  length: number;
}

const filterAnchors = (
  anchors: { id: ANCHOR_POINTS; pos: Konva.Vector2d }[],
  disableEdge?: DisabledEdge,
) => {
  if (disableEdge?.left) {
    anchors = anchors.filter((anchor) => !anchor.id.includes('left'));
  }
  if (disableEdge?.right) {
    anchors = anchors.filter((anchor) => !anchor.id.includes('right'));
  }
  if (disableEdge?.top) {
    anchors = anchors.filter((anchor) => !anchor.id.includes('top'));
  }
  if (disableEdge?.bottom) {
    anchors = anchors.filter((anchor) => !anchor.id.includes('bottom'));
  }
  return anchors
    .reverse()
    .filter(
      (item, index, self) => self.findIndex((v) => v.id === item.id) === index,
    );
};

const calculateIndentMidPoint = (
  indent: IndentPosition,
  corners: IndentCorners,
  anchor: ANCHOR_POINTS,
) => {
  let point;

  if (anchor === ANCHOR_POINTS.LEFT) {
    switch (indent) {
      case IndentPosition.BOTTOM_LEFT:
        point = calculateMidPoint(
          corners['top-left'],
          corners['center-left']! || [0, 0],
        );
        break;
      case IndentPosition.TOP_LEFT:
        point = calculateMidPoint(
          corners['center-left'] || [0, 0],
          corners['bottom-left'],
        );
        break;
    }
  }
  if (anchor === ANCHOR_POINTS.RIGHT) {
    switch (indent) {
      case IndentPosition.BOTTOM_RIGHT:
        point = calculateMidPoint(
          corners['top-right'],
          corners['center-right'] || [0, 0],
        );
        break;
      case IndentPosition.TOP_RIGHT:
        point = calculateMidPoint(
          corners['center-right'] || [0, 0],
          corners['bottom-right'],
        );
        break;
    }
  }
  return point;
};

export const getAnchorPointsCopy = (
  dimensions: ProjectDimensions,
  disabledEdge?: DisabledEdge,
  forLabels?: boolean,
) => {
  const { corners, indent } = dimensions;

  const anchors = [
    {
      id: ANCHOR_POINTS.TOP_LEFT,
      pos: calculatePoint(corners['top-left']),
    },
    {
      id: ANCHOR_POINTS.BOTTOM_LEFT,
      pos: calculatePoint(corners['bottom-left']),
    },
    {
      id: ANCHOR_POINTS.BOTTOM_RIGHT,
      pos: calculatePoint(corners['bottom-right']),
    },
    {
      id: ANCHOR_POINTS.TOP_RIGHT,
      pos: calculatePoint(corners['top-right']),
    },
    {
      id: ANCHOR_POINTS.LEFT,
      pos:
        indent === IndentPosition.BOTTOM_LEFT ||
        indent === IndentPosition.TOP_LEFT
          ? calculateIndentMidPoint(
              indent,
              corners as IndentCorners,
              ANCHOR_POINTS.LEFT,
            )
          : calculateMidPoint(corners['top-left'], corners['bottom-left']),
    },
    {
      id: ANCHOR_POINTS.BOTTOM,
      pos: calculateMidPoint(corners['bottom-left'], corners['bottom-right']),
    },
    {
      id: ANCHOR_POINTS.RIGHT,
      pos:
        indent === IndentPosition.BOTTOM_RIGHT ||
        indent === IndentPosition.TOP_RIGHT
          ? calculateIndentMidPoint(
              indent,
              corners as IndentCorners,
              ANCHOR_POINTS.RIGHT,
            )
          : calculateMidPoint(corners['top-right'], corners['bottom-right']),
    },
    {
      id: ANCHOR_POINTS.TOP,
      pos: calculateMidPoint(corners['top-left'], corners['top-right']),
    },
  ];

  if (dimensions.indent) {
    const center = dimensions.corners['center'];

    if (dimensions.corners['center-left']) {
      const centerLeft = [...dimensions.corners['center-left']];

      anchors.push({
        id: ANCHOR_POINTS.CENTER_LEFT,
        pos: calculatePoint(centerLeft),
      });

      if (forLabels) {
        anchors.push({
          id: ANCHOR_POINTS.CENTER_CENTER_LEFT,
          pos: calculateMidPoint(centerLeft, center),
        });
      }

      if (indent === IndentPosition.TOP_LEFT && centerLeft) {
        anchors.push({
          id: ANCHOR_POINTS.LEFT,
          pos: calculateMidPoint(centerLeft, corners['bottom-left']),
        });

        if (forLabels) {
          anchors.push({
            id: ANCHOR_POINTS.CENTER_CENTER_TOP,
            pos: calculateMidPoint(corners['top-left'], center),
          });
        }
      }

      if (indent === IndentPosition.BOTTOM_LEFT) {
        anchors.push({
          id: ANCHOR_POINTS.LEFT,
          pos: calculateMidPoint(centerLeft, dimensions.corners['top-left']),
        });

        if (forLabels) {
          anchors.push({
            id: ANCHOR_POINTS.CENTER_CENTER_BOTTOM,
            pos: calculateMidPoint(dimensions.corners['bottom-left'], center),
          });
        }
      }
    }

    if (dimensions.corners['center-right']) {
      const centerRight = [...dimensions.corners['center-right']];

      anchors.push({
        id: ANCHOR_POINTS.CENTER_RIGHT,
        pos: calculatePoint(centerRight),
      });

      if (forLabels) {
        anchors.push({
          id: ANCHOR_POINTS.CENTER_CENTER_RIGHT,
          pos: calculateMidPoint(centerRight, center),
        });
      }

      if (indent === IndentPosition.BOTTOM_RIGHT) {
        anchors.push({
          id: ANCHOR_POINTS.RIGHT,
          pos: calculateMidPoint(centerRight, corners['top-right']),
        });

        if (forLabels) {
          anchors.push({
            id: ANCHOR_POINTS.CENTER_CENTER_BOTTOM,
            pos: calculateMidPoint(dimensions.corners['bottom-right'], center),
          });
        }
      }

      if (indent === IndentPosition.TOP_RIGHT && centerRight) {
        anchors.push({
          id: ANCHOR_POINTS.RIGHT,
          pos: calculateMidPoint(centerRight, corners['bottom-right']),
        });

        if (forLabels) {
          anchors.push({
            id: ANCHOR_POINTS.CENTER_CENTER_TOP,
            pos: calculateMidPoint(corners['top-right'], center),
          });
        }
      }
    }
  }

  return filterAnchors(
    anchors as {
      id: ANCHOR_POINTS;
      pos: Konva.Vector2d;
    }[],
    disabledEdge,
  );
};

export const scaleAnchorPoints = (
  points: { id: ANCHOR_POINTS; pos: Konva.Vector2d }[],
) => {
  return map(points, (item) => ({
    ...item,
    pos: { x: item.pos.x * DEFAULT_RATIO, y: item.pos.y * DEFAULT_RATIO },
  }));
};

const isLeftEdgeVertical = (
  corners: Record<
    'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
    [number, number]
  >,
) => {
  return corners['bottom-left'][0] === corners['top-left'][0];
};

const isRightEdgeVertical = (
  corners: Record<
    'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
    [number, number]
  >,
) => {
  return corners['bottom-right'][0] === corners['top-right'][0];
};

const handleCornerMoveManually = (
  move: Konva.Vector2d,
  shape: Shape,
  corners: Record<
    'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
    [number, number]
  >,
  id: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
  currentPosition: Konva.Vector2d,
) => {
  const diff = {
    x: move.x - shape.corners[id][0],
    y: move.y - shape.corners[id][1],
  };
  corners[id] = [corners[id][0] + diff.x, corners[id][1] + diff.y];

  return { corners, position: currentPosition };
};

const handleCornerMove = (
  move: Konva.Vector2d,
  shape: Shape,
  corners: Record<
    'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
    [number, number]
  >,
  id: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
  currentPosition: Konva.Vector2d,
) => {
  const diff = {
    x: move.x - shape.corners[id][0],
    y: move.y - shape.corners[id][1],
  };

  const lineTLTR = findLine(corners['top-left'], corners['top-right']);
  const lineBLBR = findLine(corners['bottom-left'], corners['bottom-right']);

  let newCornerBL, newCornerBR, newCornerTR, newCornerTL;

  if (id === 'bottom-right') {
    newCornerBR = [
      corners['bottom-right'][0] + diff.x,
      corners['bottom-right'][1] + diff.y,
    ] as [number, number];
    newCornerTL = corners['top-left'];

    const lineBLBRParallel = findParallelLine(newCornerBR, lineBLBR);
    if (isLeftEdgeVertical(corners) && isRightEdgeVertical(corners)) {
      const newCornerTRX = corners['top-right'][0] + diff.x;

      newCornerTR = [newCornerTRX, findYPointOnLine(lineTLTR, newCornerTRX)];
      newCornerBL = [
        corners['bottom-left'][0],
        findYPointOnLine(lineBLBRParallel, corners['bottom-left'][0]),
      ];
    } else if (isLeftEdgeVertical(corners)) {
      const lineTRBR = findLine(corners['top-right'], corners['bottom-right']);
      const lineTRBRParallel = findParallelLine(newCornerBR, lineTRBR);

      newCornerTR = findCrossPoint(lineTRBRParallel, lineTLTR);
      newCornerBL = [
        corners['bottom-left'][0],
        findYPointOnLine(lineBLBRParallel, corners['bottom-left'][0]),
      ];
    } else if (isRightEdgeVertical(corners)) {
      const lineTLBL = findLine(corners['top-left'], corners['bottom-left']);
      const lineTLTR = findLine(corners['top-left'], corners['top-right']);
      const newCornerTRX = corners['top-right'][0] + diff.x;

      newCornerBL = findCrossPoint(lineBLBRParallel, lineTLBL);
      newCornerTR = [newCornerTRX, findYPointOnLine(lineTLTR, newCornerTRX)];
    } else {
      const lineTLBL = findLine(corners['top-left'], corners['bottom-left']);
      const lineTRBR = findLine(corners['top-right'], corners['bottom-right']);
      const lineTRBRParallel = findParallelLine(newCornerBR, lineTRBR);

      newCornerBL = findCrossPoint(lineBLBRParallel, lineTLBL);
      newCornerTR = findCrossPoint(lineTRBRParallel, lineTLTR);
    }
  }

  if (id === 'bottom-left') {
    newCornerBL = [
      corners['bottom-left'][0] + diff.x,
      corners['bottom-left'][1] + diff.y,
    ] as [number, number];

    newCornerTR = corners['top-right'];

    if (isLeftEdgeVertical(corners) && isRightEdgeVertical(corners)) {
      const lineBLBRParallel = findParallelLine(newCornerBL, lineBLBR);
      const newCornerBRY = findYPointOnLine(
        lineBLBRParallel,
        corners['bottom-right'][0],
      );

      const newCornerTLY = findYPointOnLine(lineTLTR, corners['top-left'][0]);
      newCornerTL = [corners['top-left'][0] + diff.x, newCornerTLY];
      newCornerBR = [corners['bottom-right'][0], newCornerBRY];
    } else if (isLeftEdgeVertical(corners)) {
      const newCornerTLY = findYPointOnLine(
        lineTLTR,
        corners['top-left'][0] + diff.x,
      );

      const lineBLBRParallel = findParallelLine(newCornerBL, lineBLBR);
      const lineTRBR = findLine(corners['top-right'], corners['bottom-right']);

      newCornerBR = findCrossPoint(lineBLBRParallel, lineTRBR);
      newCornerTL = [corners['top-left'][0] + diff.x, newCornerTLY];
    } else if (isRightEdgeVertical(corners)) {
      const lineTLBL = findLine(corners['top-left'], corners['bottom-left']);
      const lineTLBLParallel = findParallelLine(newCornerBL, lineTLBL);

      newCornerTL = findCrossPoint(lineTLBLParallel, lineTLTR);

      const lineBLBRParallel = findParallelLine(newCornerBL, lineBLBR);

      const newCornerBRY = findYPointOnLine(
        lineBLBRParallel,
        corners['bottom-right'][0],
      );

      newCornerBR = [corners['bottom-right'][0], newCornerBRY];
    } else {
      const lineTLBL = findLine(corners['top-left'], corners['bottom-left']);
      const lineTRBR = findLine(corners['top-right'], corners['bottom-right']);
      const lineTLBLParallel = findParallelLine(newCornerBL, lineTLBL);
      const lineBLBRParallel = findParallelLine(newCornerBL, lineBLBR);

      newCornerTL = findCrossPoint(lineTLBLParallel, lineTLTR);
      newCornerBR = findCrossPoint(lineTRBR, lineBLBRParallel);
    }
  }

  if (id === 'top-right') {
    newCornerTR = [
      corners['top-right'][0] + diff.x,
      corners['top-right'][1] + diff.y,
    ] as [number, number];

    newCornerBL = corners['bottom-left'];

    const lineTLTRParallel = findParallelLine(newCornerTR, lineTLTR);

    if (isLeftEdgeVertical(corners) && isRightEdgeVertical(corners)) {
      const newCornerTLY = findYPointOnLine(
        lineTLTRParallel,
        corners['top-left'][0],
      );
      const newCornerBRY = findYPointOnLine(lineBLBR, newCornerTR[0]);
      newCornerTL = [corners['top-left'][0], newCornerTLY];
      newCornerBR = [corners['bottom-right'][0] + diff.x, newCornerBRY];
    } else if (isLeftEdgeVertical(corners)) {
      const lineTRBR = findLine(corners['top-right'], corners['bottom-right']);
      const lineTRBRParallel = findParallelLine(newCornerTR, lineTRBR);

      newCornerBR = findCrossPoint(lineBLBR, lineTRBRParallel);
      const newCornerTLY = findYPointOnLine(
        lineTLTRParallel,
        corners['top-left'][0],
      );
      newCornerTL = [corners['top-left'][0], newCornerTLY];
    } else if (isRightEdgeVertical(corners)) {
      const lineTLBL = findLine(corners['top-left'], corners['bottom-left']);

      const newCornerBRY = findYPointOnLine(lineBLBR, newCornerTR[0]);
      newCornerTL = findCrossPoint(lineTLBL, lineTLTRParallel);
      newCornerBR = [corners['bottom-right'][0] + diff.x, newCornerBRY];
    } else {
      const lineTLBL = findLine(corners['top-left'], corners['bottom-left']);
      const lineTRBR = findLine(corners['top-right'], corners['bottom-right']);
      const lineTRBRParallel = findParallelLine(newCornerTR, lineTRBR);

      newCornerBR = findCrossPoint(lineBLBR, lineTRBRParallel);
      newCornerTL = findCrossPoint(lineTLBL, lineTLTRParallel);
    }

    corners['top-right'] = [newCornerTR[0], newCornerTR[1]];
    corners['top-left'] = [newCornerTL[0], newCornerTL[1]];
    corners['bottom-right'] = [newCornerBR[0], newCornerBR[1]];
    corners['bottom-left'] = [
      corners['bottom-left'][0],
      corners['bottom-left'][1],
    ];
  }
  if (id === 'top-left') {
    newCornerTL = [
      corners['top-left'][0] + diff.x,
      corners['top-left'][1] + diff.y,
    ] as [number, number];

    newCornerBR = corners['bottom-right'];

    const lineTLTRParallel = findParallelLine(newCornerTL, lineTLTR);

    if (isLeftEdgeVertical(corners) && isRightEdgeVertical(corners)) {
      const newCornerTRY = findYPointOnLine(
        lineTLTRParallel,
        corners['top-right'][0],
      );
      const newCornerBLY = findYPointOnLine(lineBLBR, newCornerTL[0]);

      newCornerTR = [corners['top-right'][0], newCornerTRY];
      newCornerBL = [newCornerTL[0], newCornerBLY];
    } else if (isLeftEdgeVertical(corners)) {
      const lineTRBR = findLine(corners['top-right'], corners['bottom-right']);
      const newCornerBLY = findYPointOnLine(lineBLBR, newCornerTL[0]);

      newCornerTR = findCrossPoint(lineTLTRParallel, lineTRBR);
      newCornerBL = [newCornerTL[0], newCornerBLY];
    } else if (isRightEdgeVertical(corners)) {
      const lineTLBL = findLine(corners['top-left'], corners['bottom-left']);
      const lineTLBLParallel = findParallelLine(newCornerTL, lineTLBL);
      const newCornerTRY = findYPointOnLine(
        lineTLTRParallel,
        corners['top-right'][0],
      );

      newCornerTR = [corners['top-right'][0], newCornerTRY];
      newCornerBL = findCrossPoint(lineTLBLParallel, lineBLBR);
    } else {
      const lineTLBL = findLine(corners['top-left'], corners['bottom-left']);
      const lineTRBR = findLine(corners['top-right'], corners['bottom-right']);
      const lineTLBLParallel = findParallelLine(newCornerTL, lineTLBL);

      newCornerTR = findCrossPoint(lineTLTRParallel, lineTRBR);
      newCornerBL = findCrossPoint(lineTLBLParallel, lineBLBR);
    }
  }

  corners['bottom-right'] = newCornerBR as [number, number];
  corners['bottom-left'] = newCornerBL as [number, number];
  corners['top-right'] = newCornerTR as [number, number];
  corners['top-left'] = newCornerTL as [number, number];

  return { corners, position: currentPosition };
};

const handleEdgeMove = (
  move: Konva.Vector2d,
  shape: Shape,
  corners: Record<
    'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
    [number, number]
  >,
  id: ANCHOR_POINTS,
  currentPosition: Konva.Vector2d,
) => {
  const anchorPoints = getAnchorPointsCopy({ corners: shape.corners });
  const point = anchorPoints.find((item) => item.id === id);

  switch (id) {
    case ANCHOR_POINTS.TOP: {
      if (!point) {
        return { corners, position: currentPosition };
      }
      const diff = point.pos.y - move.y;
      const lineTLTR = findLine(corners['top-left'], corners['top-right']);
      let newCornerTL,
        newCornerTR: [number, number] = [0, 0];

      if (isLeftEdgeVertical(corners)) {
        newCornerTL = [corners['top-left'][0], corners['top-left'][1] - diff];
      } else {
        const lineTLBL = findLine(corners['top-left'], corners['bottom-left']);

        newCornerTL = findCrossPoint(lineTLBL, {
          a: lineTLTR.a,
          b: lineTLTR.b - diff,
        });
      }
      if (isRightEdgeVertical(corners)) {
        newCornerTR = [corners['top-right'][0], corners['top-right'][1] - diff];
      } else {
        const lineTRBR = findLine(
          corners['top-right'],
          corners['bottom-right'],
        );

        newCornerTR = findCrossPoint(lineTRBR, {
          a: lineTLTR.a,
          b: lineTLTR.b - diff,
        });
      }

      corners['top-left'] = newCornerTL as [number, number];
      corners['top-right'] = newCornerTR as [number, number];

      break;
    }

    case ANCHOR_POINTS.BOTTOM: {
      if (!point) {
        return { corners, position: currentPosition };
      }
      const diff = point.pos.y - move.y;
      const lineBLBR = findLine(
        corners['bottom-left'],
        corners['bottom-right'],
      );
      let newCornerBL,
        newCornerBR: [number, number] = [0, 0];

      if (isLeftEdgeVertical(corners)) {
        newCornerBL = [
          corners['bottom-left'][0],
          corners['bottom-left'][1] - diff,
        ];
      } else {
        const lineTLBL = findLine(corners['top-left'], corners['bottom-left']);

        newCornerBL = findCrossPoint(lineTLBL, {
          a: lineBLBR.a,
          b: lineBLBR.b - diff,
        });
      }
      if (isRightEdgeVertical(corners)) {
        newCornerBR = [
          corners['bottom-right'][0],
          corners['bottom-right'][1] - diff,
        ];
      } else {
        const lineTRBR = findLine(
          corners['top-right'],
          corners['bottom-right'],
        );

        newCornerBR = findCrossPoint(lineTRBR, {
          a: lineBLBR.a,
          b: lineBLBR.b - diff,
        });
      }

      corners['bottom-left'] = newCornerBL as [number, number];
      corners['bottom-right'] = newCornerBR as [number, number];

      break;
    }
    case ANCHOR_POINTS.LEFT: {
      if (!point) {
        return { corners, position: currentPosition };
      }

      const diff = point.pos.x - move.x;

      const lineTLTR = findLine(corners['top-left'], corners['top-right']);
      const lineBLBR = findLine(
        corners['bottom-left'],
        corners['bottom-right'],
      );

      let newCornerTL,
        newCornerBL: [number, number] = [0, 0];

      if (isLeftEdgeVertical(corners)) {
        const newX = corners['top-left'][0] - diff;

        const newCornerTLY = findYPointOnLine(lineTLTR, newX);
        const newCornerBLY = findYPointOnLine(lineBLBR, newX);

        newCornerTL = [newX, newCornerTLY] as [number, number];
        newCornerBL = [newX, newCornerBLY] as [number, number];
      } else {
        const lineTLBL = findLine(corners['top-left'], corners['bottom-left']);

        if (lineTLTR.a < lineBLBR.b) {
          const newCornerTLX = corners['top-left'][0] - diff;
          const newCornerTLY = findYPointOnLine(lineTLTR, newCornerTLX);

          newCornerTL = [newCornerTLX, newCornerTLY] as [number, number];
          const lineTLBLParallel = findParallelLine(newCornerTL, lineTLBL);

          newCornerBL = findCrossPoint(lineTLBLParallel, lineBLBR);
        } else {
          const newCornerBLX = corners['bottom-left'][0] - diff;
          const newCornerBLY = findYPointOnLine(lineBLBR, newCornerBLX);

          newCornerBL = [newCornerBLX, newCornerBLY] as [number, number];
          const lineTLBLParallel = findParallelLine(newCornerBL, lineTLBL);

          newCornerTL = findCrossPoint(lineTLBLParallel, lineTLTR);
        }
      }
      corners['top-left'] = newCornerTL;
      corners['bottom-left'] = newCornerBL;

      break;
    }
    case ANCHOR_POINTS.RIGHT: {
      if (!point) {
        return { corners, position: currentPosition };
      }

      const diff = point.pos.x - move.x;

      const lineTLTR = findLine(corners['top-left'], corners['top-right']);
      const lineBLBR = findLine(
        corners['bottom-left'],
        corners['bottom-right'],
      );

      let newCornerTR,
        newCornerBR: [number, number] = [0, 0];

      if (isRightEdgeVertical(corners)) {
        const newX = corners['top-right'][0] - diff;

        const newCornerTRY = findYPointOnLine(lineTLTR, newX);
        const newCornerBRY = findYPointOnLine(lineBLBR, newX);

        newCornerTR = [newX, newCornerTRY] as [number, number];
        newCornerBR = [newX, newCornerBRY] as [number, number];
      } else {
        const lineTRBR = findLine(
          corners['top-right'],
          corners['bottom-right'],
        );
        if (lineTRBR.a < lineTRBR.b) {
          const newCornerTRX = corners['top-right'][0] - diff;
          const newCornerTRY = findYPointOnLine(lineTLTR, newCornerTRX);

          newCornerTR = [newCornerTRX, newCornerTRY] as [number, number];
          const lineTRBRParallel = findParallelLine(newCornerTR, lineTRBR);

          newCornerBR = findCrossPoint(lineTRBRParallel, lineBLBR);
        } else {
          const newCornerBRX = corners['bottom-right'][0] - diff;
          const newCornerBRY = findYPointOnLine(lineBLBR, newCornerBRX);

          newCornerBR = [newCornerBRX, newCornerBRY] as [number, number];
          const lineTRBRParallel = findParallelLine(newCornerBR, lineTRBR);

          newCornerTR = findCrossPoint(lineTRBRParallel, lineTLTR);
        }
      }
      corners['top-right'] = newCornerTR;
      corners['bottom-right'] = newCornerBR;

      break;
    }
  }

  return { corners, position: currentPosition };
};

export const handleAnchorMoveCopy = (
  id: ANCHOR_POINTS,
  move: Konva.Vector2d,
  rectCorners: Record<RectCornersType, [number, number]>,
  currentPosition: Konva.Vector2d,
  adjustManually?: boolean,
) => {
  const corners = { ...rectCorners };
  const shape = new Shape({ corners }, currentPosition);

  if (
    id === ANCHOR_POINTS.TOP_LEFT ||
    id === ANCHOR_POINTS.BOTTOM_LEFT ||
    id === ANCHOR_POINTS.TOP_RIGHT ||
    id === ANCHOR_POINTS.BOTTOM_RIGHT
  ) {
    if (adjustManually) {
      return handleCornerMoveManually(
        move,
        shape,
        corners,
        id,
        currentPosition,
      );
    } else {
      return handleCornerMove(move, shape, corners, id, currentPosition);
    }
  } else {
    return handleEdgeMove(move, shape, corners, id, currentPosition);
  }
};

export const calculateDistanceFromEdgeForGlassHandler = (
  id: number,
  collider_id: number,
  corner: string,
  data: ExtendedDataCopy,
) => {
  const baseShape = find(
    [data.shape, ...data.shapes],
    (item) => item.id === id,
  );
  const colliderShape = find(
    [data.shape, ...data.shapes],
    (item) => item.id === collider_id,
  );

  if (!baseShape || !colliderShape) {
    return 0;
  }

  const glassCorners = convertToGlass(baseShape).corners;
  const outCorner = glassCorners[corner as RectCornersType];
  const outCornerOpposite =
    glassCorners[getOppositeCorner(corner as ANCHOR_POINTS) as RectCornersType];

  return calculateDistanceFromEdge(
    outCorner,
    outCornerOpposite,
    corner as RectCornersType,
    convertToGlass(colliderShape),
    true,
  );
};

export const calculateDistanceFromEdgeForParentHandler = (
  corner: string,
  data: DataCopy,
) => {
  const glassCorners = convertToGlass(data.shape).corners;
  const outCorner = glassCorners[corner as RectCornersType];
  const outCornerOpposite =
    glassCorners[getOppositeCorner(corner as ANCHOR_POINTS) as RectCornersType];
  const { parent } = getParentPolygon(data.parents[0]);

  return calculateDistanceFromEdge(
    outCorner,
    outCornerOpposite,
    corner as RectCornersType,
    parent,
  );
};

export const calculateDistanceFromEdge = (
  outCorner: [number, number],
  outCornerOpposite: [number, number],
  corner: string,
  collider: GlassWithEdges,
  modifiedOutsideCollider = false,
) => {
  const diagonalStart = {
    x: outCorner[0],
    y: outCorner[1],
  };
  const diagonalEnd = {
    x: outCornerOpposite[0],
    y: outCornerOpposite[1],
  };
  let distance = 0;

  const crossedEdge = getCrossedEdge(
    collider,
    diagonalStart,
    diagonalEnd,
    corner as RectCornersType,
    modifiedOutsideCollider,
  );

  if (crossedEdge.name === '') {
    return distance;
  }

  if (crossedEdge.vector.startX === crossedEdge.vector.endX) {
    if (diagonalStart.x === diagonalEnd.x) {
      distance = 0;
    } else {
      const diagonal = findLine(
        [diagonalStart.x, diagonalStart.y],
        [diagonalEnd.x, diagonalEnd.y],
      );
      const crossPointY = findYPointOnLine(diagonal, crossedEdge.vector.startX);
      const crossPoint = [crossedEdge.vector.startX, crossPointY];
      distance = getVectorLength(
        { x: outCorner[0], y: outCorner[1] },
        { x: crossPoint[0], y: crossPoint[1] },
      );
    }
  } else {
    const edgeLine = findLine(
      [crossedEdge.vector.startX, crossedEdge.vector.startY],
      [crossedEdge.vector.endX, crossedEdge.vector.endY],
    );
    if (diagonalStart.x === diagonalEnd.x) {
      const crossPointY = findYPointOnLine(edgeLine, diagonalStart.x);
      const crossPoint = [diagonalStart.x, crossPointY];
      distance = getVectorLength(
        { x: outCorner[0], y: outCorner[1] },
        { x: crossPoint[0], y: crossPoint[1] },
      );
    } else {
      const diagonal = findLine(
        [diagonalStart.x, diagonalStart.y],
        [diagonalEnd.x, diagonalEnd.y],
      );
      const crossPoint = findCrossPoint(edgeLine, diagonal);
      distance = getVectorLength(
        { x: outCorner[0], y: outCorner[1] },
        { x: crossPoint[0], y: crossPoint[1] },
      );
    }
  }

  return distance;
};

const getPairsForIndent = (
  indent: IndentPosition,
): Record<
  | ANCHOR_POINTS.TOP
  | ANCHOR_POINTS.BOTTOM
  | ANCHOR_POINTS.LEFT
  | ANCHOR_POINTS.RIGHT
  | ANCHOR_POINTS.CENTER_CENTER_RIGHT
  | ANCHOR_POINTS.CENTER_CENTER_LEFT
  | ANCHOR_POINTS.CENTER_CENTER_BOTTOM
  | ANCHOR_POINTS.CENTER_CENTER_TOP,
  IndentCornersType[]
> => {
  const basePairs: Record<
    ANCHOR_POINTS.TOP | ANCHOR_POINTS.BOTTOM,
    IndentCornersType[]
  > = {
    [ANCHOR_POINTS.TOP]: [ANCHOR_POINTS.TOP_LEFT, ANCHOR_POINTS.TOP_RIGHT],
    [ANCHOR_POINTS.BOTTOM]: [
      ANCHOR_POINTS.BOTTOM_LEFT,
      ANCHOR_POINTS.BOTTOM_RIGHT,
    ],
  };

  const centerCenterRight: Record<
    ANCHOR_POINTS.CENTER_CENTER_RIGHT,
    IndentCornersType[]
  > = {
    [ANCHOR_POINTS.CENTER_CENTER_RIGHT]: [
      ANCHOR_POINTS.CENTER,
      ANCHOR_POINTS.CENTER_RIGHT,
    ],
  };

  const centerCenterLeft: Record<
    ANCHOR_POINTS.CENTER_CENTER_LEFT,
    IndentCornersType[]
  > = {
    [ANCHOR_POINTS.CENTER_CENTER_LEFT]: [
      ANCHOR_POINTS.CENTER,
      ANCHOR_POINTS.CENTER_LEFT,
    ],
  };

  switch (indent) {
    case IndentPosition.TOP_RIGHT:
      return {
        ...basePairs,
        ...centerCenterRight,
        [ANCHOR_POINTS.LEFT]: [
          ANCHOR_POINTS.TOP_LEFT,
          ANCHOR_POINTS.BOTTOM_LEFT,
        ],
        [ANCHOR_POINTS.RIGHT]: [
          ANCHOR_POINTS.CENTER_RIGHT,
          ANCHOR_POINTS.BOTTOM_RIGHT,
        ],
        [ANCHOR_POINTS.CENTER_CENTER_TOP]: [
          ANCHOR_POINTS.CENTER,
          ANCHOR_POINTS.TOP_RIGHT,
        ],
        [ANCHOR_POINTS.CENTER_CENTER_BOTTOM]: [],
        [ANCHOR_POINTS.CENTER_CENTER_LEFT]: [],
      };
    case IndentPosition.TOP_LEFT:
      return {
        ...basePairs,
        ...centerCenterLeft,
        [ANCHOR_POINTS.LEFT]: [
          ANCHOR_POINTS.CENTER_LEFT,
          ANCHOR_POINTS.BOTTOM_LEFT,
        ],
        [ANCHOR_POINTS.RIGHT]: [
          ANCHOR_POINTS.TOP_RIGHT,
          ANCHOR_POINTS.BOTTOM_RIGHT,
        ],
        [ANCHOR_POINTS.CENTER_CENTER_TOP]: [
          ANCHOR_POINTS.CENTER,
          ANCHOR_POINTS.TOP_LEFT,
        ],
        [ANCHOR_POINTS.CENTER_CENTER_BOTTOM]: [],
        [ANCHOR_POINTS.CENTER_CENTER_RIGHT]: [],
      };
    case IndentPosition.BOTTOM_RIGHT:
      return {
        ...basePairs,
        ...centerCenterRight,
        [ANCHOR_POINTS.LEFT]: [
          ANCHOR_POINTS.TOP_LEFT,
          ANCHOR_POINTS.BOTTOM_LEFT,
        ],
        [ANCHOR_POINTS.RIGHT]: [
          ANCHOR_POINTS.TOP_RIGHT,
          ANCHOR_POINTS.CENTER_RIGHT,
        ],
        [ANCHOR_POINTS.CENTER_CENTER_BOTTOM]: [
          ANCHOR_POINTS.CENTER,
          ANCHOR_POINTS.BOTTOM_RIGHT,
        ],
        [ANCHOR_POINTS.CENTER_CENTER_TOP]: [],
        [ANCHOR_POINTS.CENTER_CENTER_LEFT]: [],
      };
    case IndentPosition.BOTTOM_LEFT:
      return {
        ...basePairs,
        ...centerCenterLeft,
        [ANCHOR_POINTS.LEFT]: [
          ANCHOR_POINTS.TOP_LEFT,
          ANCHOR_POINTS.CENTER_LEFT,
        ],
        [ANCHOR_POINTS.RIGHT]: [
          ANCHOR_POINTS.TOP_RIGHT,
          ANCHOR_POINTS.BOTTOM_RIGHT,
        ],
        [ANCHOR_POINTS.CENTER_CENTER_BOTTOM]: [
          ANCHOR_POINTS.CENTER,
          ANCHOR_POINTS.BOTTOM_LEFT,
        ],
        [ANCHOR_POINTS.CENTER_CENTER_TOP]: [],
        [ANCHOR_POINTS.CENTER_CENTER_RIGHT]: [],
      };
  }
};

const getEdgesForIndent = (
  indent: IndentPosition,
): Record<IndentCornersType, IndentCornersType[]> => {
  switch (indent) {
    case IndentPosition.TOP_RIGHT:
      return {
        [ANCHOR_POINTS.TOP_LEFT]: [
          ANCHOR_POINTS.BOTTOM_LEFT,
          ANCHOR_POINTS.TOP_RIGHT,
        ],
        [ANCHOR_POINTS.TOP_RIGHT]: [
          ANCHOR_POINTS.CENTER,
          ANCHOR_POINTS.TOP_LEFT,
        ],
        [ANCHOR_POINTS.BOTTOM_LEFT]: [
          ANCHOR_POINTS.TOP_LEFT,
          ANCHOR_POINTS.BOTTOM_RIGHT,
        ],
        [ANCHOR_POINTS.BOTTOM_RIGHT]: [
          ANCHOR_POINTS.CENTER_RIGHT,
          ANCHOR_POINTS.BOTTOM_LEFT,
        ],
        [ANCHOR_POINTS.CENTER]: [
          ANCHOR_POINTS.TOP_RIGHT,
          ANCHOR_POINTS.CENTER_RIGHT,
        ],
        [ANCHOR_POINTS.CENTER_RIGHT]: [
          ANCHOR_POINTS.BOTTOM_RIGHT,
          ANCHOR_POINTS.CENTER,
        ],
        [ANCHOR_POINTS.CENTER_LEFT]: [],
      };
    case IndentPosition.TOP_LEFT:
      return {
        [ANCHOR_POINTS.TOP_LEFT]: [
          ANCHOR_POINTS.CENTER,
          ANCHOR_POINTS.TOP_RIGHT,
        ],
        [ANCHOR_POINTS.TOP_RIGHT]: [
          ANCHOR_POINTS.BOTTOM_RIGHT,
          ANCHOR_POINTS.TOP_LEFT,
        ],
        [ANCHOR_POINTS.BOTTOM_LEFT]: [
          ANCHOR_POINTS.CENTER_LEFT,
          ANCHOR_POINTS.BOTTOM_RIGHT,
        ],
        [ANCHOR_POINTS.BOTTOM_RIGHT]: [
          ANCHOR_POINTS.TOP_RIGHT,
          ANCHOR_POINTS.BOTTOM_LEFT,
        ],
        [ANCHOR_POINTS.CENTER]: [
          ANCHOR_POINTS.TOP_LEFT,
          ANCHOR_POINTS.CENTER_LEFT,
        ],
        [ANCHOR_POINTS.CENTER_LEFT]: [
          ANCHOR_POINTS.BOTTOM_LEFT,
          ANCHOR_POINTS.CENTER,
        ],
        [ANCHOR_POINTS.CENTER_RIGHT]: [],
      };
    case IndentPosition.BOTTOM_RIGHT:
      return {
        [ANCHOR_POINTS.TOP_LEFT]: [
          ANCHOR_POINTS.BOTTOM_LEFT,
          ANCHOR_POINTS.TOP_RIGHT,
        ],
        [ANCHOR_POINTS.TOP_RIGHT]: [
          ANCHOR_POINTS.CENTER_RIGHT,
          ANCHOR_POINTS.TOP_LEFT,
        ],
        [ANCHOR_POINTS.BOTTOM_LEFT]: [
          ANCHOR_POINTS.TOP_LEFT,
          ANCHOR_POINTS.BOTTOM_RIGHT,
        ],
        [ANCHOR_POINTS.BOTTOM_RIGHT]: [
          ANCHOR_POINTS.CENTER,
          ANCHOR_POINTS.BOTTOM_LEFT,
        ],
        [ANCHOR_POINTS.CENTER]: [
          ANCHOR_POINTS.BOTTOM_RIGHT,
          ANCHOR_POINTS.CENTER_RIGHT,
        ],
        [ANCHOR_POINTS.CENTER_RIGHT]: [
          ANCHOR_POINTS.TOP_RIGHT,
          ANCHOR_POINTS.CENTER,
        ],
        [ANCHOR_POINTS.CENTER_LEFT]: [],
      };
    case IndentPosition.BOTTOM_LEFT:
      return {
        [ANCHOR_POINTS.TOP_LEFT]: [
          ANCHOR_POINTS.CENTER_LEFT,
          ANCHOR_POINTS.TOP_RIGHT,
        ],
        [ANCHOR_POINTS.TOP_RIGHT]: [
          ANCHOR_POINTS.BOTTOM_RIGHT,
          ANCHOR_POINTS.TOP_LEFT,
        ],
        [ANCHOR_POINTS.BOTTOM_LEFT]: [
          ANCHOR_POINTS.CENTER,
          ANCHOR_POINTS.BOTTOM_RIGHT,
        ],
        [ANCHOR_POINTS.BOTTOM_RIGHT]: [
          ANCHOR_POINTS.TOP_RIGHT,
          ANCHOR_POINTS.BOTTOM_LEFT,
        ],
        [ANCHOR_POINTS.CENTER]: [
          ANCHOR_POINTS.BOTTOM_LEFT,
          ANCHOR_POINTS.CENTER_LEFT,
        ],
        [ANCHOR_POINTS.CENTER_LEFT]: [
          ANCHOR_POINTS.TOP_LEFT,
          ANCHOR_POINTS.CENTER,
        ],
        [ANCHOR_POINTS.CENTER_RIGHT]: [],
      };
  }
};

const calculateLeftEdge = (
  diff: number,
  pointTL: [number, number],
  pointBL: [number, number],
  pointTR: [number, number],
  pointBR: [number, number],
) => {
  const lineTLTR = findLine(pointTL, pointTR);
  const lineBLBR = findLine(pointBL, pointBR);

  let newCornerTL,
    newCornerBL: [number, number] = [0, 0];
  if (pointTL[0] === pointBL[0]) {
    const newX = pointTL[0] - diff;

    const newCornerTLY = findYPointOnLine(lineTLTR, newX);
    const newCornerBLY = findYPointOnLine(lineBLBR, newX);

    newCornerTL = [newX, newCornerTLY] as [number, number];
    newCornerBL = [newX, newCornerBLY] as [number, number];
  } else {
    const lineTLBL = findLine(pointTL, pointBL);

    if (lineTLTR.a < lineBLBR.b) {
      const newCornerTLX = pointTL[0] - diff;
      const newCornerTLY = findYPointOnLine(lineTLTR, newCornerTLX);

      newCornerTL = [newCornerTLX, newCornerTLY] as [number, number];
      const lineTLBLParallel = findParallelLine(newCornerTL, lineTLBL);

      newCornerBL = findCrossPoint(lineTLBLParallel, lineBLBR);
    } else {
      const newCornerBLX = pointBL[0] - diff;
      const newCornerBLY = findYPointOnLine(lineBLBR, newCornerBLX);

      newCornerBL = [newCornerBLX, newCornerBLY] as [number, number];
      const lineTLBLParallel = findParallelLine(newCornerBL, lineTLBL);

      newCornerTL = findCrossPoint(lineTLBLParallel, lineTLTR);
    }
  }
  return { top: newCornerTL, bottom: newCornerBL };
};

const calculateRightEdge = (
  diff: number,
  pointTL: [number, number],
  pointBL: [number, number],
  pointTR: [number, number],
  pointBR: [number, number],
) => {
  const lineTLTR = findLine(pointTL, pointTR);
  const lineBLBR = findLine(pointBL, pointBR);

  let newCornerTR,
    newCornerBR: [number, number] = [0, 0];

  if (pointTR[0] === pointBR[0]) {
    const newX = pointTR[0] - diff;

    const newCornerTRY = findYPointOnLine(lineTLTR, newX);
    const newCornerBRY = findYPointOnLine(lineBLBR, newX);

    newCornerTR = [newX, newCornerTRY] as [number, number];
    newCornerBR = [newX, newCornerBRY] as [number, number];
  } else {
    const lineTRBR = findLine(pointTR, pointBR);
    if (lineTRBR.a < lineTRBR.b) {
      const newCornerTRX = pointTR[0] - diff;
      const newCornerTRY = findYPointOnLine(lineTLTR, newCornerTRX);

      newCornerTR = [newCornerTRX, newCornerTRY] as [number, number];
      const lineTRBRParallel = findParallelLine(newCornerTR, lineTRBR);

      newCornerBR = findCrossPoint(lineTRBRParallel, lineBLBR);
    } else {
      const newCornerBRX = pointBR[0] - diff;
      const newCornerBRY = findYPointOnLine(lineBLBR, newCornerBRX);

      newCornerBR = [newCornerBRX, newCornerBRY] as [number, number];
      const lineTRBRParallel = findParallelLine(newCornerBR, lineTRBR);

      newCornerTR = findCrossPoint(lineTRBRParallel, lineTLTR);
    }
  }

  return { top: newCornerTR, bottom: newCornerBR };
};

const calculateTopEdge = (
  diff: number,
  pointTL: [number, number],
  pointBL: [number, number],
  pointTR: [number, number],
  pointBR: [number, number],
) => {
  const lineTLTR = findLine(pointTL, pointTR);
  let newCornerTL,
    newCornerTR: [number, number] = [0, 0];

  if (pointTL[0] === pointBL[0]) {
    newCornerTL = [pointTL[0], pointTL[1] - diff];
  } else {
    const lineTLBL = findLine(pointTL, pointBL);

    newCornerTL = findCrossPoint(lineTLBL, {
      a: lineTLTR.a,
      b: lineTLTR.b - diff,
    });
  }
  if (pointTR[0] === pointBR[0]) {
    newCornerTR = [pointTR[0], pointTR[1] - diff];
  } else {
    const lineTRBR = findLine(pointTR, pointBR);

    newCornerTR = findCrossPoint(lineTRBR, {
      a: lineTLTR.a,
      b: lineTLTR.b - diff,
    });
  }

  return { left: newCornerTL, right: newCornerTR };
};

const calculateBottomEdge = (
  diff: number,
  pointTL: [number, number],
  pointBL: [number, number],
  pointTR: [number, number],
  pointBR: [number, number],
) => {
  const lineBLBR = findLine(pointBL, pointBR);
  let newCornerBL,
    newCornerBR: [number, number] = [0, 0];

  if (pointTL[0] === pointBL[0]) {
    newCornerBL = [pointBL[0], pointBL[1] - diff];
  } else {
    const lineTLBL = findLine(pointTL, pointBL);

    newCornerBL = findCrossPoint(lineTLBL, {
      a: lineBLBR.a,
      b: lineBLBR.b - diff,
    });
  }
  if (pointTR[0] === pointBR[0]) {
    newCornerBR = [pointBR[0], pointBR[1] - diff];
  } else {
    const lineTRBR = findLine(pointTR, pointBR);

    newCornerBR = findCrossPoint(lineTRBR, {
      a: lineBLBR.a,
      b: lineBLBR.b - diff,
    });
  }
  return { left: newCornerBL, right: newCornerBR };
};

const calculateCornersNewPosition = (
  diff: { x: number; y: number },
  movedCorner: [number, number],
  firstOnLeft: [number, number],
  secondOnLeft: [number, number],
  firstOnRight: [number, number],
  secondOnRight: [number, number],
) => {
  const newMovedCorner = [movedCorner[0] + diff.x, movedCorner[1] + diff.y] as [
    number,
    number,
  ];

  let pointLeft: [number, number] = [0, 0];
  let pointRight: [number, number] = [0, 0];

  // LEFT SIDE
  if (movedCorner[0] !== firstOnLeft[0]) {
    const lineFirstLeft = findLine(movedCorner, firstOnLeft);
    const lineFirstLeftPararell = findParallelLine(
      newMovedCorner,
      lineFirstLeft,
    );

    if (firstOnLeft[0] !== secondOnLeft[0]) {
      const lineSecondLeft = findLine(secondOnLeft, firstOnLeft);
      pointLeft = findCrossPoint(lineFirstLeftPararell, lineSecondLeft);
    } else {
      const pointLeftY = findYPointOnLine(
        lineFirstLeftPararell,
        firstOnLeft[0],
      );
      pointLeft = [firstOnLeft[0], pointLeftY];
    }
  } else {
    pointLeft = [firstOnLeft[0] + diff.x, firstOnLeft[1]];
  }

  //RIGHT SIDE
  if (movedCorner[0] !== firstOnRight[0]) {
    const lineFirstRight = findLine(movedCorner, firstOnRight);
    const lineFirstRightPararell = findParallelLine(
      newMovedCorner,
      lineFirstRight,
    );

    if (firstOnRight[0] !== secondOnRight[0]) {
      const lineSecondRight = findLine(secondOnRight, firstOnRight);
      pointRight = findCrossPoint(lineFirstRightPararell, lineSecondRight);
    } else {
      const pointRightY = findYPointOnLine(
        lineFirstRightPararell,
        firstOnRight[0],
      );
      pointRight = [firstOnRight[0], pointRightY];
    }
  } else {
    pointRight = [firstOnRight[0] + diff.x, firstOnRight[1]];
  }

  return {
    moved: newMovedCorner,
    left: pointLeft,
    right: pointRight,
  };
};

const handleIndentEdgeMove = (
  indent: IndentPosition,
  corners: IndentCorners,
  id: ANCHOR_POINTS,
  move: Konva.Vector2d,
) => {
  const changed: Partial<IndentCorners> = {};
  const anchorPoints = getAnchorPointsCopy({ corners, indent });
  const point = anchorPoints.find((item) => item.id === id) || {
    pos: { x: 0, y: 0 },
  };

  if (id === ANCHOR_POINTS.TOP) {
    const diff = point.pos.y - move.y;
    let changedCorners: {
      left: number[];
      right: [number, number];
    } = {
      left: [0, 0],
      right: [0, 0],
    };
    if (indent === IndentPosition.TOP_RIGHT) {
      changedCorners = calculateTopEdge(
        diff,
        corners['top-left'],
        corners['bottom-left'],
        corners['top-right'],
        corners['center'],
      );
    }
    if (indent === IndentPosition.TOP_LEFT) {
      changedCorners = calculateTopEdge(
        diff,
        corners['top-left'],
        corners['center'],
        corners['top-right'],
        corners['bottom-right'],
      );
    }
    if (indent === IndentPosition.BOTTOM_RIGHT) {
      changedCorners = calculateTopEdge(
        diff,
        corners['top-left'],
        corners['bottom-left'],
        corners['top-right'],
        corners['center-right']!,
      );
    }
    if (indent === IndentPosition.BOTTOM_LEFT) {
      changedCorners = calculateTopEdge(
        diff,
        corners['top-left'],
        corners['center-left']!,
        corners['top-right'],
        corners['bottom-right']!,
      );
    }
    changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.left as [number, number];
    changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.right as [number, number];
  }
  if (id === ANCHOR_POINTS.BOTTOM) {
    const diff = point.pos.y - move.y;
    let changedCorners: {
      left: number[];
      right: [number, number];
    } = {
      left: [0, 0],
      right: [0, 0],
    };
    if (indent === IndentPosition.TOP_RIGHT) {
      changedCorners = calculateBottomEdge(
        diff,
        corners['top-left'],
        corners['bottom-left'],
        corners['center-right']!,
        corners['bottom-right'],
      );
    }
    if (indent === IndentPosition.TOP_LEFT) {
      changedCorners = calculateBottomEdge(
        diff,
        corners['center-left']!,
        corners['bottom-left'],
        corners['top-right'],
        corners['bottom-right'],
      );
    }
    if (indent === IndentPosition.BOTTOM_RIGHT) {
      changedCorners = calculateBottomEdge(
        diff,
        corners['top-left'],
        corners['bottom-left'],
        corners['center'],
        corners['bottom-right']!,
      );
    }
    if (indent === IndentPosition.BOTTOM_LEFT) {
      changedCorners = calculateBottomEdge(
        diff,
        corners['center'],
        corners['bottom-left'],
        corners['top-right'],
        corners['bottom-right']!,
      );
    }
    changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.left as [
      number,
      number,
    ];
    changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.right as [
      number,
      number,
    ];
  }

  if (id === ANCHOR_POINTS.LEFT) {
    const diff = point.pos.x - move.x;
    if (
      indent === IndentPosition.TOP_RIGHT ||
      indent === IndentPosition.BOTTOM_RIGHT
    ) {
      const changedCorners = calculateLeftEdge(
        diff,
        corners['top-left'],
        corners['bottom-left'],
        corners['top-right'],
        corners['bottom-right'],
      );
      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.top as [number, number];
      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.bottom as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.BOTTOM_LEFT) {
      const changedCorners = calculateLeftEdge(
        diff,
        corners['top-left'],
        corners['center-left']!,
        corners['top-right'],
        corners['center'],
      );
      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.top as [number, number];
      changed[ANCHOR_POINTS.CENTER_LEFT] = changedCorners.bottom as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.TOP_LEFT) {
      const changedCorners = calculateLeftEdge(
        diff,
        corners['center-left']!,
        corners['bottom-left'],
        corners['center'],
        corners['bottom-right'],
      );
      changed[ANCHOR_POINTS.CENTER_LEFT] = changedCorners.top as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.bottom as [
        number,
        number,
      ];
    }
  }
  if (id === ANCHOR_POINTS.RIGHT) {
    const diff = point.pos.x - move.x;
    if (
      indent === IndentPosition.TOP_LEFT ||
      indent === IndentPosition.BOTTOM_LEFT
    ) {
      const changedCorners = calculateRightEdge(
        diff,
        corners['top-left'],
        corners['bottom-left'],
        corners['top-right'],
        corners['bottom-right'],
      );
      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.top as [number, number];
      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.bottom as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.BOTTOM_RIGHT) {
      const changedCorners = calculateRightEdge(
        diff,
        corners['top-left'],
        corners['center'],
        corners['top-right'],
        corners['center-right']!,
      );
      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.top as [number, number];
      changed[ANCHOR_POINTS.CENTER_RIGHT] = changedCorners.bottom as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.TOP_RIGHT) {
      const changedCorners = calculateRightEdge(
        diff,
        corners['center'],
        corners['bottom-left'],
        corners['center-right']!,
        corners['bottom-right'],
      );
      changed[ANCHOR_POINTS.CENTER_RIGHT] = changedCorners.top as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.bottom as [
        number,
        number,
      ];
    }
  }
  return changed;
};

const handleIndentCornerMove = (
  indent: IndentPosition,
  corners: IndentCorners,
  id: ANCHOR_POINTS,
  move: Konva.Vector2d,
) => {
  const changed: Partial<IndentCorners> = {};
  const anchorPoints = getAnchorPointsCopy({ corners, indent });
  const point = anchorPoints.find((item) => item.id === id) || {
    pos: { x: 0, y: 0 },
  };

  if (id === ANCHOR_POINTS.TOP_LEFT) {
    if (indent === IndentPosition.TOP_LEFT) {
      const diff = point.pos.y - move.y;
      const changedCorners = calculateTopEdge(
        diff,
        corners['top-left'],
        corners['center'],
        corners['top-right'],
        corners['bottom-right'],
      );

      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.left as [number, number];
      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.right as [
        number,
        number,
      ];
    }

    if (indent === IndentPosition.BOTTOM_RIGHT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['top-left'],
        corners['top-right'],
        corners['center-right']!,
        corners['bottom-left']!,
        corners['bottom-right']!,
      );

      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.right as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.BOTTOM_LEFT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['top-left'],
        corners['top-right'],
        corners['bottom-right'],
        corners['center-left']!,
        corners['center'],
      );

      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.CENTER_LEFT] = changedCorners.right as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.TOP_RIGHT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['top-left'],
        corners['top-right'],
        corners['center'],
        corners['bottom-left']!,
        corners['bottom-right'],
      );

      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.right as [
        number,
        number,
      ];
    }
  }
  if (id === ANCHOR_POINTS.TOP_RIGHT) {
    if (indent === IndentPosition.TOP_RIGHT) {
      const diff = point.pos.y - move.y;
      const changedCorners = calculateTopEdge(
        diff,
        corners['top-left'],
        corners['bottom-left'],
        corners['top-right'],
        corners['center'],
      );

      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.left as [number, number];
      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.right as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.BOTTOM_LEFT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['top-right'],
        corners['bottom-right'],
        corners['bottom-left'],
        corners['top-left'],
        corners['center-left']!,
      );

      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.right as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.BOTTOM_RIGHT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['top-right'],
        corners['center-right']!,
        corners['center'],
        corners['top-left'],
        corners['bottom-left']!,
      );

      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.CENTER_RIGHT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.right as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.TOP_LEFT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['top-right'],
        corners['bottom-right']!,
        corners['bottom-left'],
        corners['top-left'],
        corners['center']!,
      );

      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.right as [
        number,
        number,
      ];
    }
  }
  if (id === ANCHOR_POINTS.BOTTOM_LEFT) {
    if (indent === IndentPosition.BOTTOM_LEFT) {
      const diff = point.pos.y - move.y;
      const changedCorners = calculateBottomEdge(
        diff,
        corners['center'],
        corners['bottom-left'],
        corners['top-right'],
        corners['bottom-right'],
      );

      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.right as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.TOP_RIGHT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['bottom-left'],
        corners['top-left'],
        corners['top-right'],
        corners['bottom-right'],
        corners['center-right']!,
      );

      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.left as [number, number];
      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.right as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.BOTTOM_RIGHT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['bottom-left'],
        corners['top-left'],
        corners['top-right'],
        corners['bottom-right'],
        corners['center'],
      );

      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.left as [number, number];
      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.right as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.TOP_LEFT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['bottom-left'],
        corners['center-left']!,
        corners['center'],
        corners['bottom-right'],
        corners['top-right'],
      );

      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.CENTER_LEFT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.right as [
        number,
        number,
      ];
    }
  }
  if (id === ANCHOR_POINTS.BOTTOM_RIGHT) {
    if (indent === IndentPosition.BOTTOM_RIGHT) {
      const diff = point.pos.y - move.y;
      const changedCorners = calculateBottomEdge(
        diff,
        corners['top-left'],
        corners['bottom-left'],
        corners['center'],
        corners['bottom-right'],
      );

      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.right as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.TOP_RIGHT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['bottom-right'],
        corners['bottom-left'],
        corners['top-left'],
        corners['center-right']!,
        corners['center']!,
      );

      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.CENTER_RIGHT] = changedCorners.right as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.TOP_LEFT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['bottom-right'],
        corners['bottom-left'],
        corners['center-left']!,
        corners['top-right']!,
        corners['top-left']!,
      );

      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.right as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.BOTTOM_LEFT) {
      const diff = {
        x: move.x - point.pos.x,
        y: move.y - point.pos.y,
      };
      const changedCorners = calculateCornersNewPosition(
        diff,
        corners['bottom-right'],
        corners['bottom-left'],
        corners['center'],
        corners['top-right']!,
        corners['top-left']!,
      );

      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.moved as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.left as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.right as [
        number,
        number,
      ];
    }
  }
  if (id === ANCHOR_POINTS.CENTER_RIGHT) {
    const diff = point.pos.x - move.x;
    if (indent === IndentPosition.TOP_RIGHT) {
      const changedCorners = calculateRightEdge(
        diff,
        corners['center'],
        corners['bottom-left'],
        corners['center-right']!,
        corners['bottom-right'],
      );
      changed[ANCHOR_POINTS.CENTER_RIGHT] = changedCorners.top as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_RIGHT] = changedCorners.bottom as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.BOTTOM_RIGHT) {
      const changedCorners = calculateRightEdge(
        diff,
        corners['top-left'],
        corners['center'],
        corners['top-right'],
        corners['center-right']!,
      );
      changed[ANCHOR_POINTS.TOP_RIGHT] = changedCorners.top as [number, number];
      changed[ANCHOR_POINTS.CENTER_RIGHT] = changedCorners.bottom as [
        number,
        number,
      ];
    }
  }
  if (id === ANCHOR_POINTS.CENTER_LEFT) {
    if (indent === IndentPosition.TOP_LEFT) {
      const diff = point.pos.x - move.x;
      const changedCorners = calculateLeftEdge(
        diff,
        corners['center-left']!,
        corners['bottom-left'],
        corners['center'],
        corners['bottom-right'],
      );
      changed[ANCHOR_POINTS.CENTER_LEFT] = changedCorners.top as [
        number,
        number,
      ];
      changed[ANCHOR_POINTS.BOTTOM_LEFT] = changedCorners.bottom as [
        number,
        number,
      ];
    }
    if (indent === IndentPosition.BOTTOM_LEFT) {
      const diff = point.pos.x - move.x;
      const changedCorners = calculateLeftEdge(
        diff,
        corners['top-left'],
        corners['center-left']!,
        corners['top-right'],
        corners['center'],
      );
      changed[ANCHOR_POINTS.TOP_LEFT] = changedCorners.top as [number, number];
      changed[ANCHOR_POINTS.CENTER_LEFT] = changedCorners.bottom as [
        number,
        number,
      ];
    }
  }
  return changed;
};

export const handleIndentMove = (
  id: ANCHOR_POINTS,
  move: Konva.Vector2d,
  indent: IndentPosition,
  corners: IndentCorners,
  currentPosition: Konva.Vector2d,
  adjustManually?: boolean,
) => {
  let changed: Partial<any> = {};

  // const changed: any = {};
  const anchorPoints = getAnchorPointsCopy({ corners, indent });
  const point = anchorPoints.find((item) => item.id === id);

  if (
    id === ANCHOR_POINTS.BOTTOM ||
    id === ANCHOR_POINTS.TOP ||
    id === ANCHOR_POINTS.LEFT ||
    id === ANCHOR_POINTS.RIGHT
  ) {
    changed = handleIndentEdgeMove(indent, corners, id, move);
  } else {
    if (adjustManually) {
      changed[id] = [move.x, move.y];
    } else {
      changed = handleIndentCornerMove(indent, corners, id, move);
    }
  }

  return { ...corners, ...changed };
};
