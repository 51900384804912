import * as React from 'react';
import { useMemo, useRef } from 'react';
import Konva from 'konva';
import { Group } from 'react-konva';
import { SizeLabel } from '../SizeLabel';
import { LABEL_HEIGHT, LABEL_WIDTH } from './DistanceLines';
import { Boundaries } from '../../types';
import { Vector2d } from 'konva/lib/types';
import { DistanceTypes } from '../../store/products';
import { DistanceLine } from '../DistanceLine';
import { DistanceArrow } from '../DistanceArrow';
import { useProductXDistanceLinesPositions } from './hooks/useProductXDistanceLinesPositions';
import { useProductXGapLinesPositions } from './hooks/useProductXGapLinesPositions';
import { Shape } from '../../../space';

interface XDistanceLinesParams {
  leftPosition: boolean;
  topPosition: boolean;
  distance: Boundaries;
  mountedEdgePoints: { left: Vector2d; right: Vector2d };
  distanceType: DistanceTypes;
  productData: Shape;
}

function XDistanceLines({
  leftPosition,
  topPosition,
  distance,
  productData,
  mountedEdgePoints,
  distanceType,
}: XDistanceLinesParams) {
  const leftLabel = useRef<Konva.Label>(null);
  const rightLabel = useRef<Konva.Label>(null);
  const gapLabel = useRef<Konva.Label>(null);

  const {
    leftLinePoints,
    leftArrowPoints,
    productLeftLinePoints,
    productCenterLinePoints,
    productRightLinePoints,
    rightLinePoints,
    rightArrowPoints,
  } = useProductXDistanceLinesPositions({
    mountedEdgePoints,
    productData,
    topPosition,
    distanceType,
  });

  const {
    gapTopDistanceLinePoints,
    gapBottomDistanceLinePoints,
    gapArrowDistanceLinePoints,
    gapLabelPosition,
  } = useProductXGapLinesPositions({
    topPosition,
    leftPosition,
    distance,
    gapLabel,
    distanceType,
    mountedEdgePoints,
  });

  const leftLabelPosition = useMemo(() => {
    const padding = topPosition ? -5 : 5;
    const label1Height = topPosition
      ? leftLabel.current?.height() ?? LABEL_HEIGHT
      : 0;
    const label1Width = leftLabel.current?.width() ?? LABEL_WIDTH;

    return {
      x:
        (leftLinePoints.endX + productLeftLinePoints.endX) / 2 -
        label1Width / 2,
      y:
        (leftLinePoints.endY + productLeftLinePoints.endY) / 2 -
        label1Height +
        padding,
    };
  }, [leftLabel.current, leftLinePoints, productLeftLinePoints, topPosition]);

  const rightLabelPosition = useMemo(() => {
    const padding = topPosition ? -5 : 5;
    const label2Height = topPosition
      ? rightLabel.current?.height() ?? LABEL_HEIGHT
      : 0;
    const label2Width = rightLabel.current?.width() ?? LABEL_WIDTH;

    return {
      x:
        (rightLinePoints.endX + productRightLinePoints.endX) / 2 -
        label2Width / 2,
      y:
        (rightLinePoints.endY + productRightLinePoints.endY) / 2 -
        label2Height +
        padding,
    };
  }, [
    rightLabel.current,
    topPosition,
    rightLinePoints,
    productRightLinePoints,
  ]);

  const leftDistanceValue = useMemo(() => Math.abs(distance.left).toFixed(2), [
    distance.left,
  ]);

  const rightDistanceValue = useMemo(
    () => Math.abs(distance.right).toFixed(2),
    [distance.right],
  );

  const gapDistanceValue = useMemo(
    () => Math.abs(topPosition ? distance.top : distance.bottom).toFixed(2),
    [distance.top, distance.bottom, topPosition],
  );

  return (
    <Group data-testid="y-axis">
      <DistanceLine points={leftLinePoints} />
      <DistanceArrow points={leftArrowPoints} />
      <SizeLabel
        ref={leftLabel}
        y={leftLabelPosition.y}
        x={leftLabelPosition.x}
        value={leftDistanceValue}
      />
      {distanceType === DistanceTypes.EDGE ? (
        <>
          <DistanceLine points={productLeftLinePoints} />
          <DistanceLine points={productRightLinePoints} />
        </>
      ) : (
        <DistanceLine points={productCenterLinePoints} />
      )}
      <DistanceArrow points={rightArrowPoints} />
      <SizeLabel
        ref={rightLabel}
        y={rightLabelPosition.y}
        x={rightLabelPosition.x}
        value={rightDistanceValue}
      />
      <DistanceLine points={rightLinePoints} />
      <DistanceLine points={gapTopDistanceLinePoints} />
      <DistanceArrow points={gapArrowDistanceLinePoints} />
      <SizeLabel
        ref={gapLabel}
        y={gapLabelPosition.y}
        x={gapLabelPosition.x}
        value={gapDistanceValue}
      />
      <DistanceLine points={gapBottomDistanceLinePoints} />
    </Group>
  );
}

XDistanceLines.displayName = 'XDistanceLines';

export default XDistanceLines;
