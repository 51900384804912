import { InputRow, InputWrapper } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { Input } from '../../../../../../components/Input';
import { EdgeType, IndentEdgeType } from '../../../../../../types';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  HorizontalPositioning,
  VerticalPositioning,
} from '../DimensionsInputs';
import { useTranslation } from 'react-i18next';
import { DirectionSwitch } from '../../../../../../components/DirectionSwitch';
import { EdgeChangeProps } from '../../containers/GlassForm/GlassDimensions';
import { getOppositeEdge } from '../../../../../../utils/shape';
import { OptionValue } from '../../../../../../components/Switch';

interface RectInputProps {
  selectedId: number;
  wall: IndentEdgeType;
  onBlur: (props: EdgeChangeProps) => void;
  edges: Record<IndentEdgeType, number>;
  lock: boolean;
  defaultDirection?: HorizontalPositioning | VerticalPositioning;
  disabledOptions?: OptionValue[];
  getMaxDimensions?: (
    direction: VerticalPositioning | HorizontalPositioning,
  ) => Record<EdgeType, number>;
}

const RectInput = ({
  selectedId,
  wall,
  onBlur,
  edges,
  defaultDirection,
  disabledOptions,
  getMaxDimensions,
  lock,
}: RectInputProps) => {
  const { t } = useTranslation('creator');

  const isVertical = useMemo(() => {
    return ['top', 'bottom', 'center-horizontal'].includes(wall);
  }, [wall]);

  const [directionSelected, setDirectionSelected] = useState<
    HorizontalPositioning | VerticalPositioning
  >(
    defaultDirection ??
      (isVertical ? HorizontalPositioning.LEFT : VerticalPositioning.TOP),
  );

  const handlePositioningChange = useCallback(
    (value: string[]) =>
      setDirectionSelected(
        value[0] as HorizontalPositioning | VerticalPositioning,
      ),
    [],
  );

  const handleBlur = useCallback(
    (value: string) => {
      onBlur({
        diff: parseFloat(value) - edges[wall],
        edge: wall,
        direction: directionSelected,
        lockOpposite: lock,
      });
    },
    [onBlur, wall, directionSelected, lock],
  );

  const maxDimensions = useMemo(() => {
    const properEdgeMax = getMaxDimensions?.(directionSelected)[
      wall as EdgeType
    ];
    const oppositeEdgeMax = getMaxDimensions?.(directionSelected)[
      getOppositeEdge(wall as EdgeType)
    ];

    if (!properEdgeMax) {
      return undefined;
    }

    if (lock && properEdgeMax && oppositeEdgeMax) {
      return Math.min(properEdgeMax, oppositeEdgeMax);
    }

    return properEdgeMax;
  }, [directionSelected, lock, getMaxDimensions]);

  useEffect(() => {
    setDirectionSelected(
      defaultDirection ??
        (isVertical ? HorizontalPositioning.LEFT : VerticalPositioning.TOP),
    );
  }, [selectedId]);

  return (
    <InputRow>
      <Typography variant={TypographyVariants.H5}>
        {t(`edge.${wall}`)}:
      </Typography>
      <InputWrapper>
        <Input
          name={wall}
          type="number"
          unit="mm"
          min={100}
          width={108}
          onBlur={handleBlur}
          value={String(edges[wall])}
          max={maxDimensions}
          step={0.01}
          errorFeedback
          withoutDebounce
        />
        <DirectionSwitch
          isVertical={isVertical}
          directionSelected={directionSelected}
          disabledOptions={disabledOptions}
          handleDirectionChange={handlePositioningChange}
        />
      </InputWrapper>
    </InputRow>
  );
};

RectInput.displayName = 'RectInput';

export default RectInput;
