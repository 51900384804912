import * as React from 'react';
import { useMemo } from 'react';
import { Group } from 'react-konva';
import {
  CreatorProduct,
  DistanceTypes,
  selectDistanceType,
  selectProducts,
  useProductsStore,
} from '../../store/products';
import { scaleGlass } from '../../utils/shapes';
import { DistanceLine } from '../DistanceLine';
import { Position, Shape } from '../../types';
import { DistanceArrow } from '../DistanceArrow';
import { SizeLabel } from '../SizeLabel';
import { fixValue } from '../../utils/fix';
import { DEFAULT_RATIO } from '../../store/config';

export interface Props {
  productIds: string[];
  templatePosition: Position;
  xAxis?: boolean;
  yAxis?: boolean;
}

type AxisType = 'x' | 'y';

const OFFSET = 50;

const getPairs = (shapes: Map<string, Shape>, axis: 'x' | 'y') => {
  const pairs = [];
  const items = Array.from(shapes.values()).sort(
    (a, b) => a.position[axis] - b.position[axis],
  );
  for (let i = 0; i < items.length - 1; i++) {
    pairs.push([items[i], items[i + 1]]);
  }
  return pairs.filter(
    (pair) => pair[0].position[axis] !== pair[1].position[axis],
  );
};

const countDistance = (
  shape1: Shape,
  shape2: Shape,
  axis: AxisType,
  distanceType: DistanceTypes,
): number => {
  const size1 = axis === 'y' ? shape1.height : shape1.width;
  const size2 = axis === 'y' ? shape2.height : shape2.width;
  if (distanceType === DistanceTypes.AXIS) {
    return Math.abs(
      shape2.position[axis] + size2 / 2 - (shape1.position[axis] + size1 / 2),
    );
  }
  if (distanceType === DistanceTypes.EDGE) {
    return Math.abs(shape2.position[axis] - (shape1.position[axis] + size1));
  }
  return 0;
};

const prepareArrow = (
  pairs: Shape[][],
  ratio: number,
  axis: AxisType,
  distanceType: DistanceTypes,
) =>
  pairs.map(([shape1, shape2]) => {
    const distance = countDistance(shape1, shape2, axis, distanceType);
    const value = fixValue(distance / ratio);
    const start =
      shape1.position[axis] +
      (axis === 'y' ? shape1.height : shape1.width) /
        (distanceType === DistanceTypes.AXIS ? 2 : 1) +
      2 +
      OFFSET;
    const end =
      shape2.position[axis] +
      (distanceType === DistanceTypes.AXIS
        ? (axis === 'y' ? shape2.height : shape2.width) / 2
        : 0) -
      2 -
      start;
    return (
      <Group
        key={`arrow-${start}-${end}`}
        x={axis === 'y' ? 0 : start}
        y={axis === 'y' ? start : 0}>
        <DistanceArrow
          points={{
            startX: -OFFSET,
            startY: -OFFSET,
            endX: axis === 'y' ? -OFFSET : end,
            endY: axis === 'y' ? end : -OFFSET,
          }}
        />
        <SizeLabel
          value={value.toFixed(2)}
          y={axis === 'y' ? (end - OFFSET) / 2 - 10 : -OFFSET - 30}
          x={axis === 'y' ? -(OFFSET + 90) : -OFFSET - 12 + end / 2}
        />
      </Group>
    );
  });

const MultiselectDistance: React.FC<Props> = React.memo(
  ({ productIds, templatePosition, xAxis = true, yAxis = true }) => {
    const products = useProductsStore(selectProducts);
    const distanceType = useProductsStore(selectDistanceType);

    const selectedProducts = useMemo(
      () =>
        productIds
          .map((id) => products.find((product) => product.id === id))
          .filter(Boolean),
      [productIds, products],
    );

    const shapes = useMemo(() => {
      const map = new Map<string, Shape>();
      (selectedProducts as CreatorProduct[]).forEach((product) => {
        const scaledProduct = scaleGlass(product.data, 1);

        map.set(product.id, {
          width: fixValue(scaledProduct.width),
          height: fixValue(scaledProduct.height),
          position: {
            x: scaledProduct.position.x - templatePosition.x,
            y: scaledProduct.position.y - templatePosition.y,
          },
        });
      });
      return map;
    }, [selectedProducts, templatePosition.x, templatePosition.y]);

    const yPairs = useMemo(() => getPairs(shapes, 'y'), [shapes]);

    const xPairs = useMemo(() => getPairs(shapes, 'x'), [shapes]);

    const Lines = useMemo(() => {
      const items = Array.from(shapes);
      const yPositions = items.map(([, value]) => value.position.y);
      const xPositions = items.map(([, value]) => value.position.x);
      const yHasDiff = new Set(yPositions).size > 1;
      const xHasDiff = new Set(xPositions).size > 1;
      return (
        <>
          {yAxis &&
            yHasDiff &&
            items.map(([key, shape]) =>
              distanceType === DistanceTypes.AXIS ? (
                <DistanceLine
                  key={key}
                  points={{
                    startX: -OFFSET,
                    startY: shape.position.y + shape.height / 2,
                    endX: shape.position.x,
                    endY: shape.position.y + shape.height / 2,
                  }}
                />
              ) : (
                <>
                  {shape.position.y !== Math.min(...yPositions) && (
                    <DistanceLine
                      key={`${key}-top`}
                      points={{
                        startX: -OFFSET,
                        startY: shape.position.y,
                        endX: shape.position.x,
                        endY: shape.position.y,
                      }}
                    />
                  )}
                  {shape.position.y !== Math.max(...yPositions) && (
                    <DistanceLine
                      key={`${key}-bottom`}
                      points={{
                        startX: -OFFSET,
                        startY: shape.position.y + shape.height,
                        endX: shape.position.x,
                        endY: shape.position.y + shape.height,
                      }}
                    />
                  )}
                </>
              ),
            )}
          {xAxis &&
            xHasDiff &&
            items.map(([key, shape]) =>
              distanceType === DistanceTypes.AXIS ? (
                <DistanceLine
                  key={key}
                  points={{
                    startX: shape.position.x + shape.width / 2,
                    startY: -OFFSET,
                    endX: shape.position.x + shape.width / 2,
                    endY: shape.position.y,
                  }}
                />
              ) : (
                <>
                  {shape.position.x !== Math.min(...xPositions) && (
                    <DistanceLine
                      key={`${key}-left`}
                      points={{
                        startX: shape.position.x,
                        startY: -OFFSET,
                        endX: shape.position.x,
                        endY: shape.position.y,
                      }}
                    />
                  )}
                  {shape.position.x !== Math.max(...xPositions) && (
                    <DistanceLine
                      key={`${key}-right`}
                      points={{
                        startX: shape.position.x + shape.width,
                        startY: -OFFSET,
                        endX: shape.position.x + shape.width,
                        endY: shape.position.y,
                      }}
                    />
                  )}
                </>
              ),
            )}
        </>
      );
    }, [shapes, distanceType, xAxis, yAxis]);

    const Arrows = useMemo(
      () => (
        <>
          {yAxis && prepareArrow(yPairs, DEFAULT_RATIO, 'y', distanceType)}
          {xAxis && prepareArrow(xPairs, DEFAULT_RATIO, 'x', distanceType)}
        </>
      ),
      [yAxis, yPairs, distanceType, xAxis, xPairs],
    );

    return (
      <Group {...templatePosition}>
        <Group data-testid="y-axis">
          {Lines}
          {Arrows}
        </Group>
      </Group>
    );
  },
);

MultiselectDistance.displayName = 'MultiselectDistance';

export default MultiselectDistance;
