import {
  convertToGlass,
  Corners,
  DataCopy,
  getClippedPolygon,
  getParentPolygon,
  getRectPolygon,
  GlassWithEdges,
} from './boundaries';
import { ANCHOR_POINTS } from '../../../services/api/models/transformer';
import { Vector2d } from 'konva/lib/types';
import { ExtendedDataCopy } from '../components/Transformer/Transformer';
import {
  EdgeType,
  IndentCorners,
  IndentEdgeType,
  RectCorners,
  RectCornersType,
} from '../../../types';
import find from 'lodash/find';
import {
  mapCoordinatesToEdges,
  toPoint,
  toPositionBig,
  toVector,
} from '../../../utils/shape';
import Big from 'big.js';
import { DEFAULT_RATIO } from '../store/config';
import forEach from 'lodash/forEach';
import { CollisionData } from './transformer';
import {
  findBForA,
  findCrossPoint,
  findCrossPointToLine,
  findLine,
  findParallelLine,
  findXPointOnLine,
  findYPointOnLine,
  findPointOnLineInDistanceFromOtherPoint,
  Line,
} from './geometry/lines';
import { calculateMidPoint, checkPointsEqual } from './geometry/points';
import {
  dotProduct,
  doVectorsIntersect,
  getVectorLength,
  Vector,
} from './geometry/vectors';
import {
  IndentPosition,
  ProjectDimensions,
} from '../../../services/api/models/project';
import { findAngle } from '../../../utils/glass';
import Point from '../../space/Point';

const MAX_CORNER_ANGLE = 45;

export const getOppositeCorner = (corner: ANCHOR_POINTS) => {
  switch (corner) {
    case ANCHOR_POINTS.TOP_LEFT: {
      return ANCHOR_POINTS.BOTTOM_RIGHT;
    }
    case ANCHOR_POINTS.TOP_RIGHT: {
      return ANCHOR_POINTS.BOTTOM_LEFT;
    }
    case ANCHOR_POINTS.BOTTOM_LEFT: {
      return ANCHOR_POINTS.TOP_RIGHT;
    }
    default: {
      return ANCHOR_POINTS.TOP_LEFT;
    }
  }
};

const getAdjacentCornerForEdge = (
  corner: ANCHOR_POINTS,
  edge:
    | ANCHOR_POINTS.TOP
    | ANCHOR_POINTS.RIGHT
    | ANCHOR_POINTS.BOTTOM
    | ANCHOR_POINTS.LEFT,
) => {
  if (edge === ANCHOR_POINTS.TOP) {
    if (corner === ANCHOR_POINTS.TOP_LEFT) {
      return ANCHOR_POINTS.TOP_RIGHT;
    } else {
      return ANCHOR_POINTS.TOP_LEFT;
    }
  }
  if (edge === ANCHOR_POINTS.RIGHT) {
    if (corner === ANCHOR_POINTS.TOP_RIGHT) {
      return ANCHOR_POINTS.BOTTOM_RIGHT;
    } else {
      return ANCHOR_POINTS.TOP_RIGHT;
    }
  }
  if (edge === ANCHOR_POINTS.BOTTOM) {
    if (corner === ANCHOR_POINTS.BOTTOM_LEFT) {
      return ANCHOR_POINTS.BOTTOM_RIGHT;
    } else {
      return ANCHOR_POINTS.BOTTOM_LEFT;
    }
  }
  if (edge === ANCHOR_POINTS.LEFT) {
    if (corner === ANCHOR_POINTS.TOP_LEFT) {
      return ANCHOR_POINTS.BOTTOM_LEFT;
    } else {
      return ANCHOR_POINTS.TOP_LEFT;
    }
  }
};

/**
 * It returns the parent edge that the diagonal line crosses
 * @param collider
 * @param diagonalStart
 * @param diagonalEnd
 * @param outPointName
 * @param diagonalOutside - if false - the diagonal line is inside the collider polygon, if true - the diagonal line is outside
 * @returns {vector: Vector, name: string}
 * */
export const getCrossedEdge = (
  collider: GlassWithEdges,
  diagonalStart: Vector2d,
  diagonalEnd: Vector2d,
  outPointName: RectCornersType,
  diagonalOutside = false,
): { vector: Vector; name: string } => {
  let crossedEdge: { vector: Vector; name: string } = {
    vector: { startX: 0, startY: 0, endX: 0, endY: 0 },
    name: '',
  };

  let validatePointToEdges: Record<RectCornersType, [EdgeType, EdgeType]> = {
    'top-left': ['top', 'left'],
    'top-right': ['top', 'right'],
    'bottom-left': ['bottom', 'left'],
    'bottom-right': ['bottom', 'right'],
  };

  if (diagonalOutside) {
    validatePointToEdges = {
      'top-left': ['bottom', 'right'],
      'top-right': ['bottom', 'left'],
      'bottom-left': ['top', 'right'],
      'bottom-right': ['top', 'left'],
    };
  }

  const bindEdgeToPoint = (outPointName: EdgeType) => {
    const edge = collider.edges[outPointName];

    return {
      start: {
        x: edge.startX,
        y: edge.startY,
      },
      end: {
        x: edge.endX,
        y: edge.endY,
      },
    };
  };

  const edgesToValidate = validatePointToEdges[outPointName];

  forEach(edgesToValidate, (edgeName) => {
    const { start, end } = bindEdgeToPoint(edgeName);
    if (doVectorsIntersect(start, end, diagonalStart, diagonalEnd)) {
      crossedEdge = { vector: collider.edges[edgeName], name: edgeName };
    }
  });

  return crossedEdge;
};

const getCorrectedVector = (
  moveVector: Vector2d,
  corner: Vector2d,
  resultVector: Vector2d,
  scale: number,
): Vector2d => {
  const MoveVector = toPositionBig(moveVector);
  const Corner = toPositionBig(corner);
  const ResultVector = toPositionBig(resultVector);
  const Scale = new Big(scale).times(DEFAULT_RATIO);

  const diff = {
    x: MoveVector.x.minus(Corner.x).times(Scale),
    y: MoveVector.y.minus(Corner.y).times(Scale),
  };

  return {
    x: ResultVector.x.minus(diff.x).toNumber(),
    y: ResultVector.y.minus(diff.y).toNumber(),
  };
};

export const getCornersOutsideNiche = (data: DataCopy) => {
  const glass = convertToGlass(data.shape);
  const glassCorners = convertToGlass(data.shape).corners;
  const glassPolygon = getRectPolygon(glass.corners);
  const { polygon: parentPolygon } = getParentPolygon(data.parents[0]);
  const clippedPolygon = getClippedPolygon(parentPolygon, glassPolygon);

  const cornersOutside: string[] = [];

  for (const [glassCornerName, glassCornerValue] of Object.entries(
    glassCorners,
  )) {
    if (
      glassCornerValue &&
      clippedPolygon.find((corner) =>
        checkPointsEqual(toPoint(corner), glassCornerValue),
      ) === undefined
    ) {
      cornersOutside.push(glassCornerName);
    }
  }

  return cornersOutside;
};

export const getCornersIntersectingGlasses = (data: ExtendedDataCopy) => {
  const glass = convertToGlass(data.shape);
  const glassCorners = convertToGlass(data.shape).corners;
  const glassPolygon = getRectPolygon(glass.corners);

  const collisions: CollisionData[] = [];

  forEach(data.shapes, (item, index) => {
    const otherGlass = convertToGlass(item);
    const otherGlassCorners = convertToGlass(item).corners;
    const otherGlassPolygon = getRectPolygon(otherGlass.corners);

    const clippedPolygon = getClippedPolygon(otherGlassPolygon, glassPolygon);

    for (const [glassCornerName, glassCornerValue] of Object.entries(
      glassCorners,
    )) {
      if (
        data.shape.id &&
        item.id &&
        glassCornerValue &&
        clippedPolygon.find((corner) =>
          checkPointsEqual(toPoint(corner), glassCornerValue),
        )
      ) {
        collisions.push({
          shapeId: data.shape.id,
          colliderId: item.id,
          cornerName: glassCornerName,
          length: 0,
        });
      }
    }

    for (const [otherGlassCornerName, otherGlassCornerValue] of Object.entries(
      otherGlassCorners,
    )) {
      if (
        data.shape.id &&
        item.id &&
        otherGlassCornerValue &&
        clippedPolygon.find((corner) =>
          checkPointsEqual(toPoint(corner), otherGlassCornerValue),
        )
      ) {
        collisions.push({
          shapeId: item.id,
          colliderId: data.shape.id,
          cornerName: otherGlassCornerName,
          length: 0,
        });
      }
    }
  });

  return collisions;
};

export const getGlassesWithCornersOutsideNiche = (
  data: Omit<DataCopy, 'shape'>,
) => {
  const glasses = data.shapes;
  const { polygon: parentPolygon } = getParentPolygon(data.parents[0]);

  const cornersOutside: { glass: GlassWithEdges; name: string }[] = [];

  glasses.forEach((glassElement) => {
    const glass = convertToGlass(glassElement);
    const glassCorners = convertToGlass(glassElement).corners;
    const glassPolygon = getRectPolygon(glass.corners);

    const clippedPolygon = getClippedPolygon(parentPolygon, glassPolygon);

    for (const [glassCornerName, glassCornerValue] of Object.entries(
      glassCorners,
    )) {
      if (
        glassCornerValue &&
        clippedPolygon.find((corner) =>
          checkPointsEqual(toPoint(corner), glassCornerValue),
        ) === undefined
      ) {
        cornersOutside.push({ glass: glass, name: glassCornerName });
      }
    }
  });
  return cornersOutside;
};

const findHorizontalMaxPoint = (
  outCorner: [number, number],
  point: [number, number],
  crossedEdge: { vector: Vector; name: string },
) => {
  const glassEdge = findLine(outCorner, point);

  if (crossedEdge.vector.startX !== crossedEdge.vector.endX) {
    const edgeLine = findLine(
      [crossedEdge.vector.startX, crossedEdge.vector.startY],
      [crossedEdge.vector.endX, crossedEdge.vector.endY],
    );

    const crossPoint = findCrossPoint(glassEdge, edgeLine);

    return toVector(crossPoint);
  } else {
    const maxPointY = findYPointOnLine(glassEdge, crossedEdge.vector.startX);
    return toVector([crossedEdge.vector.startX, maxPointY]);
  }
};

const findVerticalMaxPoint = (
  crossedEdge: { vector: Vector; name: string },
  outCorner: [number, number],
  point: [number, number],
) => {
  const edgeLine = findLine(
    [crossedEdge.vector.startX, crossedEdge.vector.startY],
    [crossedEdge.vector.endX, crossedEdge.vector.endY],
  );

  if (outCorner[0] !== point[0]) {
    const glassEdge = findLine(outCorner, point);
    const crossPoint = findCrossPoint(glassEdge, edgeLine);

    return toVector(crossPoint);
  } else {
    const maxPointY = findYPointOnLine(edgeLine, outCorner[0]);
    return toVector([outCorner[0], maxPointY]);
  }
};

export const handleDraggedCornerCollision = (
  modifiedGlass: GlassWithEdges,
  modifiedCornerId: RectCornersType,
  colliderGlass: GlassWithEdges,
  outCornerId: RectCornersType,
  scaledVector: Vector2d,
  vector: Vector2d,
  scale: number,
  modifiedOutsideCollider = false,
) => {
  const glassCorners = modifiedGlass.corners;
  const outCorner = glassCorners[modifiedCornerId];

  const diagonalStart = toVector(glassCorners[modifiedCornerId]);
  const diagonalEnd = toVector(
    glassCorners[getOppositeCorner(modifiedCornerId as ANCHOR_POINTS)],
  );

  const crossedEdge = getCrossedEdge(
    colliderGlass,
    diagonalStart,
    diagonalEnd,
    outCornerId,
    modifiedOutsideCollider,
  );

  if (crossedEdge.name === '') {
    return vector;
  }

  let maxPoint = { x: 0, y: 0 };

  let validateEdgesForPoints = {
    [ANCHOR_POINTS.TOP_RIGHT]: [
      { neighborCorner: 'bottom-right', edge: 'top' },
      { neighborCorner: 'top-left', edge: 'right' },
    ],
    [ANCHOR_POINTS.BOTTOM_RIGHT]: [
      { neighborCorner: 'top-right', edge: 'bottom' },
      { neighborCorner: 'bottom-left', edge: 'right' },
    ],
    [ANCHOR_POINTS.BOTTOM_LEFT]: [
      { neighborCorner: 'top-left', edge: 'bottom' },
      { neighborCorner: 'bottom-right', edge: 'left' },
    ],
    [ANCHOR_POINTS.TOP_LEFT]: [
      { neighborCorner: 'bottom-left', edge: 'top' },
      { neighborCorner: 'top-right', edge: 'left' },
    ],
  };

  if (modifiedOutsideCollider) {
    validateEdgesForPoints = {
      [ANCHOR_POINTS.TOP_RIGHT]: [
        { neighborCorner: 'bottom-right', edge: 'bottom' },
        { neighborCorner: 'top-left', edge: 'left' },
      ],
      [ANCHOR_POINTS.BOTTOM_RIGHT]: [
        { neighborCorner: 'top-right', edge: 'top' },
        { neighborCorner: 'bottom-left', edge: 'left' },
      ],
      [ANCHOR_POINTS.BOTTOM_LEFT]: [
        { neighborCorner: 'top-left', edge: 'top' },
        { neighborCorner: 'bottom-right', edge: 'right' },
      ],
      [ANCHOR_POINTS.TOP_LEFT]: [
        { neighborCorner: 'bottom-left', edge: 'bottom' },
        { neighborCorner: 'top-right', edge: 'right' },
      ],
    };
  }

  const validateEdges = validateEdgesForPoints[modifiedCornerId];

  validateEdges.forEach(({ neighborCorner, edge }, index) => {
    if (crossedEdge.name === edge) {
      const point = glassCorners[neighborCorner as 'top-left'];
      if (edge === 'top' || edge === 'bottom') {
        maxPoint = findVerticalMaxPoint(crossedEdge, outCorner, point);
      } else {
        maxPoint = findHorizontalMaxPoint(outCorner, point, crossedEdge);
      }
    }
  });

  return getCorrectedVector(scaledVector, maxPoint, vector, scale);
};

const findMaxAdjacentPoint = (
  outCorner: [number, number],
  adjacentCorner: [number, number],
  outOppositeCorner: [number, number],
  maxPoint: [number, number],
): [number, number] => {
  if (outCorner[0] === adjacentCorner[0]) {
    const line = findLine(outOppositeCorner, adjacentCorner);
    const maxAdjacentPointY = findYPointOnLine(line, maxPoint[0]);
    return [maxPoint[0], maxAdjacentPointY];
  } else {
    const lineA = findLine(outCorner, adjacentCorner);
    const lineAParallel = findParallelLine(maxPoint as [number, number], lineA);
    if (outOppositeCorner[0] === adjacentCorner[0]) {
      const maxAdjacentPointY = findYPointOnLine(
        lineAParallel,
        adjacentCorner[0],
      );
      return [adjacentCorner[0], maxAdjacentPointY];
    } else {
      const lineB = findLine(outOppositeCorner, adjacentCorner);
      return findCrossPoint(lineAParallel, lineB);
    }
  }
};

const findMaxPoint = (
  crossedEdge: Vector,
  outCorner: [number, number],
  adjacentOppositeCorner: [number, number],
): [number, number] => {
  if (crossedEdge.startX === crossedEdge.endX) {
    if (outCorner[0] === adjacentOppositeCorner[0]) {
      return [outCorner[0], outCorner[1]];
    } else {
      const line = findLine(outCorner, adjacentOppositeCorner);
      const maxPointY = findYPointOnLine(line, crossedEdge.startX);
      return [crossedEdge.startX, maxPointY];
    }
  } else {
    const edgeLine = findLine(
      [crossedEdge.startX, crossedEdge.startY],
      [crossedEdge.endX, crossedEdge.endY],
    );

    if (outCorner[0] === adjacentOppositeCorner[0]) {
      const maxPointY = findYPointOnLine(edgeLine, outCorner[0]);
      return [outCorner[0], maxPointY];
    } else {
      const line = findLine(outCorner, adjacentOppositeCorner);

      if (Math.abs(edgeLine.a) === 0 && Math.abs(line.a) === 0) {
        return [outCorner[0], outCorner[1]];
      }
      return findCrossPoint(edgeLine, line);
    }
  }
};

export const handleDraggedEdgeCollision = (
  modifiedGlass: GlassWithEdges,
  modifiedCornerId:
    | ANCHOR_POINTS.TOP
    | ANCHOR_POINTS.BOTTOM
    | ANCHOR_POINTS.LEFT
    | ANCHOR_POINTS.RIGHT,
  colliderGlass: GlassWithEdges,
  outCornerId: RectCornersType,
  scaledVector: Vector2d,
  vector: Vector2d,
  scale: number,
  modifiedOutsideCollider = false,
) => {
  const glassCorners = modifiedGlass.corners;
  const outCorner = glassCorners[outCornerId];
  const adjacentCornerName = getAdjacentCornerForEdge(
    outCornerId as ANCHOR_POINTS,
    modifiedCornerId,
  );

  if (!adjacentCornerName) {
    console.error('adjacentCornerName is undefined');
    return vector;
  }

  const adjacentCorner = glassCorners[adjacentCornerName];
  const adjacentOppositeCorner =
    glassCorners[getOppositeCorner(adjacentCornerName)];
  const outOppositeCorner =
    glassCorners[getOppositeCorner(outCornerId as ANCHOR_POINTS)];

  const crossedEdge = getCrossedEdge(
    colliderGlass,
    { x: outOppositeCorner[0], y: outOppositeCorner[1] },
    { x: outCorner[0], y: outCorner[1] },
    outCornerId,
    modifiedOutsideCollider,
  ).vector;

  const maxPoint = findMaxPoint(crossedEdge, outCorner, adjacentOppositeCorner);

  const maxAdjacentPoint = findMaxAdjacentPoint(
    outCorner,
    adjacentCorner,
    outOppositeCorner,
    maxPoint,
  );

  const newAnchorPoint = calculateMidPoint(maxPoint, maxAdjacentPoint);

  return getCorrectedVector(scaledVector, newAnchorPoint, vector, scale);
};

/**
 * It resolves collision for dragged edge when colliding corner doesn't belong to modifiedGlass but to colliderGlass
 * @param modifiedGlass
 * @param modifiedCornerId
 * @param colliderGlass
 * @param outCornerId
 * @param scaledVector
 * @param vector
 * @param scale
 */
const handleDraggedEdgeCollisionInnerCollider = (
  modifiedGlass: GlassWithEdges,
  modifiedCornerId:
    | ANCHOR_POINTS.TOP
    | ANCHOR_POINTS.BOTTOM
    | ANCHOR_POINTS.LEFT
    | ANCHOR_POINTS.RIGHT,
  colliderGlass: GlassWithEdges,
  outCornerId: RectCornersType,
  scaledVector: Vector2d,
  vector: Vector2d,
  scale: number,
) => {
  const adjacentCornerName = getAdjacentCornerForEdge(
    outCornerId as ANCHOR_POINTS,
    modifiedCornerId,
  );

  const oppositeAdjacentCornerName = getAdjacentCornerForEdge(
    adjacentCornerName as ANCHOR_POINTS,
    modifiedCornerId,
  );

  if (!adjacentCornerName || !oppositeAdjacentCornerName) {
    console.error('adjacentCornerName is undefined');
    return vector;
  }

  const adjacentResolved = handleDraggedCornerCollisionInnerCollider(
    modifiedGlass,
    adjacentCornerName,
    colliderGlass,
    outCornerId as ANCHOR_POINTS.TOP_LEFT,
    scaledVector,
    vector,
    scale,
  );
  const oppositeAdjacentResolved = handleDraggedCornerCollisionInnerCollider(
    modifiedGlass,
    oppositeAdjacentCornerName,
    colliderGlass,
    outCornerId as ANCHOR_POINTS.TOP_LEFT,
    scaledVector,
    vector,
    scale,
  );

  return calculateMidPoint(
    toPoint(adjacentResolved),
    toPoint(oppositeAdjacentResolved),
  );
};

const findMaxPositionForOutPoint = (
  outCorner: [number, number],
  oppositeMovedCorner: [number, number],
  crossedEdgeVector: Vector,
): [number, number] => {
  if (outCorner[0] === oppositeMovedCorner[0]) {
    const edgeLine = findLine(
      [crossedEdgeVector.startX, crossedEdgeVector.startY],
      [crossedEdgeVector.endX, crossedEdgeVector.endY],
    );

    const maxOutPointY = findYPointOnLine(edgeLine, outCorner[0]);
    return [outCorner[0], maxOutPointY];
  } else {
    const lineOutMovedOpposite = findLine(outCorner, oppositeMovedCorner);

    if (crossedEdgeVector.startX === crossedEdgeVector.endX) {
      const maxOutPointY = findYPointOnLine(
        lineOutMovedOpposite,
        crossedEdgeVector.startX,
      );

      return [crossedEdgeVector.startX, maxOutPointY];
    } else {
      const crossedEdgeLine = findLine(
        [crossedEdgeVector.startX, crossedEdgeVector.startY],
        [crossedEdgeVector.endX, crossedEdgeVector.endY],
      );

      if (
        Math.abs(crossedEdgeLine.a) === 0 &&
        Math.abs(lineOutMovedOpposite.a) === 0
      ) {
        return [outCorner[0], outCorner[1]];
      }

      return findCrossPoint(crossedEdgeLine, lineOutMovedOpposite);
    }
  }
};

const findMaxMovedPoint = (
  movedCorner: [number, number],
  outCorner: [number, number],
  maxPositionForOutPoint: [number, number],
  oppositeOutCorner: [number, number],
): [number, number] => {
  if (movedCorner[0] === outCorner[0]) {
    return [maxPositionForOutPoint[0], movedCorner[1]];
  } else {
    const lineMovedOut = findLine(movedCorner, outCorner);
    const lineMovedOutParallel = findParallelLine(
      maxPositionForOutPoint,
      lineMovedOut,
    );

    if (movedCorner[0] === oppositeOutCorner[0]) {
      const maxMovedPointY = findYPointOnLine(
        lineMovedOutParallel,
        movedCorner[0],
      );
      return [movedCorner[0], maxMovedPointY];
    } else {
      const lineMovedOppositeOut = findLine(movedCorner, oppositeOutCorner);
      return findCrossPoint(lineMovedOppositeOut, lineMovedOutParallel);
    }
  }
};

export const handleOtherCornerCollision = (
  modifiedGlass: GlassWithEdges,
  modifiedCornerId: RectCornersType,
  colliderGlass: GlassWithEdges,
  outCornerId: RectCornersType,
  scaledVector: Vector2d,
  vector: Vector2d,
  scale: number,
  modifiedOutsideCollider = false,
) => {
  const glassCorners = modifiedGlass.corners;
  const outCorner = glassCorners[outCornerId];
  const oppositeOutCorner =
    glassCorners[getOppositeCorner(outCornerId as ANCHOR_POINTS)];
  const movedCorner = glassCorners[modifiedCornerId as RectCornersType];
  const oppositeMovedCorner =
    glassCorners[getOppositeCorner(modifiedCornerId as ANCHOR_POINTS)];

  const crossedEdge = getCrossedEdge(
    colliderGlass,
    toVector(outCorner),
    toVector(oppositeOutCorner),
    outCornerId,
    modifiedOutsideCollider,
  );

  const crossedEdgeVector = crossedEdge.vector;
  const crossedEdgeName = crossedEdge.name;

  if (crossedEdgeName === '') {
    return vector;
  }

  let maxPositionForOutPoint: [number, number];

  // In case outCorner and oppositeMovedCorner are vertically inline
  // and crossedEdgeVector is vertically inline we return current vector
  if (
    outCorner[0] === oppositeMovedCorner[0] &&
    crossedEdgeVector.startX === crossedEdgeVector.endX
  ) {
    maxPositionForOutPoint = [outCorner[0], crossedEdgeVector.startY];
  } else {
    maxPositionForOutPoint = findMaxPositionForOutPoint(
      outCorner,
      oppositeMovedCorner,
      crossedEdgeVector,
    );
  }

  const maxMovedPoint = findMaxMovedPoint(
    movedCorner,
    outCorner,
    maxPositionForOutPoint,
    oppositeOutCorner,
  );

  return getCorrectedVector(
    scaledVector,
    toVector(maxMovedPoint),
    vector,
    scale,
  );
};

/**
 * It resolves collision for dragged corner when colliding corner doesn't belong to modifiedGlass but to colliderGlass
 * @param modifiedGlass
 * @param modifiedCornerId
 * @param colliderGlass
 * @param outCornerId
 * @param scaledVector
 * @param vector
 * @param scale
 */
const handleDraggedCornerCollisionInnerCollider = (
  modifiedGlass: GlassWithEdges,
  modifiedCornerId:
    | ANCHOR_POINTS.TOP_LEFT
    | ANCHOR_POINTS.TOP_RIGHT
    | ANCHOR_POINTS.BOTTOM_LEFT
    | ANCHOR_POINTS.BOTTOM_RIGHT,
  colliderGlass: GlassWithEdges,
  outCornerId:
    | ANCHOR_POINTS.TOP_LEFT
    | ANCHOR_POINTS.TOP_RIGHT
    | ANCHOR_POINTS.BOTTOM_LEFT
    | ANCHOR_POINTS.BOTTOM_RIGHT,
  scaledVector: Vector2d,
  vector: Vector2d,
  scale: number,
) => {
  const findVerticalMaxPoint = () => {
    if (crossedEdge.vector.startX !== crossedEdge.vector.endX) {
      const edgeLine = findLine(
        [crossedEdge.vector.startX, crossedEdge.vector.startY],
        [crossedEdge.vector.endX, crossedEdge.vector.endY],
      );

      if (Math.abs(edgeLine.a) === 0) {
        if (outCorner[1] === movedCorner[1]) {
          return { x: outCorner[0], y: movedCorner[1] };
        } else {
          return { x: movedCorner[0], y: outCorner[1] };
        }
      }

      const parallelLine = findParallelLine(outCorner, edgeLine);
      const maxYPoint = findYPointOnLine(parallelLine, movedCorner[0]);

      return { x: movedCorner[0], y: maxYPoint };
    } else {
      return { x: movedCorner[0], y: outCorner[1] };
    }
  };

  const findHorizontalMaxPoint = () => {
    if (crossedEdge.vector.startX !== crossedEdge.vector.endX) {
      const edgeLine = findLine(
        [crossedEdge.vector.startX, crossedEdge.vector.startY],
        [crossedEdge.vector.endX, crossedEdge.vector.endY],
      );

      if (Math.abs(edgeLine.a) === 0) {
        return { x: movedCorner[0], y: outCorner[1] };
      }

      const parallelLine = findParallelLine(outCorner, edgeLine);
      const maxXPoint = findXPointOnLine(parallelLine, movedCorner[1]);

      return { x: maxXPoint, y: movedCorner[1] };
    } else {
      return { x: outCorner[0], y: movedCorner[1] };
    }
  };

  const outCorner = colliderGlass.corners[outCornerId];
  const oppositeOutCorner =
    colliderGlass.corners[getOppositeCorner(outCornerId)];
  const movedCorner = modifiedGlass.corners[modifiedCornerId];

  const crossedEdge = getCrossedEdge(
    modifiedGlass,
    toVector(outCorner),
    toVector(oppositeOutCorner),
    outCornerId,
    true,
  );

  if (crossedEdge.name === '') {
    return vector;
  }

  let maxPoint;

  ['top', 'bottom', 'left', 'right'].forEach((edge) => {
    if (crossedEdge.name === edge) {
      if (edge === 'top' || edge === 'bottom') {
        maxPoint = findVerticalMaxPoint();
      } else {
        maxPoint = findHorizontalMaxPoint();
      }
    }
  });

  if (!maxPoint) {
    return vector;
  }

  return getCorrectedVector(scaledVector, maxPoint, vector, scale);
};

/**
 * Global function for removing collision for glass corner when moving any of glass corners
 * @param id - id of currently moved corner
 * @param data - data of available shapes in project
 * @param scaledVector
 * @param vector - current position of dragged corner. It is modified in children functions
 * @param {CollisionData} collider - data of colliding shape
 * @returns {Vector2d} - new position of dragged corner
 */
export const removeCollisionForGlass = (
  id: ANCHOR_POINTS,
  data: ExtendedDataCopy,
  scaledVector: Vector2d,
  vector: Vector2d,
  collider: CollisionData,
): Vector2d => {
  const outCornerName = collider.cornerName as RectCornersType;
  const glass = convertToGlass(data.shape);

  if (collider.shapeId !== data.shape.id) {
    const collidingShape = find([data.shape, ...data.shapes], {
      id: collider.shapeId,
    });

    if (!collidingShape) {
      return vector;
    }

    const colliderGlass = convertToGlass(collidingShape);

    if (id === 'top' || id === 'bottom' || id === 'left' || id === 'right') {
      return handleDraggedEdgeCollisionInnerCollider(
        glass,
        id,
        colliderGlass,
        outCornerName,
        scaledVector,
        vector,
        data.scale,
      );
    }

    return handleDraggedCornerCollisionInnerCollider(
      glass,
      id as ANCHOR_POINTS.TOP_LEFT,
      colliderGlass,
      outCornerName as ANCHOR_POINTS.TOP_LEFT,
      scaledVector,
      vector,
      data.scale,
    );
  }

  const collidingShape = find([data.shape, ...data.shapes], {
    id: collider.colliderId,
  });

  if (!collidingShape) {
    return vector;
  }

  const colliderGlass = convertToGlass(collidingShape);

  if (outCornerName === id) {
    return handleDraggedCornerCollision(
      glass,
      id,
      colliderGlass,
      outCornerName,
      scaledVector,
      vector,
      data.scale,
      true,
    );
  }

  if (id === 'top' || id === 'bottom' || id === 'left' || id === 'right') {
    return handleDraggedEdgeCollision(
      glass,
      id,
      colliderGlass,
      outCornerName,
      scaledVector,
      vector,
      data.scale,
      true,
    );
  }

  return handleOtherCornerCollision(
    glass,
    id as ANCHOR_POINTS.TOP_LEFT,
    colliderGlass,
    outCornerName,
    scaledVector,
    vector,
    data.scale,
    true,
  );
};

export type UtmostPoints = {
  maxX: [number, number];
  maxY: [number, number];
  minX: [number, number];
  minY: [number, number];
};

export const getUtmostPoints = (
  niche: GlassWithEdges,
  id: string,
  cornersOutside: {
    glass: GlassWithEdges;
    name: string;
  }[],
  ignoreNiche: boolean,
) => {
  const points: [number, number][] = [];

  if (!ignoreNiche) {
    if (id === 'top-left') {
      points.push(niche.corners['top-left']);
    }
    if (id === 'top-right') {
      points.push(niche.corners['top-right']);
    }
    if (id === 'bottom-left') {
      points.push(niche.corners['bottom-left']);
    }
    if (id === 'bottom-right') {
      points.push(niche.corners['bottom-right']);
    }
    if (id === 'center-right') {
      points.push(niche.corners['center-right']!);
    }
    if (id === 'center-left') {
      points.push(niche.corners['center-left']!);
    }
  }

  cornersOutside.forEach((corner) => {
    points.push(corner.glass.corners[corner.name as RectCornersType]);
  });

  let maxX: [number, number] = points[0];
  let maxY: [number, number] = points[0];
  let minX: [number, number] = points[0];
  let minY: [number, number] = points[0];

  points.map((point) => {
    if (point[0] > maxX[0]) {
      maxX = point;
    }
    if (point[1] > maxY[1]) {
      maxY = point;
    }
    if (point[0] < minX[0]) {
      minX = point;
    }
    if (point[1] < minY[1]) {
      minY = point;
    }
  });

  return {
    maxX: maxX,
    maxY: maxY,
    minX: minX,
    minY: minY,
  } as UtmostPoints;
};

const calculateEdgeAnchor = (
  cornerA: [number, number],
  cornerB: [number, number],
) => {
  return [(cornerA[0] + cornerB[0]) / 2, (cornerA[1] + cornerB[1]) / 2] as [
    number,
    number,
  ];
};

enum Direction {
  TOP,
  BOTTOM,
  LEFT,
  RIGHT,
}

// asumption: vectors have common start point
const getMoreDistantLineFromVectors = (
  start: [number, number],
  vectorAEnd: [number, number],
  vectorBEnd: [number, number],
  direciton: Direction,
) => {
  const vectorA: Vector = {
    startX: start[0],
    startY: start[1],
    endX: vectorAEnd[0],
    endY: vectorAEnd[1],
  };
  const vectorB: Vector = {
    startX: start[0],
    startY: start[1],
    endX: vectorBEnd[0],
    endY: vectorBEnd[1],
  };
  let moreDistantVector: Vector;
  if (direciton === Direction.TOP) {
    moreDistantVector = vectorA.endY < vectorB.endY ? vectorA : vectorB;
  }
  if (direciton === Direction.RIGHT) {
    moreDistantVector = vectorA.endX < vectorB.endX ? vectorB : vectorA;
  }
  if (direciton === Direction.BOTTOM) {
    moreDistantVector = vectorA.endY < vectorB.endY ? vectorB : vectorA;
  }
  if (direciton === Direction.LEFT) {
    moreDistantVector = vectorA.endX < vectorB.endX ? vectorA : vectorB;
  }

  return findLine(
    [moreDistantVector!.startX, moreDistantVector!.startY],
    [moreDistantVector!.endX, moreDistantVector!.endY],
  );
};

const getMoreDistantLine = (lineA: Line, lineB: Line, direciton: Direction) => {
  const biggerBLine = lineA.b > lineB.b ? lineA : lineB;
  const smallerBLine = lineA.b < lineB.b ? lineA : lineB;

  if (direciton === Direction.TOP) {
    return smallerBLine;
  }
  if (direciton === Direction.BOTTOM) {
    return biggerBLine;
  }
  if (direciton === Direction.RIGHT) {
    if (lineA.a < 0) {
      return biggerBLine;
    } else {
      return smallerBLine;
    }
  } else {
    if (lineA.a < 0) {
      return smallerBLine;
    } else {
      return biggerBLine;
    }
  }
};

const getNewLineForNiche = (
  utmostPointGlass: [number, number],
  utmostPointNiche: [number, number],
  line: Line,
  direction: Direction,
) => {
  const lineParallelGlass = findParallelLine(utmostPointGlass, line);
  const lineParallelNiche = findParallelLine(utmostPointNiche, line);

  return getMoreDistantLine(lineParallelGlass, lineParallelNiche, direction);
};

export const removeCollisionForNiche = (
  id: ANCHOR_POINTS,
  data: Omit<DataCopy, 'shape'>,
  scaledVector: Vector2d,
  vector: Vector2d,
  utmostPointsOnlyGlasses: UtmostPoints,
  utmostPointsWithNiche: UtmostPoints,
  adjustManually: boolean,
) => {
  let newAnchorPoint: [number, number] = [0, 0];
  const { parent } = getParentPolygon(data.parents[0]);
  const nicheCorners = parent.corners;

  // assumption: they are never vertical (90deg to x axis)
  const lineTLTR = findLine(
    nicheCorners['top-left'],
    nicheCorners['top-right'],
  );
  const lineBLBR = findLine(
    nicheCorners['bottom-left'],
    nicheCorners['bottom-right'],
  );

  if (id === 'left') {
    let newPointTL: [number, number] = [0, 0];
    let newPointBL: [number, number] = [0, 0];
    if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
      const lineTLBL = findLine(
        nicheCorners['top-left'],
        nicheCorners['bottom-left'],
      );
      const lineTLBLPararell = findParallelLine(
        utmostPointsOnlyGlasses.minX,
        lineTLBL,
      );

      newPointTL = findCrossPoint(lineTLTR, lineTLBLPararell);
      newPointBL = findCrossPoint(lineBLBR, lineTLBLPararell);
    } else {
      const minPointX = utmostPointsOnlyGlasses.minX[0];
      const newPointTLY = findYPointOnLine(lineTLTR, minPointX);
      const newPointBLY = findYPointOnLine(lineBLBR, minPointX);
      newPointTL = [minPointX, newPointTLY];
      newPointBL = [minPointX, newPointBLY];
    }
    newAnchorPoint = calculateEdgeAnchor(newPointTL, newPointBL);
  }
  if (id === 'right') {
    let newPointTR: [number, number] = [0, 0];
    let newPointBR: [number, number] = [0, 0];
    if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
      const lineTRBR = findLine(
        nicheCorners['top-right'],
        nicheCorners['bottom-right'],
      );
      const lineTRBRParallel = findParallelLine(
        utmostPointsOnlyGlasses.maxX,
        lineTRBR,
      );

      newPointTR = findCrossPoint(lineTLTR, lineTRBRParallel);
      newPointBR = findCrossPoint(lineBLBR, lineTRBRParallel);
    } else {
      const maxPointX = utmostPointsOnlyGlasses.maxX[0];
      const newPointTRY = findYPointOnLine(lineTLTR, maxPointX);
      const newPointBRY = findYPointOnLine(lineBLBR, maxPointX);
      newPointTR = [maxPointX, newPointTRY];
      newPointBR = [maxPointX, newPointBRY];
    }
    newAnchorPoint = calculateEdgeAnchor(newPointTR, newPointBR);
  }
  if (id === 'top') {
    const lineTLTRParallel = findParallelLine(
      utmostPointsOnlyGlasses.minY,
      lineTLTR,
    );

    let newPointTR: [number, number] = [0, 0];
    let newPointTL: [number, number] = [0, 0];

    if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
      const lineTRBR = findLine(
        nicheCorners['top-right'],
        nicheCorners['bottom-right'],
      );

      newPointTR = findCrossPoint(lineTLTRParallel, lineTRBR);
    } else {
      const newPointTRY = findYPointOnLine(
        lineTLTRParallel,
        nicheCorners['top-right'][0],
      );

      newPointTR = [nicheCorners['top-right'][0], newPointTRY];
    }

    if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
      const lineTLBL = findLine(
        nicheCorners['top-left'],
        nicheCorners['bottom-left'],
      );

      newPointTL = findCrossPoint(lineTLTRParallel, lineTLBL);
    } else {
      const newPointTLY = findYPointOnLine(
        lineTLTRParallel,
        nicheCorners['top-left'][0],
      );

      newPointTL = [nicheCorners['top-left'][0], newPointTLY];
    }

    newAnchorPoint = calculateEdgeAnchor(newPointTR, newPointTL);
  }
  if (id === 'bottom') {
    const lineBLBRParallel = findParallelLine(
      utmostPointsOnlyGlasses.maxY,
      lineBLBR,
    );

    let newPointBR: [number, number] = [0, 0];
    let newPointBL: [number, number] = [0, 0];

    if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
      const lineTRBR = findLine(
        nicheCorners['top-right'],
        nicheCorners['bottom-right'],
      );

      newPointBR = findCrossPoint(lineBLBRParallel, lineTRBR);
    } else {
      const newPointBRY = findYPointOnLine(
        lineBLBRParallel,
        nicheCorners['bottom-right'][0],
      );

      newPointBR = [nicheCorners['bottom-right'][0], newPointBRY];
    }

    if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
      const lineTLBL = findLine(
        nicheCorners['top-left'],
        nicheCorners['bottom-left'],
      );

      newPointBL = findCrossPoint(lineBLBRParallel, lineTLBL);
    } else {
      const newPointBLY = findYPointOnLine(
        lineBLBRParallel,
        nicheCorners['bottom-left'][0],
      );

      newPointBL = [nicheCorners['bottom-left'][0], newPointBLY];
    }

    newAnchorPoint = calculateEdgeAnchor(newPointBR, newPointBL);
  }
  if (!adjustManually) {
    if (id === 'top-left') {
      const lineTLTRNew = getNewLineForNiche(
        utmostPointsOnlyGlasses.minY,
        utmostPointsWithNiche.minY,
        lineTLTR,
        Direction.TOP,
      );

      if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
        const lineTLBL = findLine(
          nicheCorners['top-left'],
          nicheCorners['bottom-left'],
        );

        const lineTLBLNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.minX,
          utmostPointsWithNiche.minX,
          lineTLBL,
          Direction.LEFT,
        );

        newAnchorPoint = findCrossPoint(lineTLBLNew, lineTLTRNew);
      } else {
        const moreDistantPoint =
          utmostPointsWithNiche.minX[0] < utmostPointsOnlyGlasses.minX[0]
            ? utmostPointsWithNiche.minX
            : utmostPointsOnlyGlasses.minX;

        const newAnchorPointY = findYPointOnLine(
          lineTLTRNew,
          moreDistantPoint[0],
        );

        newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
      }
    }
    if (id === 'top-right') {
      const lineTLTRNew = getNewLineForNiche(
        utmostPointsOnlyGlasses.minY,
        utmostPointsWithNiche.minY,
        lineTLTR,
        Direction.TOP,
      );

      if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
        const lineTRBR = findLine(
          nicheCorners['top-right'],
          nicheCorners['bottom-right'],
        );

        const lineTRBRNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.maxX,
          utmostPointsWithNiche.maxX,
          lineTRBR,
          Direction.RIGHT,
        );

        newAnchorPoint = findCrossPoint(lineTRBRNew, lineTLTRNew);
      } else {
        const moreDistantPoint =
          utmostPointsWithNiche.maxX[0] > utmostPointsOnlyGlasses.maxX[0]
            ? utmostPointsWithNiche.maxX
            : utmostPointsOnlyGlasses.maxX;

        const newAnchorPointY = findYPointOnLine(
          lineTLTRNew,
          moreDistantPoint[0],
        );

        newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
      }
    }
    if (id === 'bottom-right') {
      const lineBLBRNew = getNewLineForNiche(
        utmostPointsOnlyGlasses.maxY,
        utmostPointsWithNiche.maxY,
        lineBLBR,
        Direction.BOTTOM,
      );

      if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
        const lineTRBR = findLine(
          nicheCorners['top-right'],
          nicheCorners['bottom-right'],
        );

        const lineTRBRNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.maxX,
          utmostPointsWithNiche.maxX,
          lineTRBR,
          Direction.RIGHT,
        );

        newAnchorPoint = findCrossPoint(lineTRBRNew, lineBLBRNew);
      } else {
        const moreDistantPoint =
          utmostPointsWithNiche.maxX[0] > utmostPointsOnlyGlasses.maxX[0]
            ? utmostPointsWithNiche.maxX
            : utmostPointsOnlyGlasses.maxX;

        const newAnchorPointY = findYPointOnLine(
          lineBLBRNew,
          moreDistantPoint[0],
        );

        newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
      }
    }
    if (id === 'bottom-left') {
      const lineBLBRNew = getNewLineForNiche(
        utmostPointsOnlyGlasses.maxY,
        utmostPointsWithNiche.maxY,
        lineBLBR,
        Direction.BOTTOM,
      );

      if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
        const lineTLBL = findLine(
          nicheCorners['top-left'],
          nicheCorners['bottom-left'],
        );

        const lineTLBLNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.minX,
          utmostPointsWithNiche.minX,
          lineTLBL,
          Direction.LEFT,
        );

        newAnchorPoint = findCrossPoint(lineTLBLNew, lineBLBRNew);
      } else {
        const moreDistantPoint =
          utmostPointsWithNiche.minX[0] < utmostPointsOnlyGlasses.minX[0]
            ? utmostPointsWithNiche.minX
            : utmostPointsOnlyGlasses.minX;

        const newAnchorPointY = findYPointOnLine(
          lineBLBRNew,
          moreDistantPoint[0],
        );

        newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
      }
    }
  } else {
    // adjust manually
    const nicheTRCorner = nicheCorners['top-right'];
    const nicheTLCorner = nicheCorners['top-left'];
    const nicheBLCorner = nicheCorners['bottom-left'];
    const nicheBRCorner = nicheCorners['bottom-right'];

    if (id === 'top-left') {
      const lineTop = getMoreDistantLineFromVectors(
        nicheTRCorner,
        utmostPointsWithNiche.minY,
        utmostPointsOnlyGlasses.minY,
        Direction.TOP,
      );

      if (
        nicheBLCorner[0] !== utmostPointsWithNiche.minX[0] &&
        nicheBLCorner[0] !== utmostPointsOnlyGlasses.minX[0]
      ) {
        const lineLeft = getMoreDistantLineFromVectors(
          nicheBLCorner,
          utmostPointsWithNiche.minX,
          utmostPointsOnlyGlasses.minX,
          Direction.LEFT,
        );

        newAnchorPoint = findCrossPoint(lineTop, lineLeft);
      } else {
        const minPointX =
          utmostPointsWithNiche.minX[0] > utmostPointsOnlyGlasses.minX[0]
            ? utmostPointsWithNiche.minX
            : utmostPointsOnlyGlasses.minX;
        const newAnchorPointY = findYPointOnLine(lineTop, minPointX[0]);
        newAnchorPoint = [minPointX[0], newAnchorPointY];
      }
    }
    if (id === 'bottom-left') {
      const lineBottom = getMoreDistantLineFromVectors(
        nicheBRCorner,
        utmostPointsWithNiche.maxY,
        utmostPointsOnlyGlasses.maxY,
        Direction.BOTTOM,
      );

      if (
        nicheTLCorner[0] !== utmostPointsWithNiche.minX[0] &&
        nicheTLCorner[0] !== utmostPointsOnlyGlasses.minX[0]
      ) {
        const lineLeft = getMoreDistantLineFromVectors(
          nicheTLCorner,
          utmostPointsWithNiche.minX,
          utmostPointsOnlyGlasses.minX,
          Direction.LEFT,
        );

        newAnchorPoint = findCrossPoint(lineBottom, lineLeft);
      } else {
        const minPointX =
          utmostPointsWithNiche.minX[0] > utmostPointsOnlyGlasses.minX[0]
            ? utmostPointsWithNiche.minX
            : utmostPointsOnlyGlasses.minX;
        const newAnchorPointY = findYPointOnLine(lineBottom, minPointX[0]);
        newAnchorPoint = [minPointX[0], newAnchorPointY];
      }
    }
    if (id === 'bottom-right') {
      const lineBottom = getMoreDistantLineFromVectors(
        nicheBLCorner,
        utmostPointsWithNiche.maxY,
        utmostPointsOnlyGlasses.maxY,
        Direction.BOTTOM,
      );

      if (
        nicheTRCorner[0] !== utmostPointsWithNiche.maxX[0] &&
        nicheTRCorner[0] !== utmostPointsOnlyGlasses.maxX[0]
      ) {
        const lineRight = getMoreDistantLineFromVectors(
          nicheTRCorner,
          utmostPointsWithNiche.maxX,
          utmostPointsOnlyGlasses.maxX,
          Direction.RIGHT,
        );

        newAnchorPoint = findCrossPoint(lineBottom, lineRight);
      } else {
        const maxPointX =
          utmostPointsWithNiche.maxX[0] < utmostPointsOnlyGlasses.maxX[0]
            ? utmostPointsWithNiche.maxX
            : utmostPointsOnlyGlasses.maxX;
        const newAnchorPointY = findYPointOnLine(lineBottom, maxPointX[0]);
        newAnchorPoint = [maxPointX[0], newAnchorPointY];
      }
    }
    if (id === 'top-right') {
      const lineTop = getMoreDistantLineFromVectors(
        nicheTLCorner,
        utmostPointsWithNiche.minY,
        utmostPointsOnlyGlasses.minY,
        Direction.TOP,
      );

      if (
        nicheBRCorner[0] !== utmostPointsWithNiche.maxX[0] &&
        nicheBRCorner[0] !== utmostPointsOnlyGlasses.maxX[0]
      ) {
        const lineRight = getMoreDistantLineFromVectors(
          nicheBRCorner,
          utmostPointsWithNiche.maxX,
          utmostPointsOnlyGlasses.maxX,
          Direction.RIGHT,
        );

        newAnchorPoint = findCrossPoint(lineTop, lineRight);
      } else {
        const maxPointX =
          utmostPointsWithNiche.maxX[0] < utmostPointsOnlyGlasses.maxX[0]
            ? utmostPointsWithNiche.maxX
            : utmostPointsOnlyGlasses.maxX;
        const newAnchorPointY = findYPointOnLine(lineTop, maxPointX[0]);
        newAnchorPoint = [maxPointX[0], newAnchorPointY];
      }
    }
  }

  return getCorrectedVector(
    scaledVector,
    { x: newAnchorPoint[0], y: newAnchorPoint[1] },
    vector,
    data.scale,
  );
};

// could be refactored to one function
export const removeCollisionForLShapedNiche = (
  id: ANCHOR_POINTS,
  data: Omit<DataCopy, 'shape'>,
  scaledVector: Vector2d,
  vector: Vector2d,
  utmostPointsOnlyGlasses: UtmostPoints,
  utmostPointsWithNiche: UtmostPoints,
  adjustManually: boolean,
) => {
  let newAnchorPoint: [number, number] = [0, 0];
  const { parent } = getParentPolygon(data.parents[0]);
  const nicheCorners = parent.corners;
  const indentPosition = data.parents[0].indent;

  // assumption: they are never vertical (90deg to x axis)
  const lineTLTR = findLine(
    nicheCorners['top-left'],
    nicheCorners['top-right'],
  );
  const lineBLBR = findLine(
    nicheCorners['bottom-left'],
    nicheCorners['bottom-right'],
  );

  if (indentPosition === IndentPosition.TOP_RIGHT) {
    if (id === 'bottom') {
      const lineBLBRParallel = findParallelLine(
        utmostPointsOnlyGlasses.maxY,
        lineBLBR,
      );

      let newPointBR: [number, number] = [0, 0];
      let newPointBL: [number, number] = [0, 0];

      if (
        nicheCorners['center-right']![0] !== nicheCorners['bottom-right'][0]
      ) {
        const lineCRBR = findLine(
          nicheCorners['center-right']!,
          nicheCorners['bottom-right'],
        );

        newPointBR = findCrossPoint(lineBLBRParallel, lineCRBR);
      } else {
        const newPointBRY = findYPointOnLine(
          lineBLBRParallel,
          nicheCorners['bottom-right'][0],
        );

        newPointBR = [nicheCorners['bottom-right'][0], newPointBRY];
      }

      if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
        const lineTLBL = findLine(
          nicheCorners['top-left'],
          nicheCorners['bottom-left'],
        );

        newPointBL = findCrossPoint(lineBLBRParallel, lineTLBL);
      } else {
        const newPointBLY = findYPointOnLine(
          lineBLBRParallel,
          nicheCorners['bottom-left'][0],
        );

        newPointBL = [nicheCorners['bottom-left'][0], newPointBLY];
      }

      newAnchorPoint = calculateEdgeAnchor(newPointBR, newPointBL);
    }
    if (id === 'top' || (id === 'top-right' && !adjustManually)) {
      const lineTLTRParallel = findParallelLine(
        utmostPointsOnlyGlasses.minY,
        lineTLTR,
      );

      let newPointTR: [number, number] = [0, 0];
      let newPointTL: [number, number] = [0, 0];

      if (nicheCorners['top-right'][0] !== nicheCorners['center']![0]) {
        const lineTRC = findLine(
          nicheCorners['top-right'],
          nicheCorners['center']!,
        );

        newPointTR = findCrossPoint(lineTLTRParallel, lineTRC);
      } else {
        const newPointTRY = findYPointOnLine(
          lineTLTRParallel,
          nicheCorners['top-right'][0],
        );

        newPointTR = [nicheCorners['top-right'][0], newPointTRY];
      }

      if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
        const lineTLBL = findLine(
          nicheCorners['top-left'],
          nicheCorners['bottom-left'],
        );

        newPointTL = findCrossPoint(lineTLTRParallel, lineTLBL);
      } else {
        const newPointTLY = findYPointOnLine(
          lineTLTRParallel,
          nicheCorners['top-left'][0],
        );

        newPointTL = [nicheCorners['top-left'][0], newPointTLY];
      }

      newAnchorPoint =
        id === 'top' ? calculateEdgeAnchor(newPointTR, newPointTL) : newPointTR;
    }
    if (id === 'right' || (id === 'center-right' && !adjustManually)) {
      const lineCCR = findLine(
        nicheCorners['center']!,
        nicheCorners['center-right']!,
      );

      let newPointCR: [number, number] = [0, 0];
      let newPointBR: [number, number] = [0, 0];
      if (
        nicheCorners['center-right']![0] !== nicheCorners['bottom-right'][0]
      ) {
        const lineCRBR = findLine(
          nicheCorners['center-right']!,
          nicheCorners['bottom-right'],
        );
        const lineCRBRParallel = findParallelLine(
          utmostPointsOnlyGlasses.maxX,
          lineCRBR,
        );

        newPointCR = findCrossPoint(lineCCR, lineCRBRParallel);
        newPointBR = findCrossPoint(lineBLBR, lineCRBRParallel);
      } else {
        const maxPointX = utmostPointsOnlyGlasses.maxX[0];
        const newPointCRY = findYPointOnLine(lineCCR, maxPointX);
        const newPointBRY = findYPointOnLine(lineBLBR, maxPointX);
        newPointCR = [maxPointX, newPointCRY];
        newPointBR = [maxPointX, newPointBRY];
      }
      newAnchorPoint =
        id === 'right'
          ? calculateEdgeAnchor(newPointCR, newPointBR)
          : newPointCR;
    }

    if (!adjustManually) {
      if (id === 'bottom-right') {
        const lineBLBRNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.maxY,
          utmostPointsWithNiche.maxY,
          lineBLBR,
          Direction.BOTTOM,
        );

        if (
          nicheCorners['center-right']![0] !== nicheCorners['bottom-right'][0]
        ) {
          const lineCRBR = findLine(
            nicheCorners['center-right']!,
            nicheCorners['bottom-right'],
          );

          const lineCRBRNew = getNewLineForNiche(
            utmostPointsOnlyGlasses.maxX,
            utmostPointsWithNiche.maxX,
            lineCRBR,
            Direction.RIGHT,
          );

          newAnchorPoint = findCrossPoint(lineCRBRNew, lineBLBRNew);
        } else {
          const moreDistantPoint =
            utmostPointsWithNiche.maxX[0] > utmostPointsOnlyGlasses.maxX[0]
              ? utmostPointsWithNiche.maxX
              : utmostPointsOnlyGlasses.maxX;

          const newAnchorPointY = findYPointOnLine(
            lineBLBRNew,
            moreDistantPoint[0],
          );

          newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
        }
      }
    } else {
      if (id === 'bottom-right') {
        const nicheTRCorner = nicheCorners['center-right']!;
        const nicheBLCorner = nicheCorners['bottom-left'];

        const lineBottom = getMoreDistantLineFromVectors(
          nicheBLCorner,
          utmostPointsWithNiche.maxY,
          utmostPointsOnlyGlasses.maxY,
          Direction.BOTTOM,
        );

        if (
          nicheTRCorner[0] !== utmostPointsWithNiche.maxX[0] &&
          nicheTRCorner[0] !== utmostPointsOnlyGlasses.maxX[0]
        ) {
          const lineRight = getMoreDistantLineFromVectors(
            nicheTRCorner,
            utmostPointsWithNiche.maxX,
            utmostPointsOnlyGlasses.maxX,
            Direction.RIGHT,
          );

          newAnchorPoint = findCrossPoint(lineBottom!, lineRight);
        } else {
          const maxPointX =
            utmostPointsWithNiche.maxX[0] < utmostPointsOnlyGlasses.maxX[0]
              ? utmostPointsWithNiche.maxX
              : utmostPointsOnlyGlasses.maxX;
          const newAnchorPointY = findYPointOnLine(lineBottom, maxPointX[0]);
          newAnchorPoint = [maxPointX[0], newAnchorPointY];
        }
      }
      if (id === 'top-right') {
        const nicheTLCorner = nicheCorners['top-left'];
        const nicheCCorner = nicheCorners['center']!;

        const lineTop = getMoreDistantLineFromVectors(
          nicheTLCorner,
          utmostPointsWithNiche.minY,
          utmostPointsOnlyGlasses.minY,
          Direction.TOP,
        );

        if (
          nicheCCorner[0] !== utmostPointsWithNiche.maxX[0] &&
          nicheCCorner[0] !== utmostPointsOnlyGlasses.maxX[0]
        ) {
          const lineRight = getMoreDistantLineFromVectors(
            nicheCCorner,
            utmostPointsWithNiche.maxX,
            utmostPointsOnlyGlasses.maxX,
            Direction.RIGHT,
          );

          newAnchorPoint = findCrossPoint(lineTop!, lineRight!);
        } else {
          const maxPointX =
            utmostPointsWithNiche.maxX[0] < utmostPointsOnlyGlasses.maxX[0]
              ? utmostPointsWithNiche.maxX
              : utmostPointsOnlyGlasses.maxX;
          const newAnchorPointY = findYPointOnLine(lineTop!, maxPointX[0]);
          newAnchorPoint = [maxPointX[0], newAnchorPointY];
        }
      }
      if (id === 'center-right') {
        const nicheBRCorner = nicheCorners['bottom-right'];
        const nicheCCorner = nicheCorners['center']!;

        const lineTop = getMoreDistantLineFromVectors(
          nicheCCorner,
          utmostPointsWithNiche.minY,
          utmostPointsOnlyGlasses.minY,
          Direction.TOP,
        );

        if (
          nicheBRCorner[0] !== utmostPointsWithNiche.minX[0] &&
          nicheBRCorner[0] !== utmostPointsOnlyGlasses.minX[0]
        ) {
          const lineRight = getMoreDistantLineFromVectors(
            nicheBRCorner,
            utmostPointsWithNiche.minX,
            utmostPointsOnlyGlasses.minX,
            Direction.RIGHT,
          );

          newAnchorPoint = findCrossPoint(lineTop, lineRight);
        } else {
          const minPointX =
            utmostPointsWithNiche.minX[0] > utmostPointsOnlyGlasses.minX[0]
              ? utmostPointsWithNiche.minX
              : utmostPointsOnlyGlasses.minX;
          const newAnchorPointY = findYPointOnLine(lineTop, minPointX[0]);
          newAnchorPoint = [minPointX[0], newAnchorPointY];
        }
      }
    }
  }

  if (indentPosition === IndentPosition.TOP_LEFT) {
    if (id === 'bottom') {
      const lineBLBRParallel = findParallelLine(
        utmostPointsOnlyGlasses.maxY,
        lineBLBR,
      );

      let newPointBR: [number, number] = [0, 0];
      let newPointBL: [number, number] = [0, 0];

      if (nicheCorners['top-right']![0] !== nicheCorners['bottom-right'][0]) {
        const lineTRBR = findLine(
          nicheCorners['top-right']!,
          nicheCorners['bottom-right'],
        );

        newPointBR = findCrossPoint(lineBLBRParallel, lineTRBR);
      } else {
        const newPointBRY = findYPointOnLine(
          lineBLBRParallel,
          nicheCorners['bottom-right'][0],
        );

        newPointBR = [nicheCorners['bottom-right'][0], newPointBRY];
      }

      if (nicheCorners['center-left']![0] !== nicheCorners['bottom-left'][0]) {
        const lineCLBL = findLine(
          nicheCorners['center-left']!,
          nicheCorners['bottom-left'],
        );

        newPointBL = findCrossPoint(lineBLBRParallel, lineCLBL);
      } else {
        const newPointBLY = findYPointOnLine(
          lineBLBRParallel,
          nicheCorners['bottom-left'][0],
        );

        newPointBL = [nicheCorners['bottom-left'][0], newPointBLY];
      }

      newAnchorPoint = calculateEdgeAnchor(newPointBR, newPointBL);
    }
    if (id === 'top' || (id === 'top-left' && !adjustManually)) {
      const lineTLTRParallel = findParallelLine(
        utmostPointsOnlyGlasses.minY,
        lineTLTR,
      );

      let newPointTR: [number, number] = [0, 0];
      let newPointTL: [number, number] = [0, 0];

      if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
        const lineTRBR = findLine(
          nicheCorners['top-right'],
          nicheCorners['bottom-right'],
        );

        newPointTR = findCrossPoint(lineTLTRParallel, lineTRBR);
      } else {
        const newPointTRY = findYPointOnLine(
          lineTLTRParallel,
          nicheCorners['top-right'][0],
        );

        newPointTR = [nicheCorners['top-right'][0], newPointTRY];
      }

      if (nicheCorners['top-left'][0] !== nicheCorners['center']![0]) {
        const lineTLC = findLine(
          nicheCorners['top-left'],
          nicheCorners['center']!,
        );

        newPointTL = findCrossPoint(lineTLTRParallel, lineTLC);
      } else {
        const newPointTLY = findYPointOnLine(
          lineTLTRParallel,
          nicheCorners['top-left'][0],
        );

        newPointTL = [nicheCorners['top-left'][0], newPointTLY];
      }

      newAnchorPoint =
        id === 'top' ? calculateEdgeAnchor(newPointTR, newPointTL) : newPointTL;
    }
    if (id === 'left' || (id === 'center-left' && !adjustManually)) {
      const lineCLC = findLine(
        nicheCorners['center-left']!,
        nicheCorners['center']!,
      );

      let newPointCL: [number, number] = [0, 0];
      let newPointBL: [number, number] = [0, 0];
      if (nicheCorners['center-left']![0] !== nicheCorners['bottom-left'][0]) {
        const lineCLBL = findLine(
          nicheCorners['center-left']!,
          nicheCorners['bottom-left'],
        );
        const lineCLBLPararell = findParallelLine(
          utmostPointsOnlyGlasses.minX,
          lineCLBL,
        );

        newPointCL = findCrossPoint(lineCLC, lineCLBLPararell);
        newPointBL = findCrossPoint(lineBLBR, lineCLBLPararell);
      } else {
        const minPointX = utmostPointsOnlyGlasses.minX[0];
        const newPointCLY = findYPointOnLine(lineCLC, minPointX);
        const newPointBLY = findYPointOnLine(lineBLBR, minPointX);
        newPointCL = [minPointX, newPointCLY];
        newPointBL = [minPointX, newPointBLY];
      }
      newAnchorPoint =
        id === 'left'
          ? calculateEdgeAnchor(newPointCL, newPointBL)
          : newPointCL;
    }
    if (!adjustManually) {
      if (id === 'bottom-left') {
        const lineBLBRNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.maxY,
          utmostPointsWithNiche.maxY,
          lineBLBR,
          Direction.BOTTOM,
        );

        if (
          nicheCorners['center-left']![0] !== nicheCorners['bottom-left'][0]
        ) {
          const lineCLBL = findLine(
            nicheCorners['center-left']!,
            nicheCorners['bottom-left'],
          );

          const lineCLBLNew = getNewLineForNiche(
            utmostPointsOnlyGlasses.minX,
            utmostPointsWithNiche.minX,
            lineCLBL,
            Direction.LEFT,
          );

          newAnchorPoint = findCrossPoint(lineCLBLNew, lineBLBRNew);
        } else {
          const moreDistantPoint =
            utmostPointsWithNiche.minX[0] < utmostPointsOnlyGlasses.minX[0]
              ? utmostPointsWithNiche.minX
              : utmostPointsOnlyGlasses.minX;

          const newAnchorPointY = findYPointOnLine(
            lineBLBRNew,
            moreDistantPoint[0],
          );

          newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
        }
      }
    } else {
      if (id === 'bottom-left') {
        const nicheBRCorner = nicheCorners['bottom-right'];
        const nicheCLCorner = nicheCorners['center-left']!;

        const lineBottom = getMoreDistantLineFromVectors(
          nicheBRCorner,
          utmostPointsWithNiche.maxY,
          utmostPointsOnlyGlasses.maxY,
          Direction.BOTTOM,
        );

        if (
          nicheCLCorner[0] !== utmostPointsWithNiche.minX[0] &&
          nicheCLCorner[0] !== utmostPointsOnlyGlasses.minX[0]
        ) {
          const lineLeft = getMoreDistantLineFromVectors(
            nicheCLCorner,
            utmostPointsWithNiche.minX,
            utmostPointsOnlyGlasses.minX,
            Direction.LEFT,
          );

          newAnchorPoint = findCrossPoint(lineBottom, lineLeft);
        } else {
          const minPointX =
            utmostPointsWithNiche.minX[0] > utmostPointsOnlyGlasses.minX[0]
              ? utmostPointsWithNiche.minX
              : utmostPointsOnlyGlasses.minX;
          const newAnchorPointY = findYPointOnLine(lineBottom, minPointX[0]);
          newAnchorPoint = [minPointX[0], newAnchorPointY];
        }
      }
      if (id === 'center-left') {
        const nicheBLCorner = nicheCorners['bottom-left'];
        const nicheCCorner = nicheCorners['center']!;

        const lineTopNiche = findLine(nicheCCorner, utmostPointsWithNiche.minY);
        const lineTopGlass = findLine(
          nicheCCorner,
          utmostPointsOnlyGlasses.minY,
        );
        const lineTop = getMoreDistantLine(
          lineTopNiche,
          lineTopGlass,
          Direction.TOP,
        );

        if (
          nicheBLCorner[0] !== utmostPointsWithNiche.minX[0] &&
          nicheBLCorner[0] !== utmostPointsOnlyGlasses.minX[0]
        ) {
          const lineLeftNiche = findLine(
            nicheBLCorner,
            utmostPointsWithNiche.minX,
          );
          const lineLeftGlass = findLine(
            nicheBLCorner,
            utmostPointsOnlyGlasses.minX,
          );

          const lineLeft = getMoreDistantLine(
            lineLeftNiche,
            lineLeftGlass,
            Direction.LEFT,
          );

          newAnchorPoint = findCrossPoint(lineTop, lineLeft);
        } else {
          const minPointX =
            utmostPointsWithNiche.minX[0] > utmostPointsOnlyGlasses.minX[0]
              ? utmostPointsWithNiche.minX
              : utmostPointsOnlyGlasses.minX;
          const newAnchorPointY = findYPointOnLine(lineTop, minPointX[0]);
          newAnchorPoint = [minPointX[0], newAnchorPointY];
        }
      }
      if (id === 'top-left') {
        const nicheTRCorner = nicheCorners['top-right'];
        const nicheCCorner = nicheCorners['center']!;

        const lineTop = getMoreDistantLineFromVectors(
          nicheTRCorner,
          utmostPointsWithNiche.minY,
          utmostPointsOnlyGlasses.minY,
          Direction.TOP,
        );

        if (
          nicheCCorner[0] !== utmostPointsWithNiche.minX[0] &&
          nicheCCorner[0] !== utmostPointsOnlyGlasses.minX[0]
        ) {
          const lineLeft = getMoreDistantLineFromVectors(
            nicheCCorner,
            utmostPointsWithNiche.minX,
            utmostPointsOnlyGlasses.minX,
            Direction.LEFT,
          );

          newAnchorPoint = findCrossPoint(lineTop, lineLeft);
        } else {
          const minPointX =
            utmostPointsWithNiche.minX[0] > utmostPointsOnlyGlasses.minX[0]
              ? utmostPointsWithNiche.minX
              : utmostPointsOnlyGlasses.minX;
          const newAnchorPointY = findYPointOnLine(lineTop, minPointX[0]);
          newAnchorPoint = [minPointX[0], newAnchorPointY];
        }
      }
    }
  }

  if (indentPosition === IndentPosition.BOTTOM_RIGHT) {
    if (id === 'top') {
      const lineTLTRParallel = findParallelLine(
        utmostPointsOnlyGlasses.minY,
        lineTLTR,
      );

      let newPointTR: [number, number] = [0, 0];
      let newPointTL: [number, number] = [0, 0];

      if (nicheCorners['top-right'][0] !== nicheCorners['center-right']![0]) {
        const lineTRCR = findLine(
          nicheCorners['top-right'],
          nicheCorners['center-right']!,
        );

        newPointTR = findCrossPoint(lineTLTRParallel, lineTRCR);
      } else {
        const newPointTRY = findYPointOnLine(
          lineTLTRParallel,
          nicheCorners['top-right'][0],
        );

        newPointTR = [nicheCorners['top-right'][0], newPointTRY];
      }

      if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
        const lineTLBL = findLine(
          nicheCorners['top-left'],
          nicheCorners['bottom-left'],
        );

        newPointTL = findCrossPoint(lineTLTRParallel, lineTLBL);
      } else {
        const newPointTLY = findYPointOnLine(
          lineTLTRParallel,
          nicheCorners['top-left'][0],
        );

        newPointTL = [nicheCorners['top-left'][0], newPointTLY];
      }

      newAnchorPoint = calculateEdgeAnchor(newPointTR, newPointTL);
    }
    if (id === 'bottom' || (id === 'bottom-right' && !adjustManually)) {
      const lineBLBRParallel = findParallelLine(
        utmostPointsOnlyGlasses.maxY,
        lineBLBR,
      );

      let newPointBR: [number, number] = [0, 0];
      let newPointBL: [number, number] = [0, 0];

      if (nicheCorners['center']![0] !== nicheCorners['bottom-right'][0]) {
        const lineCBR = findLine(
          nicheCorners['center']!,
          nicheCorners['bottom-right'],
        );

        newPointBR = findCrossPoint(lineBLBRParallel, lineCBR);
      } else {
        const newPointBRY = findYPointOnLine(
          lineBLBRParallel,
          nicheCorners['bottom-right'][0],
        );

        newPointBR = [nicheCorners['bottom-right'][0], newPointBRY];
      }

      if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
        const lineTLBL = findLine(
          nicheCorners['top-left'],
          nicheCorners['bottom-left'],
        );

        newPointBL = findCrossPoint(lineBLBRParallel, lineTLBL);
      } else {
        const newPointBLY = findYPointOnLine(
          lineBLBRParallel,
          nicheCorners['bottom-left'][0],
        );

        newPointBL = [nicheCorners['bottom-left'][0], newPointBLY];
      }

      newAnchorPoint =
        id === 'bottom'
          ? calculateEdgeAnchor(newPointBR, newPointBL)
          : newPointBR;
    }
    if (id === 'right' || (id === 'center-right' && !adjustManually)) {
      const lineCCR = findLine(
        nicheCorners['center']!,
        nicheCorners['center-right']!,
      );

      let newPointTR: [number, number] = [0, 0];
      let newPointCR: [number, number] = [0, 0];
      if (nicheCorners['top-right'][0] !== nicheCorners['center-right']![0]) {
        const lineTRCR = findLine(
          nicheCorners['top-right'],
          nicheCorners['center-right']!,
        );
        const lineTRCRParallel = findParallelLine(
          utmostPointsOnlyGlasses.maxX,
          lineTRCR,
        );

        newPointTR = findCrossPoint(lineTLTR, lineTRCRParallel);
        newPointCR = findCrossPoint(lineCCR, lineTRCRParallel);
      } else {
        const maxPointX = utmostPointsOnlyGlasses.maxX[0];
        const newPointTRY = findYPointOnLine(lineTLTR, maxPointX);
        const newPointCRY = findYPointOnLine(lineCCR, maxPointX);
        newPointTR = [maxPointX, newPointTRY];
        newPointCR = [maxPointX, newPointCRY];
      }
      newAnchorPoint =
        id === 'right'
          ? calculateEdgeAnchor(newPointTR, newPointCR)
          : newPointCR;
    }
    if (!adjustManually) {
      if (id === 'top-right') {
        const lineTLTRNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.minY,
          utmostPointsWithNiche.minY,
          lineTLTR,
          Direction.TOP,
        );

        if (nicheCorners['top-right'][0] !== nicheCorners['center-right']![0]) {
          const lineTRCR = findLine(
            nicheCorners['top-right'],
            nicheCorners['center-right']!,
          );

          const lineTRCRNew = getNewLineForNiche(
            utmostPointsOnlyGlasses.maxX,
            utmostPointsWithNiche.maxX,
            lineTRCR,
            Direction.RIGHT,
          );

          newAnchorPoint = findCrossPoint(lineTRCRNew, lineTLTRNew);
        } else {
          const moreDistantPoint =
            utmostPointsWithNiche.maxX[0] > utmostPointsOnlyGlasses.maxX[0]
              ? utmostPointsWithNiche.maxX
              : utmostPointsOnlyGlasses.maxX;

          const newAnchorPointY = findYPointOnLine(
            lineTLTRNew,
            moreDistantPoint[0],
          );

          newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
        }
      }
    } else {
      if (id === 'top-right') {
        const nicheTLCorner = nicheCorners['top-left'];
        const nicheCRCorner = nicheCorners['center-right']!;

        const lineTop = getMoreDistantLineFromVectors(
          nicheTLCorner,
          utmostPointsWithNiche.minY,
          utmostPointsOnlyGlasses.minY,
          Direction.TOP,
        );

        if (
          nicheCRCorner[0] !== utmostPointsWithNiche.maxX[0] &&
          nicheCRCorner[0] !== utmostPointsOnlyGlasses.maxX[0]
        ) {
          const lineRight = getMoreDistantLineFromVectors(
            nicheCRCorner,
            utmostPointsWithNiche.maxX,
            utmostPointsOnlyGlasses.maxX,
            Direction.RIGHT,
          );

          newAnchorPoint = findCrossPoint(lineTop, lineRight);
        } else {
          const maxPointX =
            utmostPointsWithNiche.maxX[0] < utmostPointsOnlyGlasses.maxX[0]
              ? utmostPointsWithNiche.maxX
              : utmostPointsOnlyGlasses.maxX;
          const newAnchorPointY = findYPointOnLine(lineTop, maxPointX[0]);
          newAnchorPoint = [maxPointX[0], newAnchorPointY];
        }
      }
      if (id === 'center-right') {
        const nicheTRCorner = nicheCorners['top-right'];
        const nicheCCorner = nicheCorners['center']!;

        const lineBottom = getMoreDistantLineFromVectors(
          nicheCCorner,
          utmostPointsWithNiche.maxY,
          utmostPointsOnlyGlasses.maxY,
          Direction.BOTTOM,
        );

        if (
          nicheTRCorner[0] !== utmostPointsWithNiche.maxX[0] &&
          nicheTRCorner[0] !== utmostPointsOnlyGlasses.maxX[0]
        ) {
          const lineRight = getMoreDistantLineFromVectors(
            nicheTRCorner,
            utmostPointsWithNiche.maxX,
            utmostPointsOnlyGlasses.maxX,
            Direction.RIGHT,
          );

          newAnchorPoint = findCrossPoint(lineBottom, lineRight);
        } else {
          const maxPointX =
            utmostPointsWithNiche.maxX[0] < utmostPointsOnlyGlasses.maxX[0]
              ? utmostPointsWithNiche.maxX
              : utmostPointsOnlyGlasses.maxX;
          const newAnchorPointY = findYPointOnLine(lineBottom, maxPointX[0]);
          newAnchorPoint = [maxPointX[0], newAnchorPointY];
        }
      }
      if (id === 'bottom-right') {
        const nicheBLCorner = nicheCorners['bottom-left'];
        const nicheCCorner = nicheCorners['center']!;

        const lineBottom = getMoreDistantLineFromVectors(
          nicheBLCorner,
          utmostPointsWithNiche.maxY,
          utmostPointsOnlyGlasses.maxY,
          Direction.BOTTOM,
        );

        if (
          nicheCCorner[0] !== utmostPointsWithNiche.maxX[0] &&
          nicheCCorner[0] !== utmostPointsOnlyGlasses.maxX[0]
        ) {
          const lineRight = getMoreDistantLineFromVectors(
            nicheCCorner,
            utmostPointsWithNiche.maxX,
            utmostPointsOnlyGlasses.maxX,
            Direction.RIGHT,
          );

          newAnchorPoint = findCrossPoint(lineBottom, lineRight);
        } else {
          const maxPointX =
            utmostPointsWithNiche.maxX[0] < utmostPointsOnlyGlasses.maxX[0]
              ? utmostPointsWithNiche.maxX
              : utmostPointsOnlyGlasses.maxX;
          const newAnchorPointY = findYPointOnLine(lineBottom, maxPointX[0]);
          newAnchorPoint = [maxPointX[0], newAnchorPointY];
        }
      }
    }
  }

  if (indentPosition === IndentPosition.BOTTOM_LEFT) {
    if (id === 'top') {
      const lineTLTRParallel = findParallelLine(
        utmostPointsOnlyGlasses.minY,
        lineTLTR,
      );

      let newPointTR: [number, number] = [0, 0];
      let newPointTL: [number, number] = [0, 0];

      if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
        const lineTRBR = findLine(
          nicheCorners['top-right'],
          nicheCorners['bottom-right'],
        );

        newPointTR = findCrossPoint(lineTLTRParallel, lineTRBR);
      } else {
        const newPointTRY = findYPointOnLine(
          lineTLTRParallel,
          nicheCorners['top-right'][0],
        );

        newPointTR = [nicheCorners['top-right'][0], newPointTRY];
      }

      if (nicheCorners['top-left'][0] !== nicheCorners['center-left']![0]) {
        const lineTLCL = findLine(
          nicheCorners['top-left'],
          nicheCorners['center-left']!,
        );

        newPointTL = findCrossPoint(lineTLTRParallel, lineTLCL);
      } else {
        const newPointTLY = findYPointOnLine(
          lineTLTRParallel,
          nicheCorners['top-left'][0],
        );

        newPointTL = [nicheCorners['top-left'][0], newPointTLY];
      }

      newAnchorPoint = calculateEdgeAnchor(newPointTR, newPointTL);
    }
    if (id === 'bottom' || (id === 'bottom-left' && !adjustManually)) {
      const lineBLBRParallel = findParallelLine(
        utmostPointsOnlyGlasses.maxY,
        lineBLBR,
      );

      let newPointBR: [number, number] = [0, 0];
      let newPointBL: [number, number] = [0, 0];

      if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
        const lineTRBR = findLine(
          nicheCorners['top-right'],
          nicheCorners['bottom-right'],
        );

        newPointBR = findCrossPoint(lineBLBRParallel, lineTRBR);
      } else {
        const newPointBRY = findYPointOnLine(
          lineBLBRParallel,
          nicheCorners['bottom-right'][0],
        );

        newPointBR = [nicheCorners['bottom-right'][0], newPointBRY];
      }

      if (nicheCorners['center']![0] !== nicheCorners['bottom-left'][0]) {
        const lineCBL = findLine(
          nicheCorners['center']!,
          nicheCorners['bottom-left'],
        );

        newPointBL = findCrossPoint(lineBLBRParallel, lineCBL);
      } else {
        const newPointBLY = findYPointOnLine(
          lineBLBRParallel,
          nicheCorners['bottom-left'][0],
        );

        newPointBL = [nicheCorners['bottom-left'][0], newPointBLY];
      }

      newAnchorPoint =
        id === 'bottom'
          ? calculateEdgeAnchor(newPointBR, newPointBL)
          : newPointBL;
    }
    if (id === 'left' || (id === 'center-left' && !adjustManually)) {
      const lineCLC = findLine(
        nicheCorners['center-left']!,
        nicheCorners['center']!,
      );

      let newPointTL: [number, number] = [0, 0];
      let newPointCL: [number, number] = [0, 0];
      if (nicheCorners['top-left'][0] !== nicheCorners['center-left']![0]) {
        const lineTLBL = findLine(
          nicheCorners['top-left'],
          nicheCorners['center-left']!,
        );
        const lineTLBLPararell = findParallelLine(
          utmostPointsOnlyGlasses.minX,
          lineTLBL,
        );

        newPointTL = findCrossPoint(lineTLTR, lineTLBLPararell);
        newPointCL = findCrossPoint(lineCLC, lineTLBLPararell);
      } else {
        const minPointX = utmostPointsOnlyGlasses.minX[0];
        const newPointTLY = findYPointOnLine(lineTLTR, minPointX);
        const newPointCLY = findYPointOnLine(lineCLC, minPointX);
        newPointTL = [minPointX, newPointTLY];
        newPointCL = [minPointX, newPointCLY];
      }
      newAnchorPoint =
        id === 'left'
          ? calculateEdgeAnchor(newPointTL, newPointCL)
          : newPointCL;
    }

    if (!adjustManually) {
      if (id === 'top-left') {
        const lineTLTRNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.minY,
          utmostPointsWithNiche.minY,
          lineTLTR,
          Direction.TOP,
        );

        if (nicheCorners['top-left'][0] !== nicheCorners['center-left']![0]) {
          const lineTLCL = findLine(
            nicheCorners['top-left'],
            nicheCorners['center-left']!,
          );

          const lineTLCLNew = getNewLineForNiche(
            utmostPointsOnlyGlasses.minX,
            utmostPointsWithNiche.minX,
            lineTLCL,
            Direction.LEFT,
          );

          newAnchorPoint = findCrossPoint(lineTLCLNew, lineTLTRNew);
        } else {
          const moreDistantPoint =
            utmostPointsWithNiche.minX[0] < utmostPointsOnlyGlasses.minX[0]
              ? utmostPointsWithNiche.minX
              : utmostPointsOnlyGlasses.minX;

          const newAnchorPointY = findYPointOnLine(
            lineTLTRNew,
            moreDistantPoint[0],
          );

          newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
        }
      }
    } else {
      if (id === 'top-left') {
        const nicheTRCorner = nicheCorners['top-right'];
        const nicheCLCorner = nicheCorners['center-left']!;

        const lineTop = getMoreDistantLineFromVectors(
          nicheTRCorner,
          utmostPointsWithNiche.minY,
          utmostPointsOnlyGlasses.minY,
          Direction.TOP,
        );

        if (
          nicheCLCorner[0] !== utmostPointsWithNiche.minX[0] &&
          nicheCLCorner[0] !== utmostPointsOnlyGlasses.minX[0]
        ) {
          const lineLeft = getMoreDistantLineFromVectors(
            nicheCLCorner,
            utmostPointsWithNiche.minX,
            utmostPointsOnlyGlasses.minX,
            Direction.LEFT,
          );

          newAnchorPoint = findCrossPoint(lineTop, lineLeft);
        } else {
          const minPointX =
            utmostPointsWithNiche.minX[0] > utmostPointsOnlyGlasses.minX[0]
              ? utmostPointsWithNiche.minX
              : utmostPointsOnlyGlasses.minX;
          const newAnchorPointY = findYPointOnLine(lineTop, minPointX[0]);
          newAnchorPoint = [minPointX[0], newAnchorPointY];
        }
      }
      if (id === 'center-left') {
        const nicheCCorner = nicheCorners['center']!;
        const nicheTLCorner = nicheCorners['top-left']!;

        const lineBottom = getMoreDistantLineFromVectors(
          nicheCCorner,
          utmostPointsWithNiche.maxY,
          utmostPointsOnlyGlasses.maxY,
          Direction.BOTTOM,
        );

        if (
          nicheTLCorner[0] !== utmostPointsWithNiche.minX[0] &&
          nicheTLCorner[0] !== utmostPointsOnlyGlasses.minX[0]
        ) {
          const lineLeft = getMoreDistantLineFromVectors(
            nicheTLCorner,
            utmostPointsWithNiche.minX,
            utmostPointsOnlyGlasses.minX,
            Direction.LEFT,
          );

          newAnchorPoint = findCrossPoint(lineBottom, lineLeft);
        } else {
          const minPointX =
            utmostPointsWithNiche.minX[0] > utmostPointsOnlyGlasses.minX[0]
              ? utmostPointsWithNiche.minX
              : utmostPointsOnlyGlasses.minX;
          const newAnchorPointY = findYPointOnLine(lineBottom, minPointX[0]);
          newAnchorPoint = [minPointX[0], newAnchorPointY];
        }
      }
      if (id === 'bottom-left') {
        const nicheBRCorner = nicheCorners['bottom-right'];
        const nicheCCorner = nicheCorners['center']!;

        const lineBottom = getMoreDistantLineFromVectors(
          nicheBRCorner,
          utmostPointsWithNiche.maxY,
          utmostPointsOnlyGlasses.maxY,
          Direction.BOTTOM,
        );

        if (
          nicheCCorner[0] !== utmostPointsWithNiche.minX[0] &&
          nicheCCorner[0] !== utmostPointsOnlyGlasses.minX[0]
        ) {
          const lineLeft = getMoreDistantLineFromVectors(
            nicheCCorner,
            utmostPointsWithNiche.minX,
            utmostPointsOnlyGlasses.minX,
            Direction.LEFT,
          );

          newAnchorPoint = findCrossPoint(lineBottom, lineLeft);
        } else {
          const minPointX =
            utmostPointsWithNiche.minX[0] > utmostPointsOnlyGlasses.minX[0]
              ? utmostPointsWithNiche.minX
              : utmostPointsOnlyGlasses.minX;
          const newAnchorPointY = findYPointOnLine(lineBottom, minPointX[0]);
          newAnchorPoint = [minPointX[0], newAnchorPointY];
        }
      }
    }
  }

  return getCorrectedVector(
    scaledVector,
    { x: newAnchorPoint[0], y: newAnchorPoint[1] },
    vector,
    data.scale,
  );
};

export const removeCollisionForCorner = (
  id: ANCHOR_POINTS,
  data: DataCopy,
  scaledVector: Vector2d,
  vector: Vector2d,
  outCorner: string,
) => {
  const outCornerName = outCorner as RectCornersType;
  const glass = convertToGlass(data.shape);
  const { parent } = getParentPolygon(data.parents[0]);

  if (outCornerName === id) {
    return handleDraggedCornerCollision(
      glass,
      id,
      parent,
      outCornerName,
      scaledVector,
      vector,
      data.scale,
    );
  } else if (
    id === 'top' ||
    id === 'bottom' ||
    id === 'left' ||
    id === 'right'
  ) {
    return handleDraggedEdgeCollision(
      glass,
      id,
      parent,
      outCornerName,
      scaledVector,
      vector,
      data.scale,
    );
  } else {
    return handleOtherCornerCollision(
      glass,
      id as ANCHOR_POINTS.TOP_LEFT,
      parent,
      outCornerName,
      scaledVector,
      vector,
      data.scale,
    );
  }
};

// cosine for angle between AB and AC
export const getAngleCosine = (PointA: Point, PointB: Point, PointC: Point) => {
  const ABLength = getVectorLength(PointA, PointB);
  const ACLength = getVectorLength(PointA, PointC);

  const dotProductABAC = dotProduct(PointA, PointB, PointA, PointC);

  const cosine = dotProductABAC / (ABLength * ACLength);

  return cosine;
};

const getCoefficientAFromDegrees = (angle: number) => {
  return Math.tan((angle * Math.PI) / 180);
};

const getLineAngleOfInclination = (line: Line) => {
  return (Math.atan(line.a) * 180) / Math.PI;
};

export const validateIndentAngle = (
  nicheCorners: Corners,
  indentPosition: IndentPosition,
  newAngle: number,
  anchorId: ANCHOR_POINTS,
  scale: number,
  scaledVector: Vector2d,
  vector: Vector2d,
) => {
  let indentNotMovedEdge;
  let outerEdge = { a: 0, b: 0 };
  let angleForNewEdge = 0;
  let newAnchorPoint = [0, 0];
  let newEdge = { a: 0, b: 0 };
  let newMovedEdgeA = 0;
  let newMovedEdgeB = 0;
  let indentNotMovedEdgeDegree = 0;

  const RIGHT_ANGLE = 90;

  if (indentPosition === IndentPosition.TOP_LEFT) {
    if (anchorId === ANCHOR_POINTS.TOP_LEFT) {
      indentNotMovedEdge = findLine(
        nicheCorners['center-left']!,
        nicheCorners['center']!,
      );

      outerEdge = findLine(nicheCorners['top-left'], nicheCorners['top-right']);

      indentNotMovedEdgeDegree = getLineAngleOfInclination(indentNotMovedEdge);

      angleForNewEdge = indentNotMovedEdgeDegree + newAngle;
    }
    if (anchorId === ANCHOR_POINTS.CENTER_LEFT) {
      if (nicheCorners['top-left'][0] !== nicheCorners['center']![0]) {
        indentNotMovedEdge = findLine(
          nicheCorners['top-left']!,
          nicheCorners['center']!,
        );

        indentNotMovedEdgeDegree = getLineAngleOfInclination(
          indentNotMovedEdge,
        );
      } else {
        indentNotMovedEdgeDegree = RIGHT_ANGLE;
      }

      outerEdge = findLine(
        nicheCorners['center-left']!,
        nicheCorners['bottom-left'],
      );

      angleForNewEdge = indentNotMovedEdgeDegree - newAngle;
    }
  }

  if (indentPosition === IndentPosition.TOP_RIGHT) {
    if (anchorId === ANCHOR_POINTS.TOP_RIGHT) {
      indentNotMovedEdge = findLine(
        nicheCorners['center-right']!,
        nicheCorners['center']!,
      );

      outerEdge = findLine(nicheCorners['top-left'], nicheCorners['top-right']);

      indentNotMovedEdgeDegree = -getLineAngleOfInclination(indentNotMovedEdge);

      angleForNewEdge = -indentNotMovedEdgeDegree - newAngle;
    }

    if (anchorId === ANCHOR_POINTS.CENTER_RIGHT) {
      if (nicheCorners['top-right'][0] !== nicheCorners['center']![0]) {
        indentNotMovedEdge = findLine(
          nicheCorners['top-right']!,
          nicheCorners['center']!,
        );

        indentNotMovedEdgeDegree = getLineAngleOfInclination(
          indentNotMovedEdge,
        );
      } else {
        indentNotMovedEdgeDegree = -RIGHT_ANGLE;
      }

      outerEdge = findLine(
        nicheCorners['center-right']!,
        nicheCorners['bottom-right'],
      );

      angleForNewEdge = indentNotMovedEdgeDegree + newAngle;
    }
  }

  if (indentPosition === IndentPosition.BOTTOM_LEFT) {
    if (anchorId === ANCHOR_POINTS.BOTTOM_LEFT) {
      indentNotMovedEdge = findLine(
        nicheCorners['center-left']!,
        nicheCorners['center']!,
      );

      outerEdge = findLine(
        nicheCorners['bottom-left'],
        nicheCorners['bottom-right'],
      );

      indentNotMovedEdgeDegree = getLineAngleOfInclination(indentNotMovedEdge);

      angleForNewEdge = indentNotMovedEdgeDegree - newAngle;
    }

    if (anchorId === ANCHOR_POINTS.CENTER_LEFT) {
      if (nicheCorners['bottom-left'][0] !== nicheCorners['center']![0]) {
        indentNotMovedEdge = findLine(
          nicheCorners['bottom-left']!,
          nicheCorners['center']!,
        );

        indentNotMovedEdgeDegree = getLineAngleOfInclination(
          indentNotMovedEdge,
        );
      } else {
        indentNotMovedEdgeDegree = -RIGHT_ANGLE;
      }

      outerEdge = findLine(
        nicheCorners['center-left']!,
        nicheCorners['top-left'],
      );

      angleForNewEdge = indentNotMovedEdgeDegree + newAngle;
    }
  }

  if (indentPosition === IndentPosition.BOTTOM_RIGHT) {
    if (anchorId === ANCHOR_POINTS.BOTTOM_RIGHT) {
      indentNotMovedEdge = findLine(
        nicheCorners['center-right']!,
        nicheCorners['center']!,
      );

      outerEdge = findLine(
        nicheCorners['bottom-left'],
        nicheCorners['bottom-right'],
      );

      indentNotMovedEdgeDegree = -getLineAngleOfInclination(indentNotMovedEdge);

      angleForNewEdge = -indentNotMovedEdgeDegree + newAngle;
    }

    if (anchorId === ANCHOR_POINTS.CENTER_RIGHT) {
      if (nicheCorners['bottom-right'][0] !== nicheCorners['center']![0]) {
        indentNotMovedEdge = findLine(
          nicheCorners['bottom-right']!,
          nicheCorners['center']!,
        );

        indentNotMovedEdgeDegree = getLineAngleOfInclination(
          indentNotMovedEdge,
        );
      } else {
        indentNotMovedEdgeDegree = -RIGHT_ANGLE;
      }

      outerEdge = findLine(
        nicheCorners['center-right']!,
        nicheCorners['top-right'],
      );

      angleForNewEdge = indentNotMovedEdgeDegree - newAngle;
    }
  }

  newMovedEdgeA = getCoefficientAFromDegrees(angleForNewEdge);

  newMovedEdgeB = Number(findBForA(nicheCorners['center']!, newMovedEdgeA));
  newEdge = { a: newMovedEdgeA, b: newMovedEdgeB };

  newAnchorPoint = findCrossPoint(outerEdge, newEdge);

  return getCorrectedVector(
    scaledVector,
    { x: newAnchorPoint[0], y: newAnchorPoint[1] },
    vector,
    scale,
  );
};

const getCorrectPointForIndent = (
  points: {
    point1: number[];
    point2: number[];
  },
  correctingVectorDirection: CorrectingVectorDirection,
) => {
  const { point1, point2 } = points;

  if (correctingVectorDirection === CorrectingVectorDirection.TOP) {
    return point1[1] > point2[1] ? point2 : point1;
  }
  if (correctingVectorDirection === CorrectingVectorDirection.BOTTOM) {
    return point1[1] > point2[1] ? point1 : point2;
  }
  if (correctingVectorDirection === CorrectingVectorDirection.LEFT) {
    return point1[0] > point2[0] ? point2 : point1;
  }
  if (correctingVectorDirection === CorrectingVectorDirection.RIGHT) {
    return point1[0] > point2[0] ? point1 : point2;
  }
};

export enum CorrectingVectorDirection {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
}

const getCorrectVectorDirection = (
  indentPosition: IndentPosition,
  anchorId: ANCHOR_POINTS,
) => {
  if (
    indentPosition === IndentPosition.TOP_LEFT ||
    indentPosition === IndentPosition.TOP_RIGHT
  ) {
    if (
      anchorId === ANCHOR_POINTS.TOP_LEFT ||
      anchorId === ANCHOR_POINTS.TOP_RIGHT ||
      anchorId === ANCHOR_POINTS.TOP
    ) {
      return CorrectingVectorDirection.TOP;
    }
  }
  if (
    indentPosition === IndentPosition.BOTTOM_LEFT ||
    indentPosition === IndentPosition.BOTTOM_RIGHT
  ) {
    if (
      anchorId === ANCHOR_POINTS.BOTTOM_LEFT ||
      anchorId === ANCHOR_POINTS.BOTTOM_RIGHT ||
      anchorId === ANCHOR_POINTS.BOTTOM
    ) {
      return CorrectingVectorDirection.BOTTOM;
    }
  }
  if (indentPosition === IndentPosition.TOP_LEFT) {
    if (
      anchorId === ANCHOR_POINTS.CENTER_LEFT ||
      anchorId === ANCHOR_POINTS.LEFT ||
      anchorId === ANCHOR_POINTS.BOTTOM_LEFT
    ) {
      return CorrectingVectorDirection.LEFT;
    }
  }
  if (indentPosition === IndentPosition.BOTTOM_LEFT) {
    if (
      anchorId === ANCHOR_POINTS.CENTER_LEFT ||
      anchorId === ANCHOR_POINTS.LEFT ||
      anchorId === ANCHOR_POINTS.TOP_LEFT
    ) {
      return CorrectingVectorDirection.LEFT;
    }
  }
  if (indentPosition === IndentPosition.TOP_RIGHT) {
    if (
      anchorId === ANCHOR_POINTS.CENTER_RIGHT ||
      anchorId === ANCHOR_POINTS.RIGHT ||
      anchorId === ANCHOR_POINTS.BOTTOM_RIGHT
    ) {
      return CorrectingVectorDirection.RIGHT;
    }
  }
  if (indentPosition === IndentPosition.BOTTOM_RIGHT) {
    if (
      anchorId === ANCHOR_POINTS.CENTER_RIGHT ||
      anchorId === ANCHOR_POINTS.RIGHT ||
      anchorId === ANCHOR_POINTS.TOP_RIGHT
    ) {
      return CorrectingVectorDirection.RIGHT;
    }
  }
};

// returns two points from moved edge that does not change the indent position
const getMinLShapedNicheCorners = (
  center: [number, number],
  movedEdgeInner: [number, number],
  movedEdgeOuter: [number, number],
  notMovedOuterEdge: [number, number],
  correctingVectorDirection: CorrectingVectorDirection,
) => {
  const MIN_GLASS_EDGE_LENGTH = 100;
  let newInnerCorner;
  let newOuterCorner;
  let points;

  // newInnerPoint
  if (center[0] !== movedEdgeInner[0]) {
    const lineCenterInnerMoved = findLine(center, movedEdgeInner);
    points = findPointOnLineInDistanceFromOtherPoint(
      lineCenterInnerMoved,
      center,
      MIN_GLASS_EDGE_LENGTH,
    );

    newInnerCorner = getCorrectPointForIndent(
      points,
      correctingVectorDirection,
    );
  } else {
    if (correctingVectorDirection === CorrectingVectorDirection.TOP) {
      newInnerCorner = [center[0], center[1] - 100];
    } else {
      newInnerCorner = [center[0], center[1] + 100];
    }
  }

  // newOuterPoint
  if (movedEdgeInner[0] !== movedEdgeOuter[0]) {
    const lineMovedInnerInnerNotMoved = findLine(
      movedEdgeInner,
      movedEdgeOuter,
    );
    const lineMovedInnerInnerNotMovedPararell = findParallelLine(
      newInnerCorner as [number, number],
      lineMovedInnerInnerNotMoved,
    );

    if (movedEdgeOuter[0] !== notMovedOuterEdge[0]) {
      const lineInnerNotMovedOuterNotMoved = findLine(
        notMovedOuterEdge,
        movedEdgeOuter,
      );
      newOuterCorner = findCrossPoint(
        lineMovedInnerInnerNotMovedPararell,
        lineInnerNotMovedOuterNotMoved,
      );
    } else {
      const newOuterPointY = findYPointOnLine(
        lineMovedInnerInnerNotMovedPararell,
        movedEdgeOuter[0],
      );

      newOuterCorner = [movedEdgeOuter[0], newOuterPointY];
    }
  } else {
    const lineInnerNotMovedOuterNotMoved = findLine(
      notMovedOuterEdge,
      movedEdgeOuter,
    );

    const newOuterPointY = findYPointOnLine(
      lineInnerNotMovedOuterNotMoved,
      newInnerCorner![0],
    );

    newOuterCorner = [newInnerCorner![0], newOuterPointY];
  }

  return { newInnerCorner, newOuterCorner };
};

type NewCornersForLShapedniche = {
  newInnerCorner: [number, number];
  newOuterCorner: [number, number];
};

const getCorrectingVectorForAnchor = (
  newCorners: NewCornersForLShapedniche,
  indentPosition: IndentPosition,
  anchorId: ANCHOR_POINTS,
  scale: number,
  scaledVector: Vector2d,
  vector: Vector2d,
) => {
  let newAnchorPosition = [0, 0];
  if (indentPosition === IndentPosition.TOP_LEFT) {
    if (
      anchorId === ANCHOR_POINTS.TOP_LEFT ||
      anchorId === ANCHOR_POINTS.CENTER_LEFT
    ) {
      newAnchorPosition = newCorners.newInnerCorner;
    }
    if (
      anchorId === ANCHOR_POINTS.TOP_RIGHT ||
      anchorId === ANCHOR_POINTS.BOTTOM_LEFT
    ) {
      newAnchorPosition = newCorners.newOuterCorner;
    }
    if (anchorId === ANCHOR_POINTS.TOP || anchorId === ANCHOR_POINTS.LEFT) {
      newAnchorPosition = calculateEdgeAnchor(
        newCorners.newInnerCorner,
        newCorners.newOuterCorner,
      );
    }
  }
  if (indentPosition === IndentPosition.TOP_RIGHT) {
    if (
      anchorId === ANCHOR_POINTS.TOP_LEFT ||
      anchorId === ANCHOR_POINTS.CENTER_LEFT
    ) {
      newAnchorPosition = newCorners.newOuterCorner;
    }
    if (
      anchorId === ANCHOR_POINTS.TOP_RIGHT ||
      anchorId === ANCHOR_POINTS.BOTTOM_LEFT
    ) {
      newAnchorPosition = newCorners.newInnerCorner;
    }
    if (anchorId === ANCHOR_POINTS.TOP || anchorId === ANCHOR_POINTS.RIGHT) {
      newAnchorPosition = calculateEdgeAnchor(
        newCorners.newInnerCorner,
        newCorners.newOuterCorner,
      );
    }
  }
  if (indentPosition === IndentPosition.BOTTOM_LEFT) {
    if (
      anchorId === ANCHOR_POINTS.BOTTOM_LEFT ||
      anchorId === ANCHOR_POINTS.CENTER_LEFT
    ) {
      newAnchorPosition = newCorners.newInnerCorner;
    }
    if (
      anchorId === ANCHOR_POINTS.BOTTOM_RIGHT ||
      anchorId === ANCHOR_POINTS.TOP_LEFT
    ) {
      newAnchorPosition = newCorners.newOuterCorner;
    }
    if (anchorId === ANCHOR_POINTS.BOTTOM || anchorId === ANCHOR_POINTS.LEFT) {
      newAnchorPosition = calculateEdgeAnchor(
        newCorners.newInnerCorner,
        newCorners.newOuterCorner,
      );
    }
  }
  if (indentPosition === IndentPosition.BOTTOM_RIGHT) {
    if (
      anchorId === ANCHOR_POINTS.BOTTOM_RIGHT ||
      anchorId === ANCHOR_POINTS.CENTER_RIGHT
    ) {
      newAnchorPosition = newCorners.newInnerCorner;
    }
    if (
      anchorId === ANCHOR_POINTS.BOTTOM_RIGHT ||
      anchorId === ANCHOR_POINTS.TOP_RIGHT
    ) {
      newAnchorPosition = newCorners.newOuterCorner;
    }
    if (anchorId === ANCHOR_POINTS.BOTTOM || anchorId === ANCHOR_POINTS.RIGHT) {
      newAnchorPosition = calculateEdgeAnchor(
        newCorners.newInnerCorner,
        newCorners.newOuterCorner,
      );
    }
  }

  return getCorrectedVector(
    scaledVector,
    toVector(newAnchorPosition),
    vector,
    scale,
  );
};

export const validateIndentPosition = (
  nicheCorners: Corners,
  indentPosition: IndentPosition,
  anchorId: ANCHOR_POINTS,
  scale: number,
  scaledVector: Vector2d,
  vector: Vector2d,
) => {
  let newCorners;
  const correctingVectorDirection = getCorrectVectorDirection(
    indentPosition,
    anchorId,
  );

  if (indentPosition === IndentPosition.TOP_LEFT) {
    if (
      anchorId === ANCHOR_POINTS.TOP_LEFT ||
      anchorId === ANCHOR_POINTS.TOP ||
      anchorId === ANCHOR_POINTS.TOP_RIGHT
    ) {
      newCorners = getMinLShapedNicheCorners(
        nicheCorners['center']!,
        nicheCorners['top-left'],
        nicheCorners['top-right'],
        nicheCorners['bottom-right'],
        correctingVectorDirection!,
      );
    }
    if (
      anchorId === ANCHOR_POINTS.CENTER_LEFT ||
      anchorId === ANCHOR_POINTS.LEFT ||
      anchorId === ANCHOR_POINTS.BOTTOM_LEFT
    ) {
      newCorners = getMinLShapedNicheCorners(
        nicheCorners['center']!,
        nicheCorners['center-left']!,
        nicheCorners['bottom-left'],
        nicheCorners['bottom-right'],
        correctingVectorDirection!,
      );
    }
  }
  if (indentPosition === IndentPosition.TOP_RIGHT) {
    if (
      anchorId === ANCHOR_POINTS.TOP_LEFT ||
      anchorId === ANCHOR_POINTS.TOP ||
      anchorId === ANCHOR_POINTS.TOP_RIGHT
    ) {
      newCorners = getMinLShapedNicheCorners(
        nicheCorners['center']!,
        nicheCorners['top-right'],
        nicheCorners['top-left'],
        nicheCorners['bottom-left'],
        correctingVectorDirection!,
      );
    }
    if (
      anchorId === ANCHOR_POINTS.CENTER_RIGHT ||
      anchorId === ANCHOR_POINTS.RIGHT ||
      anchorId === ANCHOR_POINTS.BOTTOM_RIGHT
    ) {
      newCorners = getMinLShapedNicheCorners(
        nicheCorners['center']!,
        nicheCorners['center-right']!,
        nicheCorners['bottom-right'],
        nicheCorners['bottom-left'],
        correctingVectorDirection!,
      );
    }
  }
  if (indentPosition === IndentPosition.BOTTOM_RIGHT) {
    if (
      anchorId === ANCHOR_POINTS.BOTTOM_LEFT ||
      anchorId === ANCHOR_POINTS.BOTTOM ||
      anchorId === ANCHOR_POINTS.BOTTOM_RIGHT
    ) {
      newCorners = getMinLShapedNicheCorners(
        nicheCorners['center']!,
        nicheCorners['bottom-right'],
        nicheCorners['bottom-left'],
        nicheCorners['top-left'],
        correctingVectorDirection!,
      );
    }
    if (
      anchorId === ANCHOR_POINTS.CENTER_RIGHT ||
      anchorId === ANCHOR_POINTS.RIGHT ||
      anchorId === ANCHOR_POINTS.TOP_RIGHT
    ) {
      newCorners = getMinLShapedNicheCorners(
        nicheCorners['center']!,
        nicheCorners['center-right']!,
        nicheCorners['top-right'],
        nicheCorners['top-left'],
        correctingVectorDirection!,
      );
    }
  }
  if (indentPosition === IndentPosition.BOTTOM_LEFT) {
    if (
      anchorId === ANCHOR_POINTS.BOTTOM_LEFT ||
      anchorId === ANCHOR_POINTS.BOTTOM ||
      anchorId === ANCHOR_POINTS.BOTTOM_RIGHT
    ) {
      newCorners = getMinLShapedNicheCorners(
        nicheCorners['center']!,
        nicheCorners['bottom-left'],
        nicheCorners['bottom-right'],
        nicheCorners['top-right'],
        correctingVectorDirection!,
      );
    }
    if (
      anchorId === ANCHOR_POINTS.CENTER_LEFT ||
      anchorId === ANCHOR_POINTS.LEFT ||
      anchorId === ANCHOR_POINTS.TOP_LEFT
    ) {
      newCorners = getMinLShapedNicheCorners(
        nicheCorners['center']!,
        nicheCorners['center-left']!,
        nicheCorners['top-left'],
        nicheCorners['top-right'],
        correctingVectorDirection!,
      );
    }
  }

  return getCorrectingVectorForAnchor(
    newCorners as NewCornersForLShapedniche,
    indentPosition,
    anchorId,
    scale,
    scaledVector,
    vector,
  );
};

const getBaseCorner = (edge: EdgeType, direction: EdgeType) => {
  const anchorPointMap = {
    'bottom-left': ANCHOR_POINTS.TOP_LEFT,
    'bottom-right': ANCHOR_POINTS.TOP_RIGHT,
    'top-left': ANCHOR_POINTS.BOTTOM_LEFT,
    'top-right': ANCHOR_POINTS.BOTTOM_RIGHT,
    'right-top': ANCHOR_POINTS.TOP_LEFT,
    'right-bottom': ANCHOR_POINTS.BOTTOM_LEFT,
    'left-top': ANCHOR_POINTS.TOP_RIGHT,
    'left-bottom': ANCHOR_POINTS.BOTTOM_RIGHT,
  };

  return anchorPointMap[`${edge}-${direction}` as 'bottom-left'];
};

const getDefaultLineAngle = (
  corners: ProjectDimensions['corners'],
  baseCornerKey: RectCornersType,
  oppositeCornerKey: RectCornersType,
  edge: EdgeType,
  direction: EdgeType,
) => {
  const baseCorner = corners[baseCornerKey];
  const oppositeCorner = corners[oppositeCornerKey];

  let cornerModification = { x: 0, y: 0 };

  if (edge === 'top' || edge === 'bottom') {
    cornerModification = {
      ...cornerModification,
      x: direction === 'left' ? -200 : 200,
    };
  } else {
    cornerModification = {
      ...cornerModification,
      y: direction === 'top' ? -200 : 200,
    };
  }

  return findAngle(toVector(baseCorner), toVector(oppositeCorner), {
    x: oppositeCorner[0] + cornerModification.x,
    y: oppositeCorner[1] + cornerModification.y,
  });
};

const getActualLineAngle = (
  corners: ProjectDimensions['corners'],
  baseCornerKey: RectCornersType,
  oppositeCornerKey: RectCornersType,
  adjacentCornerKey: RectCornersType,
) => {
  return findAngle(
    toVector(corners[baseCornerKey]),
    toVector(corners[oppositeCornerKey]),
    toVector(corners[adjacentCornerKey]),
  );
};

export const calculateMaxEdgeLength = (
  corners: ProjectDimensions['corners'],
  edge: EdgeType,
  direction: EdgeType,
) => {
  const edges = mapCoordinatesToEdges({ corners });

  const baseCornerKey = getBaseCorner(edge, direction);
  const oppositeCornerKey = getOppositeCorner(baseCornerKey);
  const adjacentCornerKey = getAdjacentCornerForEdge(
    getOppositeCorner(baseCornerKey),
    edge as ANCHOR_POINTS.TOP,
  );

  if (!adjacentCornerKey) {
    return 0;
  }

  const defaultLineAngle = getDefaultLineAngle(
    corners,
    baseCornerKey as RectCornersType,
    oppositeCornerKey,
    edge,
    direction,
  );
  const actualLineAngle = getActualLineAngle(
    corners,
    baseCornerKey as RectCornersType,
    oppositeCornerKey,
    adjacentCornerKey,
  );

  const alfaPrim = 180 - MAX_CORNER_ANGLE - actualLineAngle;

  const lineTan = Math.tan(
    ((180 - alfaPrim - defaultLineAngle) * Math.PI) / 180,
  );

  const tangent = [
    'bottom-left',
    'top-right',
    'right-top',
    'left-bottom',
  ].includes(`${edge}-${direction}`)
    ? -lineTan
    : lineTan;

  const maxPoint = findCrossPointToLine(edges[edge], {
    a: tangent,
    b: Number(findBForA(corners[baseCornerKey as RectCornersType], tangent)),
  });

  return getVectorLength(
    toVector(corners[oppositeCornerKey]),
    toVector(maxPoint),
  );
};

const splitIndentToRect = (
  indent: IndentPosition,
  corners: IndentCorners,
  edge: IndentEdgeType,
  direction: EdgeType,
): Record<EdgeType, RectCorners | undefined> => {
  let rectCorners: Record<EdgeType, RectCorners | undefined> = {
    left: undefined,
    top: undefined,
    right: undefined,
    bottom: undefined,
  };

  switch (indent) {
    case IndentPosition.TOP_RIGHT: {
      rectCorners = {
        ...rectCorners,
        left: {
          'top-left': corners[ANCHOR_POINTS.TOP_LEFT],
          'top-right': corners[ANCHOR_POINTS.TOP_RIGHT],
          'bottom-left': corners[ANCHOR_POINTS.BOTTOM_LEFT],
          'bottom-right':
            edge === 'left' && direction === 'bottom'
              ? corners[ANCHOR_POINTS.BOTTOM_RIGHT]
              : corners[ANCHOR_POINTS.CENTER],
        },
        bottom: {
          'top-left':
            edge === 'bottom' && direction === 'left'
              ? corners[ANCHOR_POINTS.TOP_LEFT]
              : corners[ANCHOR_POINTS.CENTER],
          'top-right': corners[ANCHOR_POINTS.CENTER_RIGHT] ?? [0, 0],
          'bottom-left': corners[ANCHOR_POINTS.BOTTOM_LEFT],
          'bottom-right': corners[ANCHOR_POINTS.BOTTOM_RIGHT],
        },
      };
      break;
    }
    case IndentPosition.TOP_LEFT: {
      rectCorners = {
        ...rectCorners,
        right: {
          'top-left': corners[ANCHOR_POINTS.TOP_LEFT],
          'top-right': corners[ANCHOR_POINTS.TOP_RIGHT],
          'bottom-left':
            edge === 'right' && direction === 'bottom'
              ? corners[ANCHOR_POINTS.BOTTOM_LEFT]
              : corners[ANCHOR_POINTS.CENTER],
          'bottom-right': corners[ANCHOR_POINTS.BOTTOM_RIGHT],
        },
        bottom: {
          'top-left': corners[ANCHOR_POINTS.CENTER_LEFT] ?? [0, 0],
          'top-right':
            edge === 'bottom' && direction === 'right'
              ? corners[ANCHOR_POINTS.TOP_RIGHT]
              : corners[ANCHOR_POINTS.CENTER],
          'bottom-left': corners[ANCHOR_POINTS.BOTTOM_LEFT],
          'bottom-right': corners[ANCHOR_POINTS.BOTTOM_RIGHT],
        },
      };
      break;
    }
    case IndentPosition.BOTTOM_LEFT: {
      rectCorners = {
        ...rectCorners,
        right: {
          'top-left':
            edge === 'right' && direction === 'top'
              ? corners[ANCHOR_POINTS.TOP_LEFT]
              : corners[ANCHOR_POINTS.CENTER],
          'top-right': corners[ANCHOR_POINTS.TOP_RIGHT],
          'bottom-left': corners[ANCHOR_POINTS.BOTTOM_LEFT],
          'bottom-right': corners[ANCHOR_POINTS.BOTTOM_RIGHT],
        },
        top: {
          'top-left': corners[ANCHOR_POINTS.TOP_LEFT],
          'top-right': corners[ANCHOR_POINTS.TOP_RIGHT],
          'bottom-left': corners[ANCHOR_POINTS.CENTER_LEFT] ?? [0, 0],
          'bottom-right':
            edge === 'top' && direction === 'right'
              ? corners[ANCHOR_POINTS.BOTTOM_RIGHT]
              : corners[ANCHOR_POINTS.CENTER],
        },
      };
      break;
    }
    case IndentPosition.BOTTOM_RIGHT: {
      rectCorners = {
        ...rectCorners,
        left: {
          'top-left': corners[ANCHOR_POINTS.TOP_LEFT],
          'top-right':
            edge === 'left' && direction === 'top'
              ? corners[ANCHOR_POINTS.TOP_RIGHT]
              : corners[ANCHOR_POINTS.CENTER],
          'bottom-left': corners[ANCHOR_POINTS.BOTTOM_LEFT],
          'bottom-right': corners[ANCHOR_POINTS.BOTTOM_RIGHT],
        },
        top: {
          'top-left': corners[ANCHOR_POINTS.TOP_LEFT],
          'top-right': corners[ANCHOR_POINTS.TOP_RIGHT],
          'bottom-left':
            edge === 'top' && direction === 'left'
              ? corners[ANCHOR_POINTS.BOTTOM_LEFT]
              : corners[ANCHOR_POINTS.CENTER],
          'bottom-right': corners[ANCHOR_POINTS.CENTER_RIGHT] ?? [0, 0],
        },
      };
      break;
    }
  }

  return rectCorners;
};

export const calculateMaxEdgeLengthIndent = (
  dimensions: ProjectDimensions,
  edge: IndentEdgeType,
  direction: EdgeType,
) => {
  const rectCorners = splitIndentToRect(
    dimensions.indent as IndentPosition,
    dimensions.corners as IndentCorners,
    edge,
    direction,
  );

  switch (dimensions.indent) {
    case IndentPosition.TOP_RIGHT: {
      if ((edge === 'left' || edge === 'top') && rectCorners['left']) {
        return calculateMaxEdgeLength(rectCorners['left'], edge, direction);
      }

      if (edge === 'center-vertical' && rectCorners['left']) {
        return calculateMaxEdgeLength(rectCorners['left'], 'right', direction);
      }

      if ((edge === 'right' || edge === 'bottom') && rectCorners['bottom']) {
        return calculateMaxEdgeLength(rectCorners['bottom'], edge, direction);
      }

      if (edge === 'center-horizontal' && rectCorners['bottom']) {
        return calculateMaxEdgeLength(rectCorners['bottom'], 'top', direction);
      }
      break;
    }
    case IndentPosition.TOP_LEFT: {
      if ((edge === 'right' || edge === 'top') && rectCorners['right']) {
        return calculateMaxEdgeLength(rectCorners['right'], edge, direction);
      }

      if (edge === 'center-vertical' && rectCorners['right']) {
        return calculateMaxEdgeLength(rectCorners['right'], 'left', direction);
      }

      if ((edge === 'left' || edge === 'bottom') && rectCorners['bottom']) {
        return calculateMaxEdgeLength(rectCorners['bottom'], edge, direction);
      }

      if (edge === 'center-horizontal' && rectCorners['bottom']) {
        return calculateMaxEdgeLength(rectCorners['bottom'], 'top', direction);
      }
      break;
    }
    case IndentPosition.BOTTOM_LEFT: {
      if ((edge === 'right' || edge === 'bottom') && rectCorners['right']) {
        return calculateMaxEdgeLength(rectCorners['right'], edge, direction);
      }

      if (edge === 'center-vertical' && rectCorners['right']) {
        return calculateMaxEdgeLength(rectCorners['right'], 'left', direction);
      }

      if ((edge === 'left' || edge === 'top') && rectCorners['top']) {
        return calculateMaxEdgeLength(rectCorners['top'], edge, direction);
      }

      if (edge === 'center-horizontal' && rectCorners['top']) {
        return calculateMaxEdgeLength(rectCorners['top'], 'bottom', direction);
      }
      break;
    }
    case IndentPosition.BOTTOM_RIGHT: {
      if ((edge === 'left' || edge === 'bottom') && rectCorners['left']) {
        return calculateMaxEdgeLength(rectCorners['left'], edge, direction);
      }

      if (edge === 'center-vertical' && rectCorners['left']) {
        return calculateMaxEdgeLength(rectCorners['left'], 'right', direction);
      }

      if ((edge === 'right' || edge === 'top') && rectCorners['top']) {
        return calculateMaxEdgeLength(rectCorners['top'], edge, direction);
      }

      if (edge === 'center-horizontal' && rectCorners['top']) {
        return calculateMaxEdgeLength(rectCorners['top'], 'bottom', direction);
      }
      break;
    }
  }

  return 5000;
};
