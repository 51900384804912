import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
  DimensionsInputs,
  HorizontalPositioning,
  VerticalPositioning,
} from '../../components/DimensionsInputs';
import { Shape } from '../../../../../../modules/space';
import {
  ProjectData,
  ProjectDimensions,
} from '../../../../../../services/api/models/project';
import { Position } from '../../../../../../types';
import { changeShapeEdge } from '../../../../../../utils/shape';
import { BoundariesCopy } from '../../../../../../modules/creator/types';
import { DEFAULT_RATIO } from '../../../../../../modules/creator/store/config';
import Big from 'big.js';
import { fixValue } from '../../../../../../modules/creator/utils/fix';

interface NicheDimensionsProps {
  projectData: ProjectData;
  innerBoundaries: BoundariesCopy | undefined;
  onUpdateDimensions: (
    dimensions: ProjectDimensions,
    position?: Partial<Position>,
    history?: boolean,
  ) => void;
}

const NicheDimensions = ({
  projectData,
  innerBoundaries,
  onUpdateDimensions,
}: NicheDimensionsProps) => {
  const [hPositioning, setHPositioning] = useState(HorizontalPositioning.LEFT);
  const [vPositioning, setVPositioning] = useState(VerticalPositioning.TOP);

  const projectShape = useMemo(
    () => new Shape(projectData.dimensions, projectData.position),
    [projectData.dimensions, projectData.position],
  );

  const extremeBoundaries = useMemo(
    () => ({
      top: innerBoundaries?.top.sort((a, b) => b.y - a.y)[0],
      bottom: innerBoundaries?.bottom.sort((a, b) => b.y - a.y)[0],
      right: innerBoundaries?.right.sort((a, b) => b.x - a.x)[0],
      left: innerBoundaries?.left.sort((a, b) => b.x - a.x)[0],
    }),
    [innerBoundaries],
  );
  //TODO: Restore functionality
  const minWidth = useMemo(() => {
    let value;
    // The min value sometimes was 1, it's too small, and was causing the page to loop,
    // so if the value should be at least 5 (the smallest when the logic works properly)
    const defaultValue = 5;

    if (!extremeBoundaries.left || !extremeBoundaries.right) {
      return defaultValue;
    }

    switch (hPositioning) {
      case HorizontalPositioning.RIGHT: {
        value = fixValue(
          extremeBoundaries.right.x - projectShape.extremePoints.left.point.x,
        );
        break;
      }
      case HorizontalPositioning.LEFT: {
        value = fixValue(
          projectShape.extremePoints.right.point.x - extremeBoundaries.left.x,
        );
        break;
      }
      case HorizontalPositioning.MIDDLE:
        {
          const gapToLeft = fixValue(
            extremeBoundaries.left.x - projectShape.extremePoints.left.point.x,
          );
          const gapToRight = fixValue(
            projectShape.extremePoints.right.point.x -
              extremeBoundaries.right.x,
          );

          value =
            gapToLeft > gapToRight
              ? fixValue(projectShape.width - 2 * gapToRight)
              : fixValue(projectShape.width - 2 * gapToLeft);
        }
        break;
    }
    return value;
  }, [hPositioning, extremeBoundaries, innerBoundaries]);

  //TODO: Restore functionality
  const minHeight = useMemo(() => {
    let value;
    // The min value sometimes was 1, it's too small, and was causing the page to loop,
    // so if the value should be at least 5 (the smallest when the logic works properly)
    const defaultValue = 5;

    if (!extremeBoundaries.top || !extremeBoundaries.bottom) {
      return defaultValue;
    }

    switch (vPositioning) {
      case VerticalPositioning.BOTTOM: {
        value = fixValue(
          extremeBoundaries.bottom.y - projectShape.extremePoints.top.point.y,
        );
        break;
      }
      case VerticalPositioning.TOP: {
        value = fixValue(
          projectShape.extremePoints.bottom.point.y - extremeBoundaries.top.y,
        );
        break;
      }
      case VerticalPositioning.MIDDLE: {
        const gapToTop = fixValue(
          extremeBoundaries.top.y - projectShape.extremePoints.top.point.y,
        );
        const gapToBottom = fixValue(
          projectShape.extremePoints.bottom.point.y -
            extremeBoundaries.bottom.y,
        );

        value =
          gapToTop > gapToBottom
            ? fixValue(projectShape.height - 2 * gapToBottom)
            : fixValue(projectShape.height - 2 * gapToTop);
        break;
      }
    }
    return value < defaultValue ? defaultValue : value;
  }, [vPositioning, extremeBoundaries, innerBoundaries]);

  const onWidthChange = useCallback(
    (value: string) => {
      const position = projectData.position ?? { x: 0, y: 0 };
      const templateObj = new Shape(projectData.dimensions);

      const width = new Big(parseFloat(value ? value : '0'));

      switch (hPositioning) {
        case HorizontalPositioning.RIGHT: {
          const dimensions = changeShapeEdge(
            projectData.dimensions,
            'right',
            fixValue(width),
          );

          onUpdateDimensions(dimensions, undefined, true);
          break;
        }
        case HorizontalPositioning.LEFT: {
          const dif = new Big(new Big(templateObj.width).minus(width));

          const dimensions = changeShapeEdge(
            projectData.dimensions,
            'right',
            fixValue(width),
          );

          onUpdateDimensions(
            dimensions,
            {
              x: fixValue(new Big(position.x).plus(dif), 5),
            },
            true,
          );
          break;
        }
        case HorizontalPositioning.MIDDLE: {
          const dif = new Big(new Big(templateObj.width).minus(width)).div(2);

          const dimensions = changeShapeEdge(
            projectData.dimensions,
            'right',
            fixValue(width),
          );

          onUpdateDimensions(
            dimensions,
            {
              x: fixValue(new Big(position.x).plus(dif), 5),
            },
            true,
          );
          break;
        }
      }
    },
    [hPositioning, projectData.dimensions, onUpdateDimensions],
  );

  const onHeightChange = useCallback(
    (value: string) => {
      const position = projectData.position ?? { x: 0, y: 0 };
      const templateObj = new Shape(projectData.dimensions);
      const height = new Big(value);

      switch (vPositioning) {
        case VerticalPositioning.BOTTOM: {
          const dimensions = changeShapeEdge(
            projectData.dimensions,
            'bottom',
            fixValue(height),
          );

          onUpdateDimensions(dimensions, undefined, true);
          break;
        }
        case VerticalPositioning.TOP: {
          const dif = new Big(new Big(templateObj.height).minus(height));

          const dimensions = changeShapeEdge(
            projectData.dimensions,
            'bottom',
            fixValue(height),
          );

          onUpdateDimensions(
            dimensions,
            {
              y: fixValue(new Big(position.y).plus(dif), 5),
            },
            true,
          );
          break;
        }
        case VerticalPositioning.MIDDLE: {
          const dif = new Big(new Big(templateObj.height).minus(height)).div(2);

          const dimensions = changeShapeEdge(
            projectData.dimensions,
            'bottom',
            fixValue(height),
          );

          onUpdateDimensions(
            dimensions,
            {
              y: fixValue(new Big(position.y).plus(dif), 5),
            },
            true,
          );
          break;
        }
      }
    },
    [projectData.dimensions, onUpdateDimensions, vPositioning],
  );

  return (
    <DimensionsInputs
      width={projectShape.width}
      height={projectShape.height}
      minWidth={minWidth}
      minHeight={minHeight}
      onWidthChange={onWidthChange}
      onHeightChange={onHeightChange}
      hPositioning={hPositioning}
      vPositioning={vPositioning}
      onVPositioningChange={setVPositioning}
      onHPositioningChange={setHPositioning}
      disableVChange={!projectShape.isRectangular}
      disableHChange={!projectShape.isRectangular}
    />
  );
};

NicheDimensions.displayName = 'NicheDimensions';

export default NicheDimensions;
