import { EdgeType, IndentEdgeType } from '../../../../../../types';
import * as React from 'react';
import { useMemo } from 'react';
import { EdgeChangeProps } from '../../containers/GlassForm/GlassDimensions';
import { HorizontalPositioning, VerticalPositioning } from './types';
import RectInput from './RectInput';
import { Divider, InputContainer } from './styles';
import { IndentPosition } from '../../../../../../services/api/models/project';

interface IndentInputFormProps {
  selectedId: number;
  edges: Record<IndentEdgeType, number>;
  indent: IndentPosition;
  handleChange: (props: EdgeChangeProps) => void;
  getMaxDimensions?: (
    direction: VerticalPositioning | HorizontalPositioning,
  ) => Record<EdgeType, number>;
}

const IndentInputForm = ({
  selectedId,
  edges,
  indent,
  handleChange,
  getMaxDimensions,
}: IndentInputFormProps) => {
  const disabledHorizontal = useMemo(
    () =>
      indent.includes('LEFT')
        ? [HorizontalPositioning.RIGHT, HorizontalPositioning.MIDDLE]
        : [HorizontalPositioning.LEFT, HorizontalPositioning.MIDDLE],
    [indent],
  );

  const disabledVertical = useMemo(
    () =>
      indent.includes('TOP')
        ? [VerticalPositioning.BOTTOM, VerticalPositioning.MIDDLE]
        : [VerticalPositioning.TOP, VerticalPositioning.MIDDLE],
    [indent],
  );

  return (
    <div>
      <InputContainer>
        <RectInput
          key={'top-input-row'}
          wall={'top'}
          edges={edges}
          lock={false}
          onBlur={handleChange}
          getMaxDimensions={getMaxDimensions}
          selectedId={selectedId}
        />
      </InputContainer>
      <InputContainer>
        <RectInput
          key={'bottom-input-row'}
          wall={'bottom'}
          edges={edges}
          lock={false}
          onBlur={handleChange}
          getMaxDimensions={getMaxDimensions}
          selectedId={selectedId}
        />
      </InputContainer>
      <InputContainer>
        <RectInput
          key={'center-horizontal-input-row'}
          wall={'center-horizontal'}
          onBlur={handleChange}
          edges={edges}
          lock={false}
          defaultDirection={
            indent.includes('LEFT')
              ? HorizontalPositioning.LEFT
              : HorizontalPositioning.RIGHT
          }
          getMaxDimensions={getMaxDimensions}
          disabledOptions={disabledHorizontal}
          selectedId={selectedId}
        />
      </InputContainer>
      <Divider />
      <InputContainer>
        <RectInput
          key={'left-input-row'}
          wall={'left'}
          edges={edges}
          lock={false}
          onBlur={handleChange}
          getMaxDimensions={getMaxDimensions}
          selectedId={selectedId}
        />
      </InputContainer>
      <InputContainer>
        <RectInput
          key={'right-input-row'}
          wall={'right'}
          edges={edges}
          lock={false}
          onBlur={handleChange}
          getMaxDimensions={getMaxDimensions}
          selectedId={selectedId}
        />
      </InputContainer>
      <InputContainer>
        <RectInput
          key={'center-vertical-input-row'}
          wall={'center-vertical'}
          onBlur={handleChange}
          lock={false}
          edges={edges}
          defaultDirection={
            indent.includes('TOP')
              ? VerticalPositioning.TOP
              : VerticalPositioning.BOTTOM
          }
          disabledOptions={disabledVertical}
          getMaxDimensions={getMaxDimensions}
          selectedId={selectedId}
        />
      </InputContainer>
    </div>
  );
};

IndentInputForm.displayName = 'IndentInputForm';

export default IndentInputForm;
