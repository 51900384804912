import { Shape } from '../types';
import { Glass } from '../../../services/api/models/glass';
import { DiagonalDirection, shadowDiagonalDistance } from './shadows';
import { glassToShape } from './shapes';
import { fixValue } from './fix';
import Big from 'big.js';
import { Shape as ShapeClass } from '../../space';

export enum NeighborType {
  GLASS = 'GLASS',
  WALL = 'WALL',
}

export interface NeighborData {
  type: NeighborType;
}

export interface NeighborWall extends NeighborData {
  type: NeighborType.WALL;
}

export interface NeighborGlass extends NeighborData {
  type: NeighborType.GLASS;
  glass: Glass;
}

export interface Neighbor {
  distance: number;
  shape: Shape;
  data: NeighborWall | NeighborGlass;
}

export interface Data {
  glassShape: Shape;
  restGlasses: Glass[];
  template: Shape;
}

export type Direction = 'top' | 'bottom' | 'left' | 'right';

export type GlassNeighbors = Record<Direction | DiagonalDirection, Neighbor[]>;

export const isDiagonalDirection = (
  value: Direction | DiagonalDirection,
): value is DiagonalDirection => {
  return ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'].includes(value);
};

const checkWallNeighborsDistance = (
  direction: Direction,
  glassShape: Shape,
  template: Shape,
): number => {
  if (!glassShape.corners || !template.corners) {
    return -1;
  }
  const glassObj = new ShapeClass(
    { corners: glassShape.corners },
    glassShape.position,
  );
  const templateObj = new ShapeClass(
    { corners: template.corners },
    template.position,
  );
  switch (direction) {
    case 'top': {
      return fixValue(
        new Big(glassObj.extremePoints.top.point.y).minus(
          templateObj.extremePoints.top.point.y,
        ),
      );
    }
    case 'bottom': {
      return fixValue(
        new Big(templateObj.extremePoints.bottom.point.y).minus(
          new Big(glassObj.extremePoints.bottom.point.y),
        ),
      );
    }
    case 'left': {
      return fixValue(
        new Big(glassObj.extremePoints.left.point.x).minus(
          new Big(templateObj.extremePoints.left.point.x),
        ),
      );
    }
    case 'right': {
      return fixValue(
        new Big(templateObj.extremePoints.right.point.x).minus(
          new Big(glassObj.extremePoints.right.point.x),
        ),
      );
    }
    default: {
      return -1;
    }
  }
};

const checkGlassNeighborsDistance = (
  direction: Direction,
  glassShape: Shape,
) => (item: Glass): number => {
  if (!glassShape.corners) {
    return -1;
  }

  const itemObj = new ShapeClass({ corners: item.corners }, item.position);
  const glassObj = new ShapeClass(
    { corners: glassShape.corners },
    glassShape.position,
  );
  const collision = glassObj.shapeCollision(
    itemObj,
    direction === 'left' || direction === 'right' ? 'y' : 'x',
  );

  switch (direction) {
    case 'top': {
      return collision
        ? fixValue(
            new Big(glassObj.extremePoints.top.point.y).minus(
              new Big(itemObj.extremePoints.bottom.point.y),
            ),
          )
        : -1;
    }
    case 'bottom': {
      return collision
        ? fixValue(
            new Big(itemObj.extremePoints.top.point.y).minus(
              new Big(glassObj.extremePoints.bottom.point.y),
            ),
          )
        : -1;
    }
    case 'left': {
      return collision
        ? fixValue(
            new Big(glassObj.extremePoints.left.point.x).minus(
              new Big(itemObj.extremePoints.right.point.x),
            ),
          )
        : -1;
    }
    case 'right': {
      return collision
        ? fixValue(
            new Big(itemObj.extremePoints.left.point.x).minus(
              new Big(glassObj.extremePoints.right.point.x),
            ),
          )
        : -1;
    }
    default: {
      return -1;
    }
  }
};

export const findNeighborsInDirection = (
  { glassShape, template, restGlasses }: Data,
  direction: Direction,
): Neighbor[] => {
  const neighbors: Neighbor[] = [];
  const wallDistance = checkWallNeighborsDistance(
    direction,
    glassShape,
    template,
  );
  if (wallDistance > -1) {
    neighbors.push({
      distance: wallDistance,
      shape: template,
      data: {
        type: NeighborType.WALL,
      },
    });
  }
  const checkFn = checkGlassNeighborsDistance(direction, glassShape);
  restGlasses
    .map<Neighbor>((item) => ({
      distance: checkFn(item),
      shape: glassToShape(item),
      data: {
        type: NeighborType.GLASS,
        glass: item,
      },
    }))
    .filter((item) => item.distance > -1)
    .forEach((item) => neighbors.push(item));

  return neighbors;
};

const checkDiagonalGlassNeighborsDistance = (
  direction: DiagonalDirection,
  glassShape: Shape,
) => (item: Glass): number => {
  if (!glassShape.corners) {
    return -1;
  }
  const itemObj = new ShapeClass({ corners: item.corners }, item.position);
  const glassObj = new ShapeClass(
    { corners: glassShape.corners },
    glassShape.position,
  );
  const shapes: [ShapeClass, ShapeClass] = [glassObj, itemObj];

  return shadowDiagonalDistance({ shapes, direction });
};

export const findNeighborsInDiagonalDirection = (
  { glassShape, template, restGlasses }: Data,
  direction: DiagonalDirection,
) => {
  const neighbors: Neighbor[] = [];
  const checkFn = checkDiagonalGlassNeighborsDistance(direction, glassShape);

  restGlasses
    .map<Neighbor>((item) => ({
      distance: checkFn(item),
      shape: glassToShape(item),
      data: {
        type: NeighborType.GLASS,
        glass: item,
      },
    }))
    .filter((item) => item.distance > -1)
    .forEach((item) => neighbors.push(item));

  return neighbors;
};

export const findNeighbors = (config: Data): GlassNeighbors => ({
  top: findNeighborsInDirection(config, 'top'),
  bottom: findNeighborsInDirection(config, 'bottom'),
  left: findNeighborsInDirection(config, 'left'),
  right: findNeighborsInDirection(config, 'right'),
  topLeft: findNeighborsInDiagonalDirection(config, 'topLeft'),
  topRight: findNeighborsInDiagonalDirection(config, 'topRight'),
  bottomRight: findNeighborsInDiagonalDirection(config, 'bottomRight'),
  bottomLeft: findNeighborsInDiagonalDirection(config, 'bottomLeft'),
});
