import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';
import { LocationState } from '../../../../pages/Project/Project';
import {
  Glass as ApiGlass,
  GlassDimensions,
} from '../../../../services/api/models/glass';
import {
  BaseType,
  ProductModel,
} from '../../../../services/api/models/product';
import { Glass } from '../../components/Glass';
import { Product } from '../../components/Product';
import { selectPointer, useConfigStore } from '../../store/config';
import { selectCancel, useConnectStore } from '../../store/connect';
import { selectClearInsert, useInsertStore } from '../../store/insert';
import {
  selectProjectGlasses,
  selectProjectProducts,
  selectUpdateGlass,
  selectUpdateGlassNeighbors,
  selectUpdateProduct,
  selectUsedProducts,
  useProjectStore,
} from '../../store/project';
import {
  selectDeselect,
  selectMulti,
  selectSelect,
  selectSelected,
  ShapeId,
  useSelectStore,
} from '../../store/select';
import {
  selectConfigured,
  selectTemplateId,
  useTemplateStore,
} from '../../store/template';
import { Pointer } from '../../types';
import { MainLayer } from '../MainLayer';
import { Template } from '../Template';

export interface Props {}

const Creator: React.FC<Props> = () => {
  const location = useLocation<LocationState>();
  const templateIdStore = useTemplateStore(selectTemplateId);
  const templateId = useMemo(
    () =>
      location?.state?.templateId
        ? `${location.state?.templateId}`
        : templateIdStore,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templateIdStore],
  );

  const configured = useTemplateStore(selectConfigured);
  const glasses = useProjectStore(selectProjectGlasses);
  const products = useProjectStore(selectProjectProducts);
  const usedProducts = useProjectStore(selectUsedProducts);
  const updateGlass = useProjectStore(selectUpdateGlass);
  const updateGlassNeighbors = useProjectStore(selectUpdateGlassNeighbors);
  const updateProduct = useProjectStore(selectUpdateProduct);
  const pointer = useConfigStore(selectPointer);
  const select = useSelectStore(selectSelect);
  const selected = useSelectStore(selectSelected);
  const multiselect = useSelectStore(selectMulti);
  const deselect = useSelectStore(selectDeselect);
  const clearInsertion = useInsertStore(selectClearInsert);
  const cancelConnect = useConnectStore(selectCancel);

  const [draggable, setDraggable] = useState(false);
  const [isMultiPressed, setMultiPressed] = useState(false);

  useHotkeys(
    'space',
    (e) => {
      e.preventDefault();
      if (pointer !== Pointer.MOVE) {
        if (e.type === 'keydown' && !draggable) {
          setDraggable(true);
        }
        if (e.type === 'keyup' && draggable) {
          setDraggable(false);
        }
      }
    },
    { keyup: true, keydown: true },
    [draggable, pointer],
  );

  useHotkeys(
    '*',
    (e) => {
      switch (e.key) {
        case 'Control':
        case 'Shift': {
          if (e.type === 'keyup') {
            setMultiPressed(false);
          }
          if (e.type === 'keydown') {
            setMultiPressed(true);
          }
        }
      }
    },
    { keyup: true, keydown: true },
    [draggable, pointer],
  );

  const handleDeselect = useCallback(() => {
    deselect();
    clearInsertion();
    cancelConnect();
  }, [cancelConnect, clearInsertion, deselect]);

  const checkSelected = useCallback(
    (shapeId: ShapeId) => !!selected.find((item) => item.shapeId === shapeId),
    [selected],
  );

  const onGlassSelect = useCallback(
    (shapeId: number) => select({ shapeId, type: 'glass' }, clearInsertion),
    [clearInsertion, select],
  );

  const onProductSelect = useCallback(
    (data: ProductModel, isGlassGlass?: boolean) =>
      select(
        {
          shapeId: data.id,
          type: 'product',
          isGlassGlass,
          multi: isMultiPressed,
        },
        clearInsertion,
      ),
    [clearInsertion, isMultiPressed, select],
  );

  const prepareGlass = useCallback(
    (glass: ApiGlass) => (
      <Glass
        {...glass.position}
        data={glass}
        glasses={glasses}
        key={glass.id}
        width={glass.width}
        height={glass.height}
        corners={glass.corners}
        onSelect={onGlassSelect}
        isSelected={checkSelected(glass.id)}
        onGlassUpdate={updateGlass}
        onNeighborsUpdate={updateGlassNeighbors}
      />
    ),
    [checkSelected, onGlassSelect, updateGlass, updateGlassNeighbors],
  );

  const prepareProduct = useCallback(
    (data: ProductModel) => (
      <Product
        key={data.id}
        data={data}
        onSelect={onProductSelect}
        isSelected={checkSelected(data.id)}
        multiselect={multiselect}
        onProductUpdate={updateProduct}
        otherProducts={products.filter((item) => item.id !== data.id)}
        glasses={glasses}
      />
    ),
    [
      checkSelected,
      glasses,
      multiselect,
      products,
      onProductSelect,
      updateProduct,
    ],
  );

  useEffect(() => {
    setDraggable(pointer === Pointer.MOVE);
  }, [pointer]);

  const Glasses = useMemo(() => {
    const selected = glasses.find(({ id }) => checkSelected(id));
    const base = glasses
      .filter((glass) => glass !== selected)
      .sort((a, b) => b.position.y - a.position.y)
      .map(prepareGlass);
    if (selected) {
      base.push(prepareGlass(selected));
    }
    return base;
  }, [glasses, prepareGlass, checkSelected]);

  const Products = useMemo(() => {
    const fullModel = Object.values(usedProducts);
    const selected = products.find(({ id }) => checkSelected(id));
    const pipes = products
      .filter(
        (product) =>
          product !== selected &&
          fullModel.some(
            (used) =>
              product.productId === used.id && used.baseType === BaseType.PIPE,
          ),
      )
      .map((product) => {
        const fullProductInfo = fullModel.find(
          (used) =>
            product.productId === used.id && used.baseType === BaseType.PIPE,
        );
        let productPadding: undefined | GlassDimensions;
        if (fullProductInfo) {
          productPadding = fullProductInfo.glassProperties[0].cutOffPadding;
          product.productPadding = productPadding;
        }
        return product;
      });
    const base = products
      .filter((data) => data !== selected && !pipes.includes(data))
      .sort((a, b) => b.position.y - a.position.y)
      .map(prepareProduct);
    if (selected) {
      base.push(prepareProduct(selected));
    }
    base.push(...pipes.map(prepareProduct));
    return base;
  }, [checkSelected, prepareProduct, products, usedProducts]);

  const onTemplateSelect = useCallback(
    () =>
      select(
        { shapeId: templateId as string, type: 'template' },
        clearInsertion,
      ),
    [clearInsertion, select, templateId],
  );

  return (
    <MainLayer onEmptyClick={handleDeselect} draggable={draggable}>
      {configured && templateId && (
        <Template
          onSelect={onTemplateSelect}
          isSelected={checkSelected(templateId)}>
          {Glasses}
          {Products}
        </Template>
      )}
    </MainLayer>
  );
};

export default Creator;
