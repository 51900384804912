import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Container } from './styles';
import { PanelHeader } from '../../components/PanelHeader';
import { useCreator } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import {
  ProjectDimensions,
  ProjectStatus,
} from '../../../../../../services/api/models/project';
import { EdgeType, IndentEdgeType } from '../../../../../../types';
import {
  changeShape,
  mapCoordinatesToEdgesDistances,
} from '../../../../../../utils/shape';
import { findInnerGlassesBounds } from '../../../../../../modules/creator/utils/boundaries';
import {
  HorizontalPositioning,
  VerticalPositioning,
} from '../../components/DimensionsInputs';
import NicheDimensions from './NicheDimensions';
import { AreaInputs } from '../../components/AreaInputs';
import { Shape } from '../../../../../../modules/space';
import {
  calculateMaxEdgeLength,
  calculateMaxEdgeLengthIndent,
} from '../../../../../../modules/creator/utils/resizeValidators';

const NicheForm: React.FC = () => {
  const { t } = useTranslation('project');

  const {
    project,
    templateId,
    selectShape,
    selected,
    toggleAdjustManually,
    updateCorners,
    updateDimensions,
    projectGlasses,
  } = useCreator();

  const edges = useMemo(
    () => mapCoordinatesToEdgesDistances(project.data.dimensions),
    [project.data.dimensions],
  );

  const isTemplateSelected = useMemo(() => selected[0]?.type === 'template', [
    selected,
  ]);

  const selectTemplate = useCallback(() => {
    if (templateId) {
      selectShape({ shapeId: templateId, type: 'template' });
    }
  }, [selectShape, templateId]);

  const handleUpdateDimensions = useCallback(
    ({
      diff,
      edge,
      direction,
      lockOpposite,
    }: {
      diff: number;
      edge: IndentEdgeType;
      direction: HorizontalPositioning | VerticalPositioning;
      lockOpposite?: boolean;
    }) => {
      const lock = project.data.dimensions.indent
        ? !project.data.adjustManually
        : lockOpposite;

      const dimensions = changeShape(
        project.data.dimensions,
        edge as EdgeType,
        diff,
        direction,
        lock,
      );

      const shapeObj = new Shape(dimensions);

      shapeObj.roundCorners();

      updateCorners(
        {
          ...project.data.dimensions,
          corners: shapeObj.corners,
        } as ProjectDimensions,
        true,
      );
    },
    [project.data.dimensions, updateCorners],
  );

  const calculateMaxDimensions = (
    direction: VerticalPositioning | HorizontalPositioning,
  ) => {
    const corners = project.data.dimensions.corners;

    const horizontalDirectionToEdge =
      direction === HorizontalPositioning.LEFT ? 'left' : 'right';

    const verticalDirectionToEdge =
      direction === VerticalPositioning.TOP ? 'top' : 'bottom';

    const verticalMiddleLength = (edge: EdgeType) =>
      calculateMaxEdgeLength(corners, edge, 'top') +
      calculateMaxEdgeLength(corners, edge, 'bottom') -
      edges[edge];

    const horizontalMiddleLength = (edge: EdgeType) =>
      calculateMaxEdgeLength(corners, edge, 'left') +
      calculateMaxEdgeLength(corners, edge, 'right') -
      edges[edge];

    if (project.data.dimensions.indent) {
      return {
        top: calculateMaxEdgeLengthIndent(
          project.data.dimensions,
          'top',
          horizontalDirectionToEdge,
        ),
        bottom: calculateMaxEdgeLengthIndent(
          project.data.dimensions,
          'bottom',
          horizontalDirectionToEdge,
        ),
        'center-horizontal': calculateMaxEdgeLengthIndent(
          project.data.dimensions,
          'center-horizontal',
          horizontalDirectionToEdge,
        ),
        left: calculateMaxEdgeLengthIndent(
          project.data.dimensions,
          'left',
          verticalDirectionToEdge,
        ),
        right: calculateMaxEdgeLengthIndent(
          project.data.dimensions,
          'right',
          verticalDirectionToEdge,
        ),
        'center-vertical': calculateMaxEdgeLengthIndent(
          project.data.dimensions,
          'center-vertical',
          verticalDirectionToEdge,
        ),
      };
    }

    return {
      top:
        direction === HorizontalPositioning.MIDDLE
          ? horizontalMiddleLength('top')
          : calculateMaxEdgeLength(corners, 'top', horizontalDirectionToEdge),
      bottom:
        direction === HorizontalPositioning.MIDDLE
          ? horizontalMiddleLength('bottom')
          : calculateMaxEdgeLength(
              corners,
              'bottom',
              horizontalDirectionToEdge,
            ),
      left:
        direction === VerticalPositioning.MIDDLE
          ? verticalMiddleLength('left')
          : calculateMaxEdgeLength(corners, 'left', verticalDirectionToEdge),
      right:
        direction === VerticalPositioning.MIDDLE
          ? verticalMiddleLength('right')
          : calculateMaxEdgeLength(corners, 'right', verticalDirectionToEdge),
    };
  };

  const glassBoundaries = useMemo(
    () => findInnerGlassesBounds(projectGlasses),
    [projectGlasses],
  );

  return (
    <>
      <PanelHeader
        title={t('propertyPanel.titles.niche')}
        actionTitle={t('propertyPanel.actionTitle')}
        onAction={selectTemplate}
        withAction={!isTemplateSelected}
        adjustManually={project.data.adjustManually}
        onToggleAdjustManually={toggleAdjustManually}
      />
      <Container>
        {project.projectStatus !== ProjectStatus.CLEAR && (
          <>
            {project.data.adjustManually || project.data.dimensions.indent ? (
              <AreaInputs
                selectedId={parseFloat(templateId ?? '')}
                indent={project.data.dimensions.indent}
                edges={edges}
                getMaxDimensions={calculateMaxDimensions}
                onChange={handleUpdateDimensions}
              />
            ) : (
              <NicheDimensions
                projectData={project.data}
                innerBoundaries={glassBoundaries}
                onUpdateDimensions={updateDimensions}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default NicheForm;
