import Big, { BigSource } from 'big.js';
import { fixValue } from '../fix';
import { isNaN } from 'lodash';
import { toPositionBig } from '../../../../utils/shape';
import { Vector2d } from 'konva/lib/types';
import Konva from 'konva';

export interface BigVector {
  x: BigSource;
  y: BigSource;
}

export type Vector = {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
};

export const normalizeVector = (vector: BigVector): number => {
  const x = new Big(vector.x);
  const y = new Big(vector.y);
  const normalize = new Big(x.pow(2).plus(y.pow(2))).sqrt();

  return fixValue(normalize, 5);
};

export function doVectorsIntersect(
  v1_start: Vector2d,
  v1_end: Vector2d,
  v2_start: Vector2d,
  v2_end: Vector2d,
) {
  function crossProduct(p: any, q: any) {
    return p[0] * q[1] - p[1] * q[0];
  }

  const cross1 = crossProduct(
    [v2_start.x - v1_start.x, v2_start.y - v1_start.y],
    [v1_end.x - v1_start.x, v1_end.y - v1_start.y],
  );
  const cross2 = crossProduct(
    [v2_end.x - v1_start.x, v2_end.y - v1_start.y],
    [v1_end.x - v1_start.x, v1_end.y - v1_start.y],
  );
  const cross3 = crossProduct(
    [v1_start.x - v2_start.x, v1_start.y - v2_start.y],
    [v2_end.x - v2_start.x, v2_end.y - v2_start.y],
  );
  const cross4 = crossProduct(
    [v1_end.x - v2_start.x, v1_end.y - v2_start.y],
    [v2_end.x - v2_start.x, v2_end.y - v2_start.y],
  );

  return cross1 * cross2 < 0 && cross3 * cross4 < 0;
}

export const isVectorCollinear = (
  vector1Start: Vector2d,
  vector1End: Vector2d,
  vector2Start: Vector2d,
  vector2End: Vector2d,
) => {
  const v1Start = toPositionBig(vector1Start);
  const v1End = toPositionBig(vector1End);
  const v2Start = toPositionBig(vector2Start);
  const v2End = toPositionBig(vector2End);

  const X1 = v1End.x.minus(v1Start.x);
  const Y1 = v1End.y.minus(v1Start.y);
  const X2 = v2End.x.minus(v2Start.x);
  const Y2 = v2End.y.minus(v2Start.y);

  return X1.times(Y2).minus(Y1.times(X2)).abs().lt(0.0001);
};
export const isPointCollinear = (
  vectorStart: Vector2d,
  vectorEnd: Vector2d,
  point: Vector2d,
) => {
  if (isNaN(point.x) || isNaN(point.y)) {
    return false;
  }

  const pt = toPositionBig(point);
  const vStart = toPositionBig(vectorStart);
  const vEnd = toPositionBig(vectorEnd);

  const X1 = pt.x.minus(vStart.x);
  const Y1 = pt.y.minus(vStart.y);
  const X2 = vEnd.x.minus(vStart.x);
  const Y2 = vEnd.y.minus(vStart.y);

  return X1.times(Y2).minus(Y1.times(X2)).abs().lt(0.0001);
};
export const getVectorLength = (
  vectorStart: Vector2d,
  vectorEnd = { x: 0, y: 0 },
  precision = 2,
) => {
  const vStart = toPositionBig(vectorStart);
  const vEnd = toPositionBig(vectorEnd);

  const X = vEnd.x.minus(vStart.x);
  const Y = vEnd.y.minus(vStart.y);

  return Number(X.pow(2).plus(Y.pow(2)).sqrt().toFixed(precision));
};

export const dotProduct = (
  vector1start: Vector2d,
  vector1end: Vector2d,
  vector2start: Vector2d,
  vector2end: Vector2d,
) => {
  const vector1 = {
    x: vector1end.x - vector1start.x,
    y: vector1end.y - vector1start.y,
  };
  const vector2 = {
    x: vector2end.x - vector2start.x,
    y: vector2end.y - vector2start.y,
  };
  return vector1.x * vector2.x + vector1.y * vector2.y;
};

export const isPointInsideVector = (
  vectorStart: Vector2d,
  vectorEnd: Vector2d,
  point: Vector2d,
) => {
  if (isPointCollinear(vectorStart, vectorEnd, point)) {
    const dotProductAC = dotProduct(vectorStart, vectorEnd, vectorStart, point);
    const dotProductAB = dotProduct(
      vectorStart,
      vectorEnd,
      vectorStart,
      vectorEnd,
    );

    if (!(dotProductAC < 0) && !(dotProductAC > dotProductAB)) {
      return true;
    }
  }

  return false;
};

export const findPointDistanceFromVector = (
  pt: Konva.Vector2d,
  vector: [Konva.Vector2d, Konva.Vector2d],
): Konva.Vector2d => {
  const point = toPositionBig(pt);
  const start = toPositionBig(vector[0]);
  const end = toPositionBig(vector[1]);

  const lineVector = {
    x: end.x.minus(start.x),
    y: end.y.minus(start.y),
  };

  const pointVector = {
    x: point.x.minus(start.x),
    y: point.y.minus(start.y),
  };

  const dotProduct = pointVector.x
    .times(lineVector.x)
    .plus(pointVector.y.times(lineVector.y));

  const lineLengthSquared = lineVector.x
    .times(lineVector.x)
    .plus(lineVector.y.times(lineVector.y));

  if (lineLengthSquared.eq(0)) {
    return {
      x: point.x.minus(start.x).toNumber(),
      y: point.y.minus(start.y).toNumber(),
    };
  }

  const t = new Big(
    Math.max(0, Math.min(1, dotProduct.div(lineLengthSquared).toNumber())),
  );

  const closestPoint = {
    x: start.x.plus(t.times(lineVector.x)),
    y: start.y.plus(t.times(lineVector.y)),
  };

  const closestPointPointDistance = {
    x: point.x.minus(closestPoint.x),
    y: point.y.minus(closestPoint.y),
  };

  if (closestPointPointDistance.x.abs().lt(0.05)) {
    closestPointPointDistance.x = new Big(0);
  }

  if (closestPointPointDistance.y.abs().lt(0.05)) {
    closestPointPointDistance.y = new Big(0);
  }

  return {
    x: closestPointPointDistance.x.toNumber(),
    y: closestPointPointDistance.y.toNumber(),
  };
};
