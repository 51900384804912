import { NeighborType } from '../utils/neighbors';
import { Boundaries as Edges } from './Boundaries';
import { Glass } from '../../../services/api/models/glass';

export enum Gap {
  G2 = 2,
  G3 = 3,
  G4 = 4,
  G5 = 5,
}

export interface GapType {
  value: [number, number];
  neighbor: NeighborType;
  neighborId: string | number | undefined;
}

export interface AllGapsType {
  glass: Glass;
  boundaries: Gaps;
}

export type Gaps = Record<keyof Edges, GapType>;
