import * as React from 'react';
import { Line } from 'react-konva';
import { selectTheme, useConfigStore } from '../../store/config';
import { useMemo } from 'react';
import map from 'lodash/map';

export interface Props {
  points: { startX: number; startY: number; endX: number; endY: number };
}

const DistanceLine: React.FC<Props> = React.memo(({ points }) => {
  const theme = useConfigStore(selectTheme);

  const pointsMap = useMemo(() => map(points, (value) => value), [
    points.startX,
    points.startY,
    points.endX,
    points.endY,
  ]);

  return (
    <Line
      points={pointsMap}
      stroke={theme.colors.accentBlue}
      strokeWidth={1}
      lineJoin="round"
      dash={[4, 4]}
    />
  );
});

DistanceLine.displayName = 'DistanceLine';

export default DistanceLine;
