import Big from 'big.js';
import Point from '../../../space/Point';

export type Line = { a: number; b: number };

export const findLine = (
  point1: [number, number],
  point2: [number, number],
) => {
  const point1X = new Big(point1[0]);
  const point1Y = new Big(point1[1]);
  const point2X = new Big(point2[0]);
  const point2Y = new Big(point2[1]);

  if (point1X.eq(point2X)) {
    return { a: Infinity, b: 0 };
  }

  // (point1Y - point2Y) / (point1X - point2X);
  const a = new Big(point1Y.minus(point2Y).div(point1X.minus(point2X)));

  //point2Y - a * point2X
  const b = new Big(point2Y.minus(a.times(point2X)));

  const aNumber = a.toNumber();
  const bNumber = b.toNumber();
  return { a: aNumber, b: bNumber };
};

export const findPerpendicularLine = (point: [number, number], line: Line) => {
  const pointX = new Big(point[0]);
  const pointY = new Big(point[1]);
  const lineA = new Big(line.a);

  // Perpendicular line a  = -1/a
  const a = new Big(Big(1).div(lineA).mul(-1));

  // pointY - lineA * pointX
  const b = new Big(pointY.minus(a.times(pointX)));

  const aNumber = a.toNumber();
  const bNumber = b.toNumber();
  return { a: aNumber, b: bNumber };
};

export const findParallelLine = (point: [number, number], line: Line) => {
  const pointX = new Big(point[0]);
  const pointY = new Big(point[1]);
  const lineA = new Big(line.a);
  const lineB = new Big(line.b);

  // pointY - lineA * pointX
  const b = new Big(pointY.minus(lineA.times(pointX)));

  const bNumber = b.toNumber();
  return { a: line.a, b: bNumber };
};

export const findCrossPointToLine = (
  points: [[number, number], [number, number]],
  line: Line,
) => {
  const pointsLine = findLine(...points);

  if (pointsLine.a !== Infinity && line.a !== Infinity) {
    return findCrossPoint(line, pointsLine);
  } else {
    return [points[0][0], findYPointOnLine(line, points[0][0])];
  }
};

export const findCrossPoint = (line1: Line, line2: Line): [number, number] => {
  if (line1.a === Infinity) {
    const xNumber = line1.b;
    const yNumber = line2.a * xNumber + line2.b;
    return [xNumber, yNumber];
  }

  const line1a = new Big(line1.a);
  const line1b = new Big(line1.b);
  const line2a = new Big(line2.a);
  const line2b = new Big(line2.b);

  //(line2.b - line1.b) / (line1.a - line2.a)
  const x = new Big(line2b.minus(line1b).div(line1a.minus(line2a)));
  // line2.a * x + line2.b;
  const y = new Big(line2a.times(x).add(line2b));

  const xNumber = x.toNumber();
  const yNumber = y.toNumber();

  return [xNumber, yNumber];
};

export const findPointOnLineInDistance = (
  line: Line,
  point: [number, number],
  distance: Big,
) => {
  const lineA = new Big(line.a);
  const pointX = new Big(point[0]);
  const pointY = new Big(point[1]);

  // Calculate the direction vector of the line
  const directionX = new Big(1); // x direction
  const directionY = lineA; // y direction which is the slope

  // Normalize the direction vector
  const mag = directionX.pow(2).plus(directionY.pow(2)).sqrt();
  const normalizedDirectionX = directionX.div(mag);
  const normalizedDirectionY = directionY.div(mag);

  // Scale the normalized direction vector by the distance
  const scaledDirectionX = normalizedDirectionX.times(distance);
  const scaledDirectionY = normalizedDirectionY.times(distance);

  // Calculate the new point along the line
  const newX = pointX.plus(scaledDirectionX);
  const newY = pointY.plus(scaledDirectionY);

  return [newX.toNumber(), newY.toNumber()];
};

export const findYPointOnLine = (line: Line, pointX: number) => {
  const lineA = new Big(line.a);
  const lineB = new Big(line.b);
  const x = new Big(pointX);

  // line.a * x + line.b
  return new Big(lineA.times(x).add(lineB)).toNumber();
};

export const findXPointOnLine = (line: Line, pointY: number) => {
  const lineA = new Big(line.a);
  const lineB = new Big(line.b);
  const y = new Big(pointY);

  // (y - line.b) / line.a
  return new Big(y.minus(lineB).div(lineA)).toNumber();
};

export const findBForA = (point: [number, number], a: number) => {
  return new Big(point[1]).minus(new Big(a).times(point[0]));
};

export const findPointOnLineInDistanceFromOtherPoint = (
  line: Line,
  point: [number, number],
  distance: number,
) => {
  // line: y = ax+b
  // circle r^2 = (x-0x)^2 + (y-Oy)^2 - where O(Ox, Oy) is center of the circle
  // r^2 = (x-0x)^2 + (ax+b-Oy)^2

  const lineA = line.a;
  const lineB = line.b;
  const R = distance;
  const Ox = point[0];
  const Oy = point[1];

  // ∆ = b^2 - 4ac

  const A = 1 + lineA * lineA;
  const B = 2 * lineA * lineB - 2 * Ox - 2 * lineA * Oy;
  const C = Ox * Ox - R * R + lineB * lineB - 2 * lineB * Oy + Oy * Oy;

  const delta = B * B - 4 * A * C;

  const X1 = (-B - Math.sqrt(delta)) / (2 * A);
  const X2 = (-B + Math.sqrt(delta)) / (2 * A);

  const Y1 = lineA * X1 + lineB;
  const Y2 = lineA * X2 + lineB;

  return {
    point1: [X1, Y1],
    point2: [X2, Y2],
  };
};

export const isLinePerpendicularToXAxis = (
  point1: [number, number],
  point2: [number, number],
) => {
  return point1[0] === point2[0];
};

// use BIG
export const findDistanceBetweenPoints = (
  point1: [number, number],
  point2: [number, number],
) => {
  return Math.sqrt(
    Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2),
  );
};
