import React, { useMemo } from 'react';
import { DEFAULT_RATIO } from '../../store/config';
import { BoundariesCopy as Edges } from '../../types';
import { GapType } from '../../types/Gap';
import { GapArrow } from '../GapArrow';
import { fixValue } from '../../utils/fix';
import { Vector2d } from 'konva/lib/types';
import { MAX_GAP_DIMENSIONS } from '../../hooks/useGaps';

export interface GlassGapProps {
  gap: GapType['value'];
  gapEdge: keyof Edges;
  gapPosition: Vector2d;
}

const GlassGap = ({ gap, gapEdge, gapPosition }: GlassGapProps) => {
  const gapAverage = useMemo(() => fixValue((gap[0] + gap[1]) / 2), [gap]);

  if (!gap || gapAverage > MAX_GAP_DIMENSIONS) {
    return null;
  }

  switch (gapEdge) {
    case 'bottom':
      return (
        <GapArrow
          position={{
            x: gapPosition.x,
            y: gapPosition.y + gapAverage * DEFAULT_RATIO,
          }}
          gap={gapAverage}
        />
      );
    case 'top':
      return (
        <GapArrow
          position={{
            x: gapPosition.x,
            y: gapPosition.y,
          }}
          gap={gapAverage}
        />
      );
    case 'left':
      return (
        <GapArrow
          position={{
            x: gapPosition.x,
            y: gapPosition.y,
          }}
          gap={gapAverage}
          xAxis
        />
      );
    case 'right':
      return (
        <GapArrow
          position={{
            x: gapPosition.x + gapAverage * DEFAULT_RATIO,
            y: gapPosition.y,
          }}
          gap={gapAverage}
          xAxis
        />
      );
  }
};

export default GlassGap;
