import Konva from 'konva';
import create, { GetState, SetState } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import theme, { ThemeTypes } from '../../../../styles/theme';
import { Pointer, Projection } from '../../types';
import createVanilla from 'zustand/vanilla';

export const STORE_NAME = `@store/creator/config`;

export const DEFAULT_RATIO = 0.5;

export const DEFAULT_SCALE = 0.5;

const mainLayerPosition: Konva.Vector2d = {
  x: 0,
  y: 0,
};

const pointer = Pointer.MOVE;

const projection = Projection.FRONT;

export type State = {
  stageWidth: number;
  stageHeight: number;
  scale: number;
  theme: ThemeTypes;
  mainLayerPosition: Konva.Vector2d;
  pointer: Pointer;
  containerRect?: DOMRect;
  projection: Projection;
  setStageDimensions(width: number, height: number): void;
  setContainerRect(rect: DOMRect): void;
  setScale(scale: number): void;
  setMainLayerPosition(position: Konva.Vector2d): void;
  setPointer(pointer: Pointer): void;
  setProjection(projection: Projection): void;
  reset(): void;
};

const store = (set: SetState<State>, get: GetState<State>): State => ({
  stageWidth: 0,
  stageHeight: 0,
  scale: DEFAULT_SCALE,
  theme,
  mainLayerPosition,
  pointer,
  projection,
  setStageDimensions: (width: number, height: number) =>
    set({ stageWidth: width, stageHeight: height }),
  setContainerRect: (containerRect: DOMRect) => set({ containerRect }),
  setScale: (scale: number) => {
    set({ scale });
  },
  setMainLayerPosition: (mainLayerPosition: Konva.Vector2d) =>
    set({ mainLayerPosition }),
  setPointer: (pointer: Pointer) => set({ pointer }),
  setProjection: (projection: Projection) => set({ projection }),
  reset: () =>
    set({
      scale: DEFAULT_SCALE,
      mainLayerPosition,
      pointer,
      projection,
    }),
});

const persistedStore = persist(store, { name: STORE_NAME });

export const vanillaStore = createVanilla<State>(
  devtools(persistedStore, STORE_NAME),
);

export const useStore = create<State>(vanillaStore);
