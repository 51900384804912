import { RefObject, useMemo } from 'react';
import {
  ARROW_PADDING,
  LABEL_HEIGHT,
  LABEL_WIDTH,
  OVERFLOW,
} from '../DistanceLines';
import { Vector2 } from '../../../utils/vector';
import { Vector2d } from 'konva/lib/types';
import { scaleDistance } from '../../../utils/distance';
import { DEFAULT_RATIO } from '../../../store/config';
import { Boundaries } from '../../../types';
import Konva from 'konva';
import { DistanceTypes } from '../../../store/products';
import map from 'lodash/map';

interface UseProductGapLinesPositionsProps {
  topPosition: boolean;
  leftPosition: boolean;
  distance: Boundaries;
  gapLabel: RefObject<Konva.Label>;
  distanceType: DistanceTypes;
  mountedEdgePoints: { left: Vector2d; right: Vector2d };
}

export const useProductXGapLinesPositions = ({
  topPosition,
  leftPosition,
  distance,
  gapLabel,
  distanceType,
  mountedEdgePoints,
}: UseProductGapLinesPositionsProps) => {
  const overflowH = leftPosition ? OVERFLOW : -OVERFLOW;

  const arrowPaddingH = topPosition ? ARROW_PADDING : -ARROW_PADDING;

  const scaledDistance = scaleDistance(distance, 1 / DEFAULT_RATIO);

  const gapEdgePoints = leftPosition
    ? mountedEdgePoints.left
    : mountedEdgePoints.right;

  const horizontalDistance = useMemo(() => {
    if (distanceType === DistanceTypes.EDGE) {
      return topPosition ? -scaledDistance.top : scaledDistance.bottom;
    } else {
      return topPosition ? scaledDistance.top : -scaledDistance.bottom;
    }
  }, [distanceType, topPosition, scaledDistance.top, scaledDistance.bottom]);

  const inlineOverflow = useMemo(() => {
    const left = new Vector2([
      mountedEdgePoints.left.x,
      mountedEdgePoints.left.y,
    ]);
    const right = new Vector2([
      mountedEdgePoints.right.x,
      mountedEdgePoints.right.y,
    ]);

    const directionVector = right.subtract(left);

    // Normalize the direction vector
    const normalizedDirection = directionVector.normalize().copy();

    return {
      x: -overflowH * normalizedDirection.x.toNumber(),
      y: -overflowH * normalizedDirection.y.toNumber(),
    };
  }, [
    mountedEdgePoints.left.x,
    mountedEdgePoints.left.y,
    mountedEdgePoints.right.x,
    mountedEdgePoints.right.y,
    overflowH,
  ]);

  const gapLabelPosition = useMemo(() => {
    const padding = leftPosition ? -5 : 5;
    const label2Width =
      (gapLabel.current?.width() ?? LABEL_WIDTH) * (leftPosition ? -1 : 0);
    const label2Height = gapLabel.current?.height() ?? LABEL_HEIGHT;

    return {
      x: gapEdgePoints.x + inlineOverflow.x + label2Width + padding,
      y:
        gapEdgePoints.y +
        inlineOverflow.y +
        horizontalDistance / 2 -
        label2Height / 2,
    };
  }, [
    leftPosition,
    gapLabel,
    gapEdgePoints.x,
    gapEdgePoints.y,
    inlineOverflow.x,
    inlineOverflow.y,
    horizontalDistance,
  ]);

  const gapTopDistanceLinePoints = useMemo(() => {
    return {
      startX: gapEdgePoints.x,
      startY: gapEdgePoints.y,
      endX: gapEdgePoints.x + inlineOverflow.x,
      endY: gapEdgePoints.y + inlineOverflow.y,
    };
  }, [gapEdgePoints.x, gapEdgePoints.y, inlineOverflow.x, inlineOverflow.y]);

  const gapArrowDistanceLinePoints = useMemo(() => {
    return {
      startX: gapEdgePoints.x + inlineOverflow.x,
      startY: gapEdgePoints.y + inlineOverflow.y + arrowPaddingH,
      endX: gapEdgePoints.x + inlineOverflow.x,
      endY:
        gapEdgePoints.y + horizontalDistance + inlineOverflow.y - arrowPaddingH,
    };
  }, [
    gapEdgePoints.x,
    gapEdgePoints.y,
    inlineOverflow.x,
    inlineOverflow.y,
    arrowPaddingH,
    horizontalDistance,
  ]);

  const gapBottomDistanceLinePoints = useMemo(() => {
    return {
      startX: gapEdgePoints.x,
      startY: gapEdgePoints.y + horizontalDistance,
      endX: gapEdgePoints.x + inlineOverflow.x,
      endY: gapEdgePoints.y + inlineOverflow.y + horizontalDistance,
    };
  }, [
    gapEdgePoints.x,
    gapEdgePoints.y,
    horizontalDistance,
    inlineOverflow.x,
    inlineOverflow.y,
  ]);

  return {
    gapTopDistanceLinePoints,
    gapBottomDistanceLinePoints,
    gapArrowDistanceLinePoints,
    gapLabelPosition,
  };
};
