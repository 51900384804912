import * as React from 'react';
import { useMemo, useRef } from 'react';
import Konva from 'konva';
import { Group } from 'react-konva';
import { SizeLabel } from '../SizeLabel';
import { LABEL_HEIGHT, LABEL_WIDTH } from './DistanceLines';
import { Boundaries } from '../../types';
import { Vector2d } from 'konva/lib/types';
import { DistanceTypes } from '../../store/products';
import { useProductYDistanceLinesPositions } from './hooks/useProductYDistanceLinesPositions';
import { useProductYGapLinesPositions } from './hooks/useProductYGapLinesPositions';
import { DistanceArrow } from '../DistanceArrow';
import { Shape } from '../../../space';
import { DistanceLine } from '../DistanceLine';

interface YDistanceLinesParams {
  leftPosition: boolean;
  topPosition: boolean;
  distance: Boundaries;
  mountedEdgePoints: { top: Vector2d; bottom: Vector2d };
  distanceType: DistanceTypes;
  productData: Shape;
}

function YDistanceLines({
  leftPosition,
  topPosition,
  distance,
  productData,
  mountedEdgePoints,
  distanceType,
}: YDistanceLinesParams) {
  const topLabel = useRef<Konva.Label>(null);
  const bottomLabel = useRef<Konva.Label>(null);
  const gapLabel = useRef<Konva.Label>(null);

  const {
    topLinePoints,
    topArrowPoints,
    productTopLinePoints,
    productCenterLinePoints,
    productBottomLinePoints,
    bottomArrowPoints,
    bottomLinePoints,
  } = useProductYDistanceLinesPositions({
    mountedEdgePoints,
    productData,
    leftPosition,
    distanceType,
  });

  const {
    gapLeftDistanceLinePoints,
    gapRightDistanceLinePoints,
    gapArrowDistanceLinePoints,
    gapLabelPosition,
  } = useProductYGapLinesPositions({
    topPosition,
    leftPosition,
    distance,
    gapLabel,
    distanceType,
    mountedEdgePoints,
  });

  const topLabelPosition = useMemo(() => {
    const padding = leftPosition ? -5 : 5;
    const label1Width = leftPosition
      ? topLabel.current?.width() ?? LABEL_WIDTH
      : 0;
    const label1Height = topLabel.current?.height() ?? LABEL_HEIGHT;

    const productDistanceLine =
      distanceType === DistanceTypes.EDGE
        ? productTopLinePoints
        : productCenterLinePoints;

    return {
      x:
        (topLinePoints.endX + productDistanceLine.endX) / 2 -
        label1Width +
        padding,
      y: (topLinePoints.endY + productDistanceLine.endY) / 2 - label1Height / 2,
    };
  }, [
    topLabel.current,
    leftPosition,
    topLinePoints,
    productTopLinePoints,
    productCenterLinePoints,
  ]);

  const bottomLabelPosition = useMemo(() => {
    const padding = leftPosition ? -10 : 5;
    const label2Width = leftPosition
      ? bottomLabel.current?.width() ?? LABEL_WIDTH
      : 0;
    const label2Height = bottomLabel.current?.height() ?? LABEL_HEIGHT;

    const productDistanceLine =
      distanceType === DistanceTypes.EDGE
        ? productBottomLinePoints
        : productCenterLinePoints;

    return {
      x:
        (bottomLinePoints.endX + productDistanceLine.endX) / 2 -
        label2Width +
        padding,
      y:
        (bottomLinePoints.endY + productDistanceLine.endY) / 2 -
        label2Height / 2,
    };
  }, [
    bottomLabel.current,
    leftPosition,
    bottomLinePoints,
    productBottomLinePoints,
    productCenterLinePoints,
  ]);

  const topDistanceValue = useMemo(() => Math.abs(distance.top).toFixed(2), [
    distance.top,
  ]);

  const bottomDistanceValue = useMemo(
    () => Math.abs(distance.bottom).toFixed(2),
    [distance.bottom],
  );

  const gapDistanceValue = useMemo(
    () => Math.abs(leftPosition ? distance.left : distance.right).toFixed(2),
    [distance.left, distance.right, leftPosition],
  );

  return (
    <Group data-testid="y-axis">
      <DistanceLine points={topLinePoints} />
      <DistanceArrow points={topArrowPoints} />
      <SizeLabel
        ref={topLabel}
        y={topLabelPosition.y}
        x={topLabelPosition.x}
        value={topDistanceValue}
      />
      {distanceType === DistanceTypes.EDGE ? (
        <>
          <DistanceLine points={productTopLinePoints} />
          <DistanceLine points={productBottomLinePoints} />
        </>
      ) : (
        <DistanceLine points={productCenterLinePoints} />
      )}
      <DistanceArrow points={bottomArrowPoints} />
      <SizeLabel
        ref={bottomLabel}
        y={bottomLabelPosition.y}
        x={bottomLabelPosition.x}
        value={bottomDistanceValue}
      />
      <DistanceLine points={bottomLinePoints} />
      <DistanceLine points={gapLeftDistanceLinePoints} />
      <DistanceArrow points={gapArrowDistanceLinePoints} />
      <SizeLabel
        ref={gapLabel}
        y={gapLabelPosition.y}
        x={gapLabelPosition.x}
        value={gapDistanceValue}
      />
      <DistanceLine points={gapRightDistanceLinePoints} />
    </Group>
  );
}

YDistanceLines.displayName = 'YDistanceLines';

export default YDistanceLines;
