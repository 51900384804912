import Konva from 'konva';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Group } from 'react-konva';
import { Position } from '../../../../services/api/models/position';
import {
  DimensionsPosition,
  GroupDimensions,
} from '../../components/GroupDimensions';
import { MultiselectDistance } from '../../components/MultiselectDistance';
import { useContextMenu } from '../../hooks';
import {
  DEFAULT_RATIO,
  selectMainLayerPosition,
  selectProjection,
  selectScale,
  selectTheme,
  useConfigStore,
} from '../../store/config';
import {
  selectData,
  selectProjectGlasses,
  selectProjectPosition,
  selectShowAngles,
  selectUpdateCorners,
  selectUpdatePosition,
  useProjectStore,
} from '../../store/project';
import {
  selectMulti,
  selectSelected,
  useSelectStore,
} from '../../store/select';
import {
  selectSetDimensions,
  selectTemplateId,
  useTemplateStore,
} from '../../store/template';
import { ClickEvent, Projection } from '../../types';
import { mapCoordinatesToEdgesDistances } from '../../../../utils/shape';
import { ProjectDimensions } from '../../../../services/api/models/project';
import { Transformer } from '../../components/Transformer';
import { KonvaShape } from '../../components/KonvaShape';

export interface Props extends Konva.ShapeConfig {
  isSelected: boolean;
  onSelect?(e: ClickEvent): void;
}

const Template: React.FC<Props> = React.memo(
  ({ children, isSelected, onSelect, ...props }) => {
    const groupRef = useRef<Konva.Group>(null);
    const shapeRef = useRef<Konva.Line>(null);

    const theme = useConfigStore(selectTheme);
    const projection = useConfigStore(selectProjection);
    const templateId = useTemplateStore(selectTemplateId);
    const templatePosition = useProjectStore(selectProjectPosition);
    const updateDimensions = useProjectStore(selectUpdateCorners);
    const setDimensions = useTemplateStore(selectSetDimensions);
    const selected = useSelectStore(selectSelected);
    const multiselect = useSelectStore(selectMulti);
    const showAngles = useProjectStore(selectShowAngles);
    const updatePosition = useProjectStore(selectUpdatePosition);
    const glasses = useProjectStore(selectProjectGlasses);
    const scale = useConfigStore(selectScale);
    const layerPosition = useConfigStore(selectMainLayerPosition);
    const { dimensions, adjustManually } = useProjectStore(selectData);

    const { open: openContextMenu } = useContextMenu(
      'template',
      templateId ?? 0,
    );
    const [currentPosition, setCurrentPosition] = useState<Position>({
      x: templatePosition.x,
      y: templatePosition.y,
    });

    const [currentDim, setCurrentDim] = useState<ProjectDimensions>(dimensions);

    const scaledPosition = useMemo(() => {
      return {
        x: currentPosition.x * DEFAULT_RATIO,
        y: currentPosition.y * DEFAULT_RATIO,
      };
    }, [currentPosition]);

    const onTransform = useCallback((dim: ProjectDimensions, pos: Position) => {
      setCurrentDim(dim);
      setCurrentPosition(pos);
    }, []);

    const onTransformEnd = useCallback(
      (dim: ProjectDimensions, pos: Position) => {
        setDimensions(dim);
        updateDimensions(dim, true);
        updatePosition(pos);
      },
      [setDimensions, updateDimensions],
    );

    useEffect(() => {
      if (
        templatePosition.x !== currentPosition.x ||
        templatePosition.y !== currentPosition.y
      ) {
        setCurrentPosition({ x: templatePosition.x, y: templatePosition.y });
      }
    }, [templatePosition]);

    useEffect(() => {
      setCurrentDim(dimensions);
    }, [dimensions]);

    const visibleDimensions = useMemo((): DimensionsPosition[] => {
      if (dimensions.indent || adjustManually) {
        return ['left', 'top', 'right', 'bottom'];
      }

      return ['left', 'top'];
    }, [adjustManually, dimensions.indent]);

    return (
      <Group>
        {projection === Projection.FRONT && (
          <KonvaShape
            groupRef={groupRef}
            lineRef={shapeRef}
            dimensions={dimensions}
            strokeColor={theme.colors.grey2}
            fillColor={theme.colors.grey2}
            onContextMenu={openContextMenu}
            x={templatePosition.x}
            y={templatePosition.y}
            scale={scale}
            onMouseDown={onSelect}
          />
        )}
        {children}
        {projection === Projection.FRONT && (
          <>
            {multiselect && (
              <MultiselectDistance
                templatePosition={templatePosition}
                productIds={
                  selected.map(
                    (item) => item.type === 'product' && item.shapeId,
                  ) as string[]
                }
              />
            )}
            <GroupDimensions
              groupPosition={scaledPosition}
              dimensions={currentDim}
              edges={mapCoordinatesToEdgesDistances(currentDim)}
              show={visibleDimensions}
              visible={isSelected && !showAngles}
            />
            <Transformer
              groupRef={groupRef}
              shapeRef={shapeRef}
              shapeId={1}
              adjustManually={adjustManually}
              dimensions={dimensions}
              position={currentPosition}
              isSelected={isSelected}
              templatePosition={templatePosition}
              templateDimensions={dimensions}
              projectGlasses={glasses}
              layerPosition={layerPosition}
              onTransform={onTransform}
              onTransformEnd={onTransformEnd}
              isParentShape={true}
              scale={scale}
            />
          </>
        )}
      </Group>
    );
  },
);

Template.displayName = 'Square';

export default Template;
