import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Group } from 'react-konva';
import { DEFAULT_RATIO } from '../../store/config';
import { DistanceTypes, GlassChild } from '../../store/products';
import { Position } from '../../types';
import { Shape } from '../../../space';
import YDistanceLines from './YDistanceLines';
import CenterDistanceLine from './CenterDistanceLine';
import XDistanceLines from './XDistanceLines';
import {
  findLine,
  findPointOnLineInDistance,
} from '../../utils/geometry/lines';
import { toPoint } from '../../../../utils/shape';
import Big from 'big.js';

export interface Props {
  product: GlassChild;
  productPosition: Position;
  isGlassGlass: boolean;
  distanceType: DistanceTypes;
  xAxis?: boolean;
  yAxis?: boolean;
}

export const OVERFLOW = 50;
export const ARROW_PADDING = 2;
export const LABEL_WIDTH = 75;
export const LABEL_HEIGHT = 22;

const CENTER_AXIS_ANCHORS = [
  'topLeft',
  'topRight',
  'bottomLeft',
  'bottomRight',
  'center',
];

const DistanceLines: React.FC<Props> = React.memo(
  ({
    product,
    productPosition,
    isGlassGlass,
    distanceType,
    xAxis = true,
    yAxis = true,
  }) => {
    const { parent, edgeDistance, axisDistance } = product;

    const distance = useMemo(
      () => (distanceType === DistanceTypes.EDGE ? edgeDistance : axisDistance),
      [distanceType, edgeDistance, axisDistance],
    );

    const scaledParent = useMemo(() => {
      const parentObj = new Shape({ corners: parent.corners }, parent.position);
      parentObj.scaleShape(DEFAULT_RATIO);
      return parentObj;
    }, [parent.corners, parent.position]);

    const productShape = useMemo(() => {
      const productShape = new Shape({ corners: product.data.corners });
      productShape.scaleShape(DEFAULT_RATIO);

      productShape.transformByVector(productPosition);

      return productShape;
    }, [product.data.corners, productPosition]);

    const leftPosition = useMemo(
      () =>
        productPosition.x <
        (scaledParent.extremePoints.left.point.x +
          scaledParent.extremePoints.right.point.x) /
          2,
      [productPosition, scaledParent.extremePoints.right.point.x],
    );

    const topPosition = useMemo(
      () =>
        productPosition.y <
        (scaledParent.extremePoints.top.point.y +
          scaledParent.extremePoints.bottom.point.y) /
          2,
      [productPosition, scaledParent.extremePoints.bottom.point.y],
    );

    const verticalEdgePoints = useMemo(
      () =>
        leftPosition
          ? scaledParent.leftEdge.points
          : scaledParent.rightEdge.points,
      [
        leftPosition,
        scaledParent.leftEdge.points,
        scaledParent.rightEdge.points,
      ],
    );
    const horizontalEdgePoints = useMemo(
      () =>
        topPosition
          ? scaledParent.topEdge.points
          : scaledParent.bottomEdge.points,
      [
        topPosition,
        scaledParent.topEdge.points,
        scaledParent.bottomEdge.points,
      ],
    );

    const moveProductShapeByGap = useCallback(() => {
      let diff = { x: 0, y: 0 };

      if (yAxis && (product.anchor === 'left' || product.anchor === 'right')) {
        const directionalDistance = leftPosition
          ? Math.abs(edgeDistance.left)
          : -Math.abs(edgeDistance.right);

        const productLine = findLine(
          toPoint(productShape.topEdge.points.left),
          toPoint(productShape.topEdge.points.right),
        );

        const movedPosition = findPointOnLineInDistance(
          productLine,
          toPoint(productPosition),
          new Big(directionalDistance).mul(DEFAULT_RATIO),
        );

        diff = {
          x: movedPosition[0] - productPosition.x,
          y: movedPosition[1] - productPosition.y,
        };
      }

      if (xAxis && (product.anchor === 'top' || product.anchor === 'bottom')) {
        const directionalDistance = topPosition
          ? Math.abs(edgeDistance.top)
          : -Math.abs(edgeDistance.bottom);

        const productLine = findLine(
          toPoint(productShape.leftEdge.points.top),
          toPoint(productShape.leftEdge.points.bottom),
        );

        if (Math.abs(productLine.a) === Infinity) {
          diff = {
            x: 0,
            y: directionalDistance * DEFAULT_RATIO,
          };
        } else {
          const movedPosition = findPointOnLineInDistance(
            productLine,
            toPoint(productPosition),
            new Big(directionalDistance).abs().mul(-DEFAULT_RATIO),
          );

          diff = {
            x: movedPosition[0] - productPosition.x,
            y: movedPosition[1] - productPosition.y,
          };
        }
      }

      productShape.transformByVector(diff);
    }, [
      edgeDistance.bottom,
      edgeDistance.left,
      edgeDistance.right,
      edgeDistance.top,
      leftPosition,
      product.anchor,
      productPosition,
      productShape,
      topPosition,
      xAxis,
      yAxis,
    ]);

    const renderDistanceAxis = useMemo(() => {
      moveProductShapeByGap();

      if (isGlassGlass || CENTER_AXIS_ANCHORS.includes(product.anchor)) {
        return (
          <CenterDistanceLine
            topPosition={topPosition}
            leftPosition={leftPosition}
            horizontalEdgePoints={horizontalEdgePoints}
            verticalEdgePoints={verticalEdgePoints}
            distance={distance}
            distanceType={distanceType}
            productData={productShape}
          />
        );
      }

      if (xAxis && (product.anchor === 'top' || product.anchor === 'bottom')) {
        return (
          <XDistanceLines
            topPosition={topPosition}
            leftPosition={leftPosition}
            mountedEdgePoints={horizontalEdgePoints}
            distance={distance}
            productData={productShape}
            distanceType={distanceType}
          />
        );
      }

      if (yAxis && (product.anchor === 'left' || product.anchor === 'right')) {
        return (
          <YDistanceLines
            leftPosition={leftPosition}
            topPosition={topPosition}
            mountedEdgePoints={verticalEdgePoints}
            distance={distance}
            productData={productShape}
            distanceType={distanceType}
          />
        );
      }
    }, [
      isGlassGlass,
      topPosition,
      leftPosition,
      verticalEdgePoints,
      horizontalEdgePoints,
      distance,
      productPosition,
    ]);

    return <Group>{renderDistanceAxis}</Group>;
  },
);

DistanceLines.displayName = 'DistanceLines';

export default DistanceLines;
