import * as React from 'react';
import { useMemo, useRef } from 'react';
import Konva from 'konva';
import { Group } from 'react-konva';
import { DistanceLine } from '../DistanceLine';
import { SizeLabel } from '../SizeLabel';
import { fixValue } from '../../utils/fix';
import { DEFAULT_RATIO } from '../../store/config';
import {
  ARROW_PADDING,
  LABEL_HEIGHT,
  LABEL_WIDTH,
  OVERFLOW,
} from './DistanceLines';
import { Boundaries } from '../../types';
import { Vector2 } from '../../utils/vector';
import { scaleDistance } from '../../utils/distance';
import {
  findCrossPoint,
  findLine,
  findParallelLine,
  findPointOnLineInDistance,
} from '../../utils/geometry/lines';
import { Shape } from '../../../space';
import { toPoint, toVector } from '../../../../utils/shape';
import Big from 'big.js';
import { DistanceArrow } from '../DistanceArrow';
import { DistanceTypes } from '../../store/products';

interface XAxisDistanceParams {
  topPosition: boolean;
  leftPosition: boolean;
  distance: Boundaries;
  distanceType: DistanceTypes;
  horizontalEdgePoints: { left: Konva.Vector2d; right: Konva.Vector2d };
  verticalEdgePoints: { top: Konva.Vector2d; bottom: Konva.Vector2d };
  productData: Shape;
}

function CenterDistanceLine({
  topPosition,
  leftPosition,
  distance,
  distanceType,
  horizontalEdgePoints,
  verticalEdgePoints,
  productData,
}: XAxisDistanceParams) {
  const productAngleLine = useMemo(
    () =>
      findLine(
        productData.corners['top-left'],
        productData.corners['bottom-left'],
      ),
    [],
  );

  const edgeLabel = useRef<Konva.Label>(null);
  const gapLabel = useRef<Konva.Label>(null);

  const overflowH = leftPosition ? OVERFLOW : -OVERFLOW;
  const overflowV = topPosition ? OVERFLOW : -OVERFLOW;

  const arrowPaddingH = topPosition ? ARROW_PADDING : -ARROW_PADDING;

  const gapEdgePoints = leftPosition
    ? horizontalEdgePoints.left
    : horizontalEdgePoints.right;

  const scaledDistance = scaleDistance(distance, 1 / DEFAULT_RATIO);

  const horizontalDistance = useMemo(
    () => (topPosition ? scaledDistance.top : -scaledDistance.bottom),
    [scaledDistance.top, scaledDistance.bottom, topPosition],
  );

  const inlineOverflow = useMemo(() => {
    const left = new Vector2([
      horizontalEdgePoints.left.x,
      horizontalEdgePoints.left.y,
    ]);
    const right = new Vector2([
      horizontalEdgePoints.right.x,
      horizontalEdgePoints.right.y,
    ]);

    const directionVector = right.subtract(left);

    // Normalize the direction vector
    const normalizedDirection = directionVector.normalize().copy();

    return {
      x: -overflowH * normalizedDirection.x.toNumber(),
      y: -overflowH * normalizedDirection.y.toNumber(),
    };
  }, [
    horizontalEdgePoints.left.x,
    horizontalEdgePoints.left.y,
    horizontalEdgePoints.right.x,
    horizontalEdgePoints.right.y,
    overflowH,
  ]);

  const edgeLinePoints = useMemo(() => {
    const startPoint = leftPosition
      ? horizontalEdgePoints.left
      : horizontalEdgePoints.right;

    let endPoint;

    const verticalLine = findLine(
      toPoint(verticalEdgePoints.top),
      toPoint(verticalEdgePoints.bottom),
    );

    if (verticalLine.a === Infinity || verticalLine.a === -Infinity) {
      endPoint = {
        x: startPoint.x,
        y: startPoint.y - overflowV,
      };
    } else {
      endPoint = toVector(
        findPointOnLineInDistance(
          verticalLine,
          toPoint(startPoint),
          new Big(verticalLine.a < 0 ? overflowV : -overflowV),
        ),
      );
    }

    return {
      startX: startPoint.x,
      startY: startPoint.y,
      endX: endPoint.x,
      endY: endPoint.y,
    };
  }, [
    leftPosition,
    horizontalEdgePoints.left,
    horizontalEdgePoints.right,
    verticalEdgePoints.top,
    verticalEdgePoints.bottom,
    overflowV,
  ]);

  const productCornerLinePoints = useMemo(() => {
    const getStartPoint = () => {
      if (distanceType === DistanceTypes.AXIS) {
        return productData.polygonCentroid;
      }

      if (topPosition) {
        return toVector(
          leftPosition
            ? productData.corners['top-left']
            : productData.corners['top-right'],
        );
      } else {
        return toVector(
          leftPosition
            ? productData.corners['top-left']
            : productData.corners['top-right'],
        );
      }
    };

    const startPoint = getStartPoint();

    const horizontalLine = findLine(
      toPoint(horizontalEdgePoints.left),
      toPoint(horizontalEdgePoints.right),
    );

    const verticalLine = findLine(
      toPoint(verticalEdgePoints.top),
      toPoint(verticalEdgePoints.bottom),
    );

    let endPoint;

    if (verticalLine.a === Infinity || verticalLine.a === -Infinity) {
      endPoint = {
        x: startPoint.x,
        y: horizontalEdgePoints.left.y - overflowV,
      };
    } else {
      const verticalParallel = findParallelLine(
        toPoint(startPoint),
        verticalLine,
      );

      const crossPoint = findCrossPoint(horizontalLine, verticalParallel);

      endPoint = toVector(
        findPointOnLineInDistance(
          verticalParallel,
          crossPoint,
          new Big(verticalLine.a < 0 ? overflowV : -overflowV),
        ),
      );
    }

    return {
      startX: startPoint.x,
      startY: startPoint.y,
      endX: endPoint.x,
      endY: endPoint.y,
    };
  }, [
    horizontalEdgePoints.left,
    horizontalEdgePoints.right,
    verticalEdgePoints.top,
    verticalEdgePoints.bottom,
    distanceType,
    topPosition,
    productData.polygonCentroid,
    productData.corners,
    leftPosition,
    overflowV,
  ]);

  const edgeArrowPoints = useMemo(() => {
    return {
      startX: edgeLinePoints.endX,
      startY: edgeLinePoints.endY,
      endX: productCornerLinePoints.endX,
      endY: productCornerLinePoints.endY,
    };
  }, [edgeLinePoints, productCornerLinePoints]);

  const edgeLabelPosition = useMemo(() => {
    const padding = topPosition ? -5 : 5;
    const label1Height = topPosition
      ? edgeLabel.current?.height() ?? LABEL_HEIGHT
      : 0;
    const label1Width = edgeLabel.current?.width() ?? LABEL_WIDTH;

    return {
      x:
        (edgeLinePoints.endX + productCornerLinePoints.endX) / 2 -
        label1Width / 2,
      y:
        (edgeLinePoints.endY + productCornerLinePoints.endY) / 2 -
        label1Height +
        padding,
    };
  }, [edgeLabel.current, edgeLinePoints, productCornerLinePoints, topPosition]);

  const gapLabelPosition = useMemo(() => {
    const padding = leftPosition ? -5 : 5;
    const label2Width =
      (gapLabel.current?.width() ?? LABEL_WIDTH) * (leftPosition ? -1 : 0);
    const label2Height = gapLabel.current?.height() ?? LABEL_HEIGHT;

    return {
      x: gapEdgePoints.x + inlineOverflow.x + label2Width + padding,
      y:
        gapEdgePoints.y +
        inlineOverflow.y +
        horizontalDistance / 2 -
        label2Height / 2,
    };
  }, [
    gapLabel.current,
    leftPosition,
    gapEdgePoints.x,
    gapEdgePoints.y,
    inlineOverflow.x,
    inlineOverflow.y,
    horizontalDistance,
  ]);

  const gapTopLinePoints = useMemo(() => {
    return {
      startX: gapEdgePoints.x,
      startY: gapEdgePoints.y,
      endX: gapEdgePoints.x + inlineOverflow.x,
      endY: gapEdgePoints.y + inlineOverflow.y,
    };
  }, [gapEdgePoints.x, gapEdgePoints.y, inlineOverflow.x, inlineOverflow.y]);

  const gapArrowLinePoints = useMemo(() => {
    return {
      startX: gapEdgePoints.x + inlineOverflow.x,
      startY: gapEdgePoints.y + inlineOverflow.y + arrowPaddingH,
      endX: gapEdgePoints.x + inlineOverflow.x,
      endY:
        gapEdgePoints.y + horizontalDistance + inlineOverflow.y - arrowPaddingH,
    };
  }, [
    gapEdgePoints.x,
    gapEdgePoints.y,
    inlineOverflow.x,
    inlineOverflow.y,
    arrowPaddingH,
    horizontalDistance,
  ]);

  const gapBottomLinePoints = useMemo(() => {
    const getStartPoint = () => {
      if (distanceType === DistanceTypes.AXIS) {
        return productData.polygonCentroid;
      }

      if (topPosition) {
        return toVector(
          leftPosition
            ? productData.corners['top-left']
            : productData.corners['top-right'],
        );
      } else {
        return toVector(
          leftPosition
            ? productData.corners['bottom-left']
            : productData.corners['bottom-right'],
        );
      }
    };
    const startPoint = getStartPoint();

    return {
      startX: startPoint.x,
      startY: startPoint.y,
      endX: gapEdgePoints.x + inlineOverflow.x,
      endY: gapEdgePoints.y + inlineOverflow.y + horizontalDistance,
    };
  }, [
    leftPosition,
    topPosition,
    gapEdgePoints.x,
    gapEdgePoints.y,
    horizontalDistance,
    inlineOverflow.x,
    inlineOverflow.y,
  ]);

  const edgeDistanceValue = useMemo(() => {
    return Math.abs(
      fixValue(leftPosition ? distance.left : distance.right),
    ).toFixed(2);
  }, [distance.left, distance.right, leftPosition]);

  const gapDistanceValue = useMemo(
    () => Math.abs(topPosition ? distance.top : distance.bottom).toFixed(2),
    [distance.bottom, distance.top, topPosition],
  );

  return (
    <Group data-testid="x-axis">
      <DistanceLine points={edgeLinePoints} />
      <DistanceArrow points={edgeArrowPoints} />
      <SizeLabel
        ref={edgeLabel}
        y={edgeLabelPosition.y}
        x={edgeLabelPosition.x}
        value={edgeDistanceValue}
      />
      <DistanceLine points={productCornerLinePoints} />
      <DistanceLine points={gapTopLinePoints} />
      <DistanceArrow points={gapArrowLinePoints} />
      <SizeLabel
        ref={gapLabel}
        y={gapLabelPosition.y}
        x={gapLabelPosition.x}
        value={gapDistanceValue}
      />
      <DistanceLine points={gapBottomLinePoints} />
    </Group>
  );
}

CenterDistanceLine.displayName = 'CenterDistanceLine';

export default CenterDistanceLine;
