import Konva from 'konva';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Glass as GlassType } from '../../../../services/api/models/glass';
import { BaseType } from '../../../../services/api/models/product';
import { i18next } from '../../../../services/i18n';
import { useContextMenu, useGaps, useInsertOnGlass } from '../../hooks';
import {
  DEFAULT_RATIO,
  selectMainLayerPosition,
  selectProjection,
  selectScale,
  selectTheme,
  useConfigStore,
} from '../../store/config';
import { selectDragData, selectDragging, useDragStore } from '../../store/drag';
import { selectItem, useInsertStore } from '../../store/insert';
import {
  selectData,
  selectProjectGlasses,
  selectProjectPosition,
  selectProjectProducts,
  selectShowAngles,
  useProjectStore,
} from '../../store/project';
import {
  GlassTypes,
  Position,
  Projection,
  Shape as ShapeType,
} from '../../types';
import { fixPosition } from '../../utils/fix';
import {
  Direction,
  findNeighbors,
  GlassNeighbors,
} from '../../utils/neighbors';
import { GlassAngles } from '../GlassAngles';
import { GlassLabel } from '../GlassLabel';
import { DimensionsPosition, GroupDimensions } from '../GroupDimensions';
import { RectCorners } from '../../../../types';
import {
  mapCoordinatesToEdgesDistances,
  scaleCorners,
} from '../../../../utils/shape';
import { ProjectDimensions } from '../../../../services/api/models/project';
import { Transformer } from '../Transformer';
import { GlassDropZones } from '../GlassDropZones';
import { GlassConnectorDropZones } from '../GlassConnectorDropZones';
import useGlassBounds from './useGlassBounds';
import { GlassInsertZones } from '../GlassInsertZones';
import { GlassGap } from '../GlassGap';
import { KonvaShape } from '../KonvaShape';
import { Shape } from '../../../space';
import { calculateGlassOriginCorners } from '../../../../utils/glass';
import some from 'lodash/some';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';

export interface Props extends Konva.RectConfig {
  data: GlassType;
  glasses: GlassType[];
  width: number;
  height: number;
  corners: RectCorners;
  isSelected: boolean;
  onGlassUpdate(glass: GlassType): void;
  onNeighborsUpdate(glassId: number, neighbors: GlassNeighbors): void;
  onSelect(glassId: number): void;
}

const OPACITY = 0.6;

const Glass: React.FC<Props> = React.memo(
  ({
    data,
    glasses,
    width,
    height,
    corners,
    isSelected,
    onSelect,
    onGlassUpdate,
    onNeighborsUpdate,
    x = 0,
    y = 0,
    ...props
  }) => {
    const shapeRef = useRef<Konva.Line>(null);
    const groupRef = useRef<Konva.Group>(null);

    const theme = useConfigStore(selectTheme);
    const scale = useConfigStore(selectScale);
    const projection = useConfigStore(selectProjection);
    const layerPosition = useConfigStore(selectMainLayerPosition);
    const templatePosition = useProjectStore(selectProjectPosition);
    const { dimensions: templateDimensions } = useProjectStore(selectData);
    const projectGlasses = useProjectStore(selectProjectGlasses);
    const projectProducts = useProjectStore(selectProjectProducts);
    const dragging = useDragStore(selectDragging);
    const dragData = useDragStore(selectDragData);
    const insert = useInsertStore(selectItem);
    const showAngles = useProjectStore(selectShowAngles);

    const [currentPosition, setCurrentPosition] = useState<Konva.Vector2d>({
      x,
      y,
    });
    const [currentCorners, setCurrentCorners] = useState<RectCorners>(corners);
    const [currentOriginCorners, setCurrentOriginCorners] = useState<
      RectCorners
    >(data.originCorners);

    const scaledPosition = useMemo(() => {
      return {
        x: currentPosition.x * DEFAULT_RATIO,
        y: currentPosition.y * DEFAULT_RATIO,
      };
    }, [currentPosition]);

    const scaledCorners = useMemo(() => scaleCorners(currentCorners), [
      currentCorners,
    ]);

    const otherGlasses = useMemo(() => {
      return projectGlasses.filter((glass) => glass.id !== data.id);
    }, [data.id, projectGlasses]);

    const isBlank = useMemo(() => !!data.blank, [data.blank]);

    const { open: openContextMenu } = useContextMenu('glass', data.id);
    const { findGaps } = useGaps({
      glasses,
      templateDimensions,
      templatePosition,
    });
    const { onInsert } = useInsertOnGlass(data, onGlassUpdate);
    const {
      disableTransformEdge,
      dragBoundFunc,
      mountedProducts,
      glassIntersects,
    } = useGlassBounds({
      id: data.id,
      templateDimensions,
      projectGlasses: otherGlasses,
      templatePosition,
      glassPosition: currentPosition,
      corners: currentOriginCorners,
      isBlank,
      projectProducts,
      groupRef,
    });

    const gaps = useMemo(() => {
      if (dragging || (isSelected && showAngles)) {
        return findGaps({
          ...data,
          position: currentPosition,
          corners: currentCorners,
        });
      }
    }, [
      data,
      currentCorners,
      currentPosition,
      findGaps,
      dragging,
      isSelected,
      showAngles,
    ]);
    //TODO: remove width & height
    const template = useMemo(
      () => ({
        position: templatePosition,
        width: 0,
        height: 0,
        corners: templateDimensions.corners,
      }),
      [templatePosition.x, templatePosition.y],
    );

    const isConnectorDragged = useMemo(
      () =>
        dragData &&
        (dragData.baseType === BaseType.PIPE ||
          dragData.baseType === BaseType.CONNECTOR_GLASS ||
          dragData.baseType === BaseType.CONNECTOR_WALL),
      [dragData],
    );

    const groupPosition = useMemo(
      () =>
        projection === Projection.TOP
          ? { ...scaledPosition, y: templatePosition.y }
          : scaledPosition,
      [projection, scaledPosition, templatePosition.y],
    );

    const updateNeighbors = useCallback(
      (shape: ShapeType) => {
        const restGlasses = projectGlasses.filter(
          (item) => item.id !== data.id,
        );
        const config = {
          glassShape: shape,
          restGlasses,
          template,
        };
        const neighbors = findNeighbors(config);

        onNeighborsUpdate(data.id, neighbors);
      },
      [data.id, onNeighborsUpdate, projectGlasses, template],
    );

    const onDragMove = useCallback(
      (pos: Position) => setCurrentPosition(pos),
      [],
    );

    const onDragEnd = useCallback(
      (pos: Position) =>
        onGlassUpdate({
          ...data,
          position: pos,
        }),
      [data],
    );

    const onTransform = useCallback(
      (dim: ProjectDimensions, pos: Konva.Vector2d) => {
        const originCorners = calculateGlassOriginCorners(
          currentCorners,
          dim.corners,
          currentOriginCorners,
        );

        setCurrentOriginCorners(originCorners);
        setCurrentCorners(dim.corners);
        setCurrentPosition(fixPosition(pos, 8));
      },
      [currentOriginCorners],
    );

    const onTransformEnd = useCallback(
      (dim: ProjectDimensions, pos: Konva.Vector2d) => {
        onGlassUpdate({
          ...data,
          corners: dim.corners,
          position: fixPosition(pos, 8),
        });
      },
      [data],
    );

    const onMouseDown = useCallback(() => {
      const someGlassIsBlank = some(projectGlasses, (item) => !!item.blank);

      if (someGlassIsBlank && !data.blank) {
        return;
      }

      onSelect(data.id);
    }, [data.id, onSelect, projectGlasses, data.blank]);

    useEffect(() => {
      if (x !== currentPosition.x || y !== currentPosition.y) {
        setCurrentPosition({ x, y });
      }
    }, [x, y]);

    useEffect(() => {
      if (!isEqual(corners, currentCorners)) {
        setCurrentCorners(corners);
      }
    }, [corners]);

    useEffect(() => {
      // it prevents redundant update when deleting the glass
      if (projectGlasses.some((item) => item.id === data.id)) {
        updateNeighbors({ position: { x, y }, width, height, corners });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [x, y, width, height, projectGlasses]);

    useEffect(() => {
      if (!isEqual(currentOriginCorners, data.originCorners)) {
        setCurrentOriginCorners(data.originCorners);
      }
    }, [currentOriginCorners, data.originCorners]);

    const isValidGlassThickness = useMemo(
      () =>
        dragData?.glassProperties
          .map((item) => item.thickness)
          .includes(data?.thickness),
      [data, dragData],
    );

    const labelPosition = useMemo(() => {
      const shape = new Shape({ corners: scaledCorners });

      shape.transformByVector(scaledPosition);

      return {
        x:
          shape.leftEdge.value +
          (shape.rightEdge.value - shape.leftEdge.value) / 2 -
          24,
        y:
          shape.topEdge.value +
          (shape.bottomEdge.value - shape.topEdge.value) / 2 -
          10,
      };
    }, [scaledCorners, scaledPosition]);

    const showDimensions = useMemo((): DimensionsPosition[] | undefined => {
      return data.adjustManually
        ? ['top', 'bottom', 'left', 'right']
        : undefined;
    }, [data.adjustManually]);

    const glassGaps = useMemo(() => {
      const glassShape = new Shape({ corners: scaledCorners }, scaledPosition);

      return map(gaps, (bound, position) => ({
        ...bound,
        edge: position as Direction,
        position: glassShape.getEdgeMiddlePointPosition(position as Direction),
      }));
    }, [gaps]);

    return (
      <>
        <KonvaShape
          groupRef={groupRef}
          lineRef={shapeRef}
          x={x}
          y={y}
          scale={scale}
          onMouseDown={onMouseDown}
          draggable={
            projection === Projection.FRONT && mountedProducts.length < 1
          }
          dragBoundFunc={dragBoundFunc}
          onDragMove={onDragMove}
          onDragEnd={onDragEnd}
          dimensions={{ corners }}
          onContextMenu={openContextMenu}
          strokeColor={
            !glassIntersects ? theme.colors.grey : theme.colors.supportRed
          }
          opacity={OPACITY}
          layerPosition={layerPosition}
          fillColor={
            !glassIntersects
              ? theme.colors.accentBlue2
              : theme.colors.validation
          }
        />
        {projection === Projection.FRONT && isSelected && (
          <GlassLabel
            {...labelPosition}
            text={i18next.t(
              data.panelType
                ? data.panelType === GlassTypes.DOOR
                  ? 'creator:panelType.door'
                  : 'creator:panelType.fixed'
                : 'creator:glassLabel',
              { id: data.id },
            )}
          />
        )}
        {dragging && dragData && !isConnectorDragged && gaps && (
          <GlassDropZones
            dimensions={{ corners: currentCorners }}
            glass={data}
            glassGaps={gaps}
            product={dragData}
            glassProducts={mountedProducts}
          />
        )}
        {dragging &&
          dragData &&
          isConnectorDragged &&
          isValidGlassThickness &&
          gaps && (
            <GlassConnectorDropZones
              position={data.position}
              dimensions={{ corners: currentCorners }}
              product={dragData}
              parent={data}
              gaps={gaps}
            />
          )}
        {insert && (
          <GlassInsertZones
            position={data.position}
            dimensions={{ corners: currentCorners }}
            insert={insert}
            onSelect={onInsert}
          />
        )}
        <GroupDimensions
          groupPosition={groupPosition}
          dimensions={{ corners: currentCorners }}
          edges={mapCoordinatesToEdgesDistances({ corners: currentCorners })}
          visible={isSelected && !showAngles}
          show={showDimensions}
        />
        <Transformer
          groupRef={groupRef}
          shapeRef={shapeRef}
          shapeId={data.id}
          isSelected={isSelected}
          onTransform={onTransform}
          onTransformEnd={onTransformEnd}
          layerPosition={layerPosition}
          templateDimensions={templateDimensions}
          templatePosition={templatePosition}
          projectGlasses={otherGlasses}
          disableEdge={disableTransformEdge}
          adjustManually={data.adjustManually ?? false}
          dimensions={{ corners: currentCorners }}
          position={currentPosition}
          scale={scale}
        />
        {showAngles && isSelected && (
          <>
            <GlassAngles
              dimensions={{ corners: scaledCorners }}
              position={scaledPosition}
            />
            {glassGaps.map((gap) => (
              <GlassGap
                gap={gap.value}
                gapEdge={gap.edge}
                gapPosition={gap.position}
              />
            ))}
          </>
        )}
      </>
    );
  },
);

Glass.displayName = 'Glass';

export default Glass;
