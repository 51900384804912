import { Vector2d } from 'konva/lib/types';
import { useCallback, useMemo } from 'react';
import {
  findLine,
  findPointOnLineInDistance,
} from '../../../utils/geometry/lines';
import { toPoint, toVector } from '../../../../../utils/shape';
import Big from 'big.js';
import { Shape } from '../../../../space';
import { OVERFLOW } from '../DistanceLines';
import { DistanceTypes } from '../../../store/products';

interface UseProductDistanceLinesPositionsProps {
  mountedEdgePoints: { left: Vector2d; right: Vector2d };
  productData: Shape;
  topPosition: boolean;
  distanceType: DistanceTypes;
}

export const useProductXDistanceLinesPositions = ({
  mountedEdgePoints,
  productData,
  topPosition,
  distanceType,
}: UseProductDistanceLinesPositionsProps) => {
  const overflowV = topPosition ? OVERFLOW : -OVERFLOW;

  const productEdgeCenter = useMemo(() => {
    const edgePoints = topPosition
      ? productData.topEdge.points
      : productData.bottomEdge.points;

    return {
      x: (edgePoints.left.x + edgePoints.right.x) / 2,
      y: (edgePoints.left.y + edgePoints.right.y) / 2,
    };
  }, [topPosition, productData.topEdge.points, productData.bottomEdge.points]);

  const productAngleLine = useMemo(
    () =>
      findLine(
        productData.corners['top-left'],
        productData.corners['bottom-left'],
      ),
    [productData.corners],
  );

  const findEndPoint = useCallback(
    (startPoint: Vector2d) => {
      if (productAngleLine.a === Infinity || productAngleLine.a === -Infinity) {
        return {
          x: startPoint.x,
          y: startPoint.y - overflowV,
        };
      }

      const endPoint = findPointOnLineInDistance(
        productAngleLine,
        toPoint(startPoint),
        new Big(productAngleLine.a < 0 ? overflowV : -overflowV),
      );

      return toVector(endPoint);
    },
    [overflowV, productAngleLine],
  );

  const leftLinePoints = useMemo(() => {
    const startPoint = mountedEdgePoints.left;
    const endPoint = findEndPoint(startPoint);

    return {
      startX: mountedEdgePoints.left.x,
      startY: mountedEdgePoints.left.y,
      endX: endPoint.x,
      endY: endPoint.y,
    };
  }, [mountedEdgePoints.left.x, mountedEdgePoints.left.y]);

  const productCenterLinePoints = useMemo(() => {
    const startPoint = productEdgeCenter;

    const endPoint = findEndPoint(startPoint);

    return {
      startX: startPoint.x,
      startY: startPoint.y,
      endX: endPoint.x,
      endY: endPoint.y,
    };
  }, [findEndPoint, productEdgeCenter]);

  const productLeftLinePoints = useMemo(() => {
    const startPoint = topPosition
      ? productData.corners['top-left']
      : productData.corners['bottom-left'];

    const endPoint = findEndPoint(toVector(startPoint));

    return {
      startX: startPoint[0],
      startY: startPoint[1],
      endX: endPoint.x,
      endY: endPoint.y,
    };
  }, [findEndPoint, productData.corners, topPosition]);

  const leftArrowPoints = useMemo(() => {
    const endPoint =
      distanceType === DistanceTypes.EDGE
        ? productLeftLinePoints
        : productCenterLinePoints;

    return {
      startX: leftLinePoints.endX,
      startY: leftLinePoints.endY,
      endX: endPoint.endX,
      endY: endPoint.endY,
    };
  }, [
    productLeftLinePoints,
    productCenterLinePoints,
    leftLinePoints,
    distanceType,
  ]);

  const productRightLinePoints = useMemo(() => {
    const startPoint = topPosition
      ? productData.corners['top-right']
      : productData.corners['bottom-right'];

    const endPoint = findEndPoint(toVector(startPoint));

    return {
      startX: startPoint[0],
      startY: startPoint[1],
      endX: endPoint.x,
      endY: endPoint.y,
    };
  }, [findEndPoint, productData.corners, topPosition]);

  const rightLinePoints = useMemo(() => {
    const startPoint = mountedEdgePoints.right;

    const endPoint = findEndPoint(startPoint);

    return {
      startX: startPoint.x,
      startY: startPoint.y,
      endX: endPoint.x,
      endY: endPoint.y,
    };
  }, [findEndPoint, mountedEdgePoints.right]);

  const rightArrowPoints = useMemo(() => {
    const endPoint =
      distanceType === DistanceTypes.EDGE
        ? productRightLinePoints
        : productCenterLinePoints;

    return {
      startX: rightLinePoints.endX,
      startY: rightLinePoints.endY,
      endX: endPoint.endX,
      endY: endPoint.endY,
    };
  }, [
    distanceType,
    productRightLinePoints,
    productCenterLinePoints,
    rightLinePoints.endX,
    rightLinePoints.endY,
  ]);

  return {
    leftLinePoints,
    leftArrowPoints,
    productLeftLinePoints,
    productCenterLinePoints,
    productRightLinePoints,
    rightLinePoints,
    rightArrowPoints,
  };
};
