import * as React from 'react';
import Konva from 'konva';
import { Position } from '../../types';
import { CreatorProduct, DistanceTypes } from '../../store/products';
import { Connection } from '../../../../services/api/models/connection';
import { DistanceLines } from '../DistanceLines';
import { GasketLabel } from '../GasketLabel';
import { Group } from 'react-konva';

export interface Props {
  children: React.ReactNode;
  position: Position;
  currentPosition?: Position;
  distanceType: DistanceTypes;
  parentConnection: Connection;
  isBar: boolean;
  draggable: boolean;
  showXAxis: boolean;
  showYAxis: boolean;
  isGlassGlass: boolean;
  ref?: React.RefObject<Konva.Group>;
  visible?: boolean;
  multiselect?: boolean;
  isSelected?: boolean;
  product?: CreatorProduct;
  onDragEnd?: () => void;
  onDragMove?: () => void;
  dragBoundFunc?: (pos: Position) => Position;
}

const ProductWrapper: React.FC<Props> = React.memo(
  React.forwardRef<Konva.Group, Props>(
    (
      {
        children,
        product,
        position,
        currentPosition,
        distanceType,
        parentConnection,
        showXAxis,
        showYAxis,
        isGlassGlass,
        multiselect,
        isBar,
        isSelected,
        draggable,
        visible,
        onDragEnd,
        onDragMove,
        dragBoundFunc,
      },
      ref,
    ) => {
      return (
        <Group>
          {!multiselect &&
            !isBar &&
            isSelected &&
            product &&
            product.parentType === 'glass' &&
            currentPosition && (
              <DistanceLines
                product={product}
                productPosition={currentPosition}
                isGlassGlass={isGlassGlass}
                distanceType={distanceType}
                xAxis={showXAxis}
                yAxis={showYAxis}
              />
            )}
          <Group
            {...position}
            ref={ref}
            onDragEnd={onDragEnd}
            onDragMove={onDragMove}
            dragBoundFunc={dragBoundFunc}
            draggable={draggable}
            visible={visible}>
            {children}
            {product && !multiselect && isBar && isSelected && (
              <GasketLabel
                anchor={parentConnection.anchor}
                product={product.data}
              />
            )}
          </Group>
        </Group>
      );
    },
  ),
);

ProductWrapper.displayName = 'ProductWrapper';

export default ProductWrapper;
