import { RefObject, useMemo } from 'react';
import {
  ARROW_PADDING,
  LABEL_HEIGHT,
  LABEL_WIDTH,
  OVERFLOW,
} from '../DistanceLines';
import { Vector2 } from '../../../utils/vector';
import { Vector2d } from 'konva/lib/types';
import { scaleDistance } from '../../../utils/distance';
import { DEFAULT_RATIO } from '../../../store/config';
import { Boundaries } from '../../../types';
import Konva from 'konva';
import { DistanceTypes } from '../../../store/products';

interface UseProductGapLinesPositionsProps {
  topPosition: boolean;
  leftPosition: boolean;
  distance: Boundaries;
  gapLabel: RefObject<Konva.Label>;
  distanceType: DistanceTypes;
  mountedEdgePoints: { top: Vector2d; bottom: Vector2d };
}

export const useProductYGapLinesPositions = ({
  topPosition,
  leftPosition,
  distance,
  gapLabel,
  distanceType,
  mountedEdgePoints,
}: UseProductGapLinesPositionsProps) => {
  const overflowV = topPosition ? OVERFLOW : -OVERFLOW;

  const arrowPaddingV = leftPosition ? ARROW_PADDING : -ARROW_PADDING;

  const scaledDistance = scaleDistance(distance, 1 / DEFAULT_RATIO);

  const gapEdgePoints = topPosition
    ? mountedEdgePoints.top
    : mountedEdgePoints.bottom;

  const verticalDistance = useMemo(() => {
    if (distanceType === DistanceTypes.EDGE) {
      return leftPosition ? -scaledDistance.left : scaledDistance.right;
    } else {
      return leftPosition ? scaledDistance.left : -scaledDistance.right;
    }
  }, [scaledDistance.left, scaledDistance.right, leftPosition]);

  const inlineOverflow = useMemo(() => {
    const top = new Vector2([mountedEdgePoints.top.x, mountedEdgePoints.top.y]);
    const bottom = new Vector2([
      mountedEdgePoints.bottom.x,
      mountedEdgePoints.bottom.y,
    ]);

    const directionVector = bottom.subtract(top);

    // Normalize the direction vector
    const normalizedDirection = directionVector.normalize().copy();

    return {
      x: -overflowV * normalizedDirection.x.toNumber(),
      y: -overflowV * normalizedDirection.y.toNumber(),
    };
  }, [
    mountedEdgePoints.bottom.x,
    mountedEdgePoints.bottom.y,
    mountedEdgePoints.top.x,
    mountedEdgePoints.top.y,
    overflowV,
  ]);

  const gapLabelPosition = useMemo(() => {
    const label2Width = gapLabel.current?.width() ?? LABEL_WIDTH;
    const label2Height =
      (gapLabel.current?.height() ?? LABEL_HEIGHT) * (topPosition ? -1 : 1);

    return {
      x: gapEdgePoints.x + inlineOverflow.x - label2Width / 2,
      y: gapEdgePoints.y + inlineOverflow.y + label2Height / 2,
    };
  }, [
    gapLabel.current,
    topPosition,
    gapEdgePoints.x,
    gapEdgePoints.y,
    inlineOverflow.x,
    inlineOverflow.y,
  ]);

  const gapLeftDistanceLinePoints = useMemo(() => {
    return {
      startX: gapEdgePoints.x,
      startY: gapEdgePoints.y,
      endX: gapEdgePoints.x + inlineOverflow.x,
      endY: gapEdgePoints.y + inlineOverflow.y,
    };
  }, [gapEdgePoints.x, gapEdgePoints.y, inlineOverflow.x, inlineOverflow.y]);

  const gapArrowDistanceLinePoints = useMemo(() => {
    return {
      startX: gapEdgePoints.x + inlineOverflow.x + arrowPaddingV,
      startY: gapEdgePoints.y + inlineOverflow.y,
      endX:
        gapEdgePoints.x + verticalDistance + inlineOverflow.x - arrowPaddingV,
      endY: gapEdgePoints.y + inlineOverflow.y,
    };
  }, [
    gapEdgePoints.x,
    gapEdgePoints.y,
    inlineOverflow.x,
    inlineOverflow.y,
    arrowPaddingV,
    verticalDistance,
  ]);

  const gapRightDistanceLinePoints = useMemo(() => {
    return {
      startX: gapEdgePoints.x + verticalDistance,
      startY: gapEdgePoints.y,
      endX: gapEdgePoints.x + verticalDistance + inlineOverflow.x,
      endY: gapEdgePoints.y + inlineOverflow.y,
    };
  }, [
    gapEdgePoints.x,
    gapEdgePoints.y,
    verticalDistance,
    inlineOverflow.x,
    inlineOverflow.y,
  ]);

  return {
    gapLeftDistanceLinePoints,
    gapRightDistanceLinePoints,
    gapArrowDistanceLinePoints,
    gapLabelPosition,
  };
};
