import * as React from 'react';
import { Arrow } from 'react-konva';
import { selectTheme, useConfigStore } from '../../store/config';
import { useMemo } from 'react';
import map from 'lodash/map';

export interface Props {
  points: { startX: number; startY: number; endX: number; endY: number };
}

const DistanceArrow: React.FC<Props> = React.memo(({ points }) => {
  const theme = useConfigStore(selectTheme);

  const pointsMap = useMemo(() => map(points, (value) => value), [
    points.startX,
    points.startY,
    points.endX,
    points.endY,
  ]);

  return (
    <Arrow
      points={pointsMap}
      stroke={theme.colors.accentBlue}
      fill={theme.colors.accentBlue}
      strokeWidth={1}
      pointerWidth={5}
      pointerLength={5}
      pointerAtBeginning
    />
  );
});

DistanceArrow.displayName = 'DistanceArrow';

export default DistanceArrow;
