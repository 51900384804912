import { Vector2d } from 'konva/lib/types';
import { useMemo } from 'react';
import {
  findLine,
  findPointOnLineInDistance,
} from '../../../utils/geometry/lines';
import { toPoint } from '../../../../../utils/shape';
import Big from 'big.js';
import { Shape } from '../../../../space';
import { OVERFLOW } from '../DistanceLines';
import { DistanceTypes } from '../../../store/products';

interface UseProductDistanceLinesPositionsProps {
  mountedEdgePoints: { top: Vector2d; bottom: Vector2d };
  productData: Shape;
  leftPosition: boolean;
  distanceType: DistanceTypes;
}

export const useProductYDistanceLinesPositions = ({
  mountedEdgePoints,
  productData,
  leftPosition,
  distanceType,
}: UseProductDistanceLinesPositionsProps) => {
  const overflowH = leftPosition ? -OVERFLOW : OVERFLOW;

  const productEdgeCenter = useMemo(() => {
    const edgePoints = leftPosition
      ? productData.leftEdge.points
      : productData.rightEdge.points;

    return {
      x: (edgePoints.top.x + edgePoints.bottom.x) / 2,
      y: (edgePoints.top.y + edgePoints.bottom.y) / 2,
    };
  }, [productData.leftEdge.points, productData.rightEdge.points, leftPosition]);

  const productAngleLine = useMemo(
    () =>
      findLine(
        productData.corners['bottom-left'],
        productData.corners['bottom-right'],
      ),
    [productData.corners],
  );

  const topLinePoints = useMemo(() => {
    const startPoint = mountedEdgePoints.top;

    const endPoint = findPointOnLineInDistance(
      productAngleLine,
      toPoint(startPoint),
      new Big(overflowH),
    );

    return {
      startX: mountedEdgePoints.top.x,
      startY: mountedEdgePoints.top.y,
      endX: endPoint[0],
      endY: endPoint[1],
    };
  }, [mountedEdgePoints.top, overflowH, productAngleLine]);

  const productCenterLinePoints = useMemo(() => {
    const startPoint = toPoint(productEdgeCenter);

    const endPoint = findPointOnLineInDistance(
      productAngleLine,
      startPoint,
      new Big(overflowH),
    );

    return {
      startX: startPoint[0],
      startY: startPoint[1],
      endX: endPoint[0],
      endY: endPoint[1],
    };
  }, [overflowH, productAngleLine, productEdgeCenter]);

  const productTopLinePoints = useMemo(() => {
    const startPoint = leftPosition
      ? productData.corners['top-left']
      : productData.corners['top-right'];

    const endPoint = findPointOnLineInDistance(
      productAngleLine,
      startPoint,
      new Big(overflowH),
    );

    return {
      startX: startPoint[0],
      startY: startPoint[1],
      endX: endPoint[0],
      endY: endPoint[1],
    };
  }, [leftPosition, overflowH, productAngleLine, productData.corners]);

  const topArrowPoints = useMemo(() => {
    const endPoint =
      distanceType === DistanceTypes.EDGE
        ? productTopLinePoints
        : productCenterLinePoints;

    return {
      startX: topLinePoints.endX,
      startY: topLinePoints.endY,
      endX: endPoint.endX,
      endY: endPoint.endY,
    };
  }, [
    productTopLinePoints,
    productCenterLinePoints,
    distanceType,
    topLinePoints.endX,
    topLinePoints.endY,
  ]);

  const productBottomLinePoints = useMemo(() => {
    const startPoint = leftPosition
      ? productData.corners['bottom-left']
      : productData.corners['bottom-right'];

    const endPoint = findPointOnLineInDistance(
      productAngleLine,
      startPoint,
      new Big(overflowH),
    );

    return {
      startX: startPoint[0],
      startY: startPoint[1],
      endX: endPoint[0],
      endY: endPoint[1],
    };
  }, [leftPosition, overflowH, productAngleLine, productData.corners]);

  const bottomLinePoints = useMemo(() => {
    const startPoint = mountedEdgePoints.bottom;

    const endPoint = findPointOnLineInDistance(
      productAngleLine,
      toPoint(startPoint),
      new Big(overflowH),
    );

    return {
      startX: mountedEdgePoints.bottom.x,
      startY: mountedEdgePoints.bottom.y,
      endX: endPoint[0],
      endY: endPoint[1],
    };
  }, [mountedEdgePoints.bottom, overflowH, productAngleLine]);

  const bottomArrowPoints = useMemo(() => {
    const endPoint =
      distanceType === DistanceTypes.EDGE
        ? productBottomLinePoints
        : productCenterLinePoints;

    return {
      startX: bottomLinePoints.endX,
      startY: bottomLinePoints.endY,
      endX: endPoint.endX,
      endY: endPoint.endY,
    };
  }, [
    distanceType,
    productBottomLinePoints,
    productCenterLinePoints,
    bottomLinePoints.endX,
    bottomLinePoints.endY,
  ]);

  return {
    topLinePoints,
    topArrowPoints,
    productTopLinePoints,
    productCenterLinePoints,
    productBottomLinePoints,
    bottomLinePoints,
    bottomArrowPoints,
  };
};
