import { useCallback, useMemo } from 'react';
import { NotificationVariants } from '../components/Notification';
import {
  selectDeselect,
  useSelectStore,
} from '../modules/creator/store/select';
import {
  selectClear,
  useTemplateStore,
} from '../modules/creator/store/template';
import {
  selectCollidingGlasses,
  selectDeleteGlass,
  selectDeleteProduct,
  selectProjectGlasses,
  selectProjectProducts,
  selectUpdateGlass,
  selectUsedProducts,
  useProjectStore,
} from '../modules/creator/store/project';
import { useTranslation } from 'react-i18next';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../store/notifications';
import {
  GlassChild,
  selectDeleteProduct as selectDeleteCreatorProduct,
  selectProducts as selectCreatorProducts,
  useProductsStore,
} from '../modules/creator/store/products';
import forEach from 'lodash/forEach';
import { BaseType } from '../services/api/models/product';
import { capitalize } from 'lodash';
import { Direction } from '../modules/creator/utils/neighbors';

export interface UseDelete {
  deleteTemplate(): void;
  deleteGlass(id: number): void;
  deleteProduct(id: string): void;
}

const useDelete = (): UseDelete => {
  const deselect = useSelectStore(selectDeselect);
  const clearTemplate = useTemplateStore(selectClear);
  const setNotification = useNotificationsStore(selectSetNotification);
  const glasses = useProjectStore(selectProjectGlasses);
  const deleteGlassAction = useProjectStore(selectDeleteGlass);
  const projectCollidingGlasses = useProjectStore(selectCollidingGlasses);
  const projectProducts = useProjectStore(selectProjectProducts);
  const creatorProducts = useProductsStore(selectCreatorProducts);
  const usedProduct = useProjectStore(selectUsedProducts);
  const deleteProductAction = useProjectStore(selectDeleteProduct);
  const deleteCreatorProduct = useProductsStore(selectDeleteCreatorProduct);
  const updateGlass = useProjectStore(selectUpdateGlass);

  const { t } = useTranslation('project');

  const products = useMemo(
    () =>
      projectProducts.map((pp) =>
        creatorProducts.find((cp) => cp.data.id === pp.id),
      ),
    [creatorProducts, projectProducts],
  );

  const deleteTemplate = useCallback(() => {
    if (glasses.length > 0) {
      deselect();
      return setNotification({
        text: t('notifications.cannotDeleteNiche'),
        variant: NotificationVariants.ERROR,
      });
    }
    return clearTemplate();
  }, [clearTemplate, deselect, glasses.length, setNotification, t]);

  const deleteGlass = useCallback(
    (glassId: number) => {
      if (
        !products.some(
          (product) =>
            product?.parentType === 'glass' &&
            product.allParents.some((item) => item.id === glassId),
        )
      ) {
        return deleteGlassAction(glassId, () => {
          deselect();
          projectCollidingGlasses.delete(glassId);
          setNotification({
            text: t('notifications.glassDeleted'),
            variant: NotificationVariants.INFO,
          });
        });
      }
      return setNotification({
        text: t('notifications.cannotDeleteElement'),
        variant: NotificationVariants.ERROR,
      });
    },
    [deleteGlassAction, deselect, products, setNotification, t],
  );

  const restoreParentToOrigin = useCallback(
    (product: GlassChild) => {
      forEach(product.allParents, (parent) => {
        const projectProduct = projectProducts.find(
          (pp) => pp.id === product.id,
        );
        const searchProduct = usedProduct[product.data.productId];

        if (!projectProduct) {
          return;
        }

        let anchor = projectProduct.connections.find(
          (c) => c.targetId === String(parent.id),
        )?.anchor;

        if (!anchor) {
          return;
        }

        if (
          searchProduct.baseType === BaseType.LIFTING_HINGE &&
          (anchor === 'left' || anchor === 'right')
        ) {
          const otherParentProducts = products.filter(
            (item) =>
              item?.data.connections.some(
                (connect) =>
                  connect.targetId === String(product.parent.id) &&
                  connect.anchor.includes('top'),
              ) && item?.id !== product.id,
          );

          if (!otherParentProducts.length) {
            anchor = `top${capitalize(anchor)}` as 'topLeft' | 'topRight';
          }
        }

        const hasProductsInDirection = (direction: Direction) =>
          projectProducts.some((pp) =>
            pp.connections.find(
              (pc) =>
                pc.anchor === direction &&
                pc.targetId === String(product.parent.id),
            ),
          );

        if (['top', 'topLeft', 'topRight'].includes(anchor)) {
          parent.corners = {
            ...parent.corners,
            'top-left': [
              hasProductsInDirection('left')
                ? parent.corners['top-left'][0]
                : parent.originCorners['top-left'][0],
              parent.originCorners['top-left'][1],
            ],
            'top-right': [
              hasProductsInDirection('right')
                ? parent.corners['top-right'][0]
                : parent.originCorners['top-right'][0],
              parent.originCorners['top-right'][1],
            ],
          };
        }

        if (['bottom', 'bottomLeft', 'bottomRight'].includes(anchor)) {
          parent.corners = {
            ...parent.corners,
            'bottom-left': [
              hasProductsInDirection('left')
                ? parent.corners['bottom-left'][0]
                : parent.originCorners['bottom-left'][0],
              parent.originCorners['bottom-left'][1],
            ],
            'bottom-right': [
              hasProductsInDirection('right')
                ? parent.corners['bottom-right'][0]
                : parent.originCorners['bottom-right'][0],
              parent.originCorners['bottom-right'][1],
            ],
          };
        }

        if (['left', 'topLeft', 'bottomLeft'].includes(anchor)) {
          parent.corners = {
            ...parent.corners,
            'top-left': [
              parent.originCorners['top-left'][0],
              hasProductsInDirection('top')
                ? parent.corners['top-left'][1]
                : parent.originCorners['top-left'][1],
            ],
            'bottom-left': [
              parent.originCorners['bottom-left'][0],
              hasProductsInDirection('bottom')
                ? parent.corners['bottom-left'][1]
                : parent.originCorners['bottom-left'][1],
            ],
          };
        }

        if (['right', 'topRight', 'bottomRight'].includes(anchor)) {
          parent.corners = {
            ...parent.corners,
            'top-right': [
              parent.originCorners['top-right'][0],
              hasProductsInDirection('top')
                ? parent.corners['top-right'][1]
                : parent.originCorners['top-right'][1],
            ],
            'bottom-right': [
              parent.originCorners['bottom-right'][0],
              hasProductsInDirection('bottom')
                ? parent.corners['bottom-right'][1]
                : parent.originCorners['bottom-right'][1],
            ],
          };
        }

        updateGlass(parent);
      });
    },
    [glasses, projectProducts],
  );

  const deleteProduct = useCallback(
    (productIid: string) => {
      if (
        !products.some((product) =>
          product?.data.connections.some(
            (connect) => connect.targetId === productIid,
          ),
        )
      ) {
        const product = products.find((p) => p?.data.id === productIid);

        if (product && product.parentType === 'glass') {
          const otherParentProducts = products.filter(
            (item) =>
              item?.data.connections.some(
                (connect) =>
                  connect.targetId === String(product.parent.id) &&
                  connect.anchor === product.anchor,
              ) && item?.id !== product.id,
          );

          if (!otherParentProducts.length) {
            restoreParentToOrigin(product);
          }
        }

        return deleteProductAction(productIid, () => {
          deleteCreatorProduct(productIid);
          deselect();
          return setNotification({
            text: t('notifications.productDeleted'),
            variant: NotificationVariants.INFO,
          });
        });
      }
      return setNotification({
        text: t('notifications.cannotDeleteElement'),
        variant: NotificationVariants.ERROR,
      });
    },
    [
      deleteCreatorProduct,
      deleteProductAction,
      deselect,
      products,
      setNotification,
      t,
    ],
  );

  return {
    deleteTemplate,
    deleteGlass,
    deleteProduct,
  };
};

export default useDelete;
